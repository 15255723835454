import React from 'react'; // eslint-disable-line no-unused-vars
import SwipeToDelete from 'react-swipe-to-delete-component';

/*
extend SwipeToDelete to provide click support
switch onDelete ordering to prevent setState on unmounted component
*/
class MySwipe extends SwipeToDelete {
  constructor(props) {
    super(props);
    this.state = { isDeleted: false, isMoving: false };
    // only fire the props.onclick if not ismoving
    // set ismoving true when move starts
    // set ismoving false when ondelete/oncancel; (offinteract fires earlier than click so it doesnt prevent clicks)
  }

  componentDidMount() {
    this.addHandlers();
    if(this.props.onClick) {
      const el = this.regionContent.firstChild;
      el.addEventListener('click', this.handleClick, false);
    }
  }

  handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    if(!this.state.isMoving) {
      this.props.onClick(e);
    }
  }

  onMove(e) {
    this.setState({ isMoving: true });
    this.moveAt(e);
    this.callMoveCB(e);
  }

  onCancel(e) {
    this.props.onCancel();

    const target = e.currentTarget;
    target.classList.remove('js-transition-cancel');

    this.model.startX = target.style.left = 0;
    this.setState({ isMoving: false });
  }

  /*
  switched order of setstate/onDelete, as ondelete may remove this element so setstate after unmount is going to error
  in dashboard:
    remove swipe > calls fb .remove and updates the state in the dashboard comp
    this unloads the deleted swipe component; which means if setstate here is called after ondelete then it will be on an unmounted comp
  */
  onDelete() {
    this.setState({ isDeleted: true, isMoving: false }, () => {
      this.props.onDelete();
    });
  }
}

export default MySwipe;