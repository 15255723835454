import React from 'react';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import moment from 'moment';

import withFade from '../UI/withFade';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { withTranslate } from '../Language';
// import * as EMAIL from '../../constants/email';

/*
- list of users from api
- sortable, fitlerable? need state then, but that is the other component inside this page, the grid itself
- stateful comp since doing some load stuff
- if we are doing all by route, then editing is easy since just need to provide the route to edit, instead now it becomes quite tricky
  since we need to tell the parent comp (via its passed func) to change state to edit, as well as the item to change to
*/

// passing user as prop to link, since we have the info available, we can pass to details
const UsersTable = ({ users, history }) => {
  const columns = [{
    Header: 'Name',
    accessor: 'name',
    minWidth: 200
  }, {
    Header: 'Role',
    accessor: 'displayRole',
  }, {
    id: 'created',
    Header: 'Created on',
    accessor: row => row.createdAt ? moment(row.createdAt).calendar() : '--',
  }, {
    id: 'updated',
    Header: 'Updated on',
    accessor: row => row.updatedAt ? moment(row.updatedAt).calendar() : '--',
  }, {
    id: 'lastSeen',
    Header: 'Last Seen',
    accessor: row => { // presence
      if(row.lastSeen === true) return 'Online';
      if (isNaN(row.lastSeen)) return row.lastSeen;
      return moment(row.lastSeen).calendar();
    }
  }, {
    id: 'action',
    Header: 'Action',
    accessor: user => <Link to={{
      pathname: `${ROUTES.USERS}/${user.uid}`,
      state: { user }
    }}><i className="far fa-edit fa-lg"></i></Link>,
    maxWidth: 80,
  }];

  return (
    <ReactTable data={users} columns={columns} className="-highlight"
      defaultPageSize={10}
      defaultSorted={[{ id: 'name', desc: false }]}
      getTdProps={(state, rowInfo, column, instance) => {
        return {
          onClick: (e, handleOriginal) => {
            history.push({
              pathname: `${ROUTES.USERS}/${rowInfo.original.uid}`,
              state: { user: rowInfo.original }
            });
            if (handleOriginal) {
              handleOriginal();
            }
          }
        };
      }}
    />
  );
}

//  <tr><td colSpan="4">No data found</td></tr>

const INITIAL_STATE = {
  loading: false,
  users: []
}

class UsersList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.users().on('value', snapshot => {
      const usersObj = snapshot.val(); // firebase returns an object with the uid as keys, not an array
      if(!snapshot.exists()) {
        return this.setState({ ...INITIAL_STATE });
      }

      /*
      instead of pushing directly we should listen on presence
      strategy:
        after inital setstate of users
        callback, loop users, mulitple refs no good, instead 
        single ref to presence and loop thru these, attaching to the user matching key if found
      */

      const usersList = Object.keys(usersObj).map(key => {
        // let username = (usersObj[key]['username']).replace(EMAIL.SUFFIX, '');
        return {
          ...usersObj[key],
          uid: key, // append uid as part of the item instead of its key
          lastSeen: 'Never'
          // username,
        }
      });

      this.setState({ 
        loading: false,
        users: usersList
      }, () => {
        // presence check:
        this.props.firebase.presences().on('value', snap => {
          let users = [...this.state.users];
          
          snap.forEach(item => {
            let val = item.val();
            let existingUser = users.findIndex(x => x.uid === item.key);
            if (existingUser > -1) {
              users[existingUser] = {
                ...users[existingUser],
                lastSeen: val
              };
            } // else user not in list, ignore
          });
          this.setState({ users });
        });
      });

    }, err => {
      this.setState({ loading: false });
      NotificationManager.error(err.toString());
    });

  }

  componentWillUnmount() {
    this.props.firebase.users().off();
    this.props.firebase.presences().off();
  }

  render() {
    const { loading, users } = this.state;
    let t = this.props.t;

    return (
      <React.Fragment>
        <h1>{t('users.title')}</h1>
        <Link to={ROUTES.USER_ADD} className="topnav"><i className="fas fa-plus fa-sm"></i> Add New User</Link>
        {loading
          ? <p>Loading ...</p>
          : <UsersTable users={users} history={this.props.history} />
        }
      </React.Fragment>
    );
  }
}

export default withTranslate(withFirebase(withFade(UsersList)));
