import React from 'react';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import { LanguageProvider } from '../Language';
import Navbar from '../UI/Navbar';

// provides a wrapper for updating title meta
class Page extends React.Component {
  componentDidMount() {
    document.title = 'SHH Driver Payroll System' + (this.props.title ? ` - ${this.props.title}` : '');
  }

  render() {
    const PageComponent = this.props.component;
    return (
      <LanguageProvider>
        <Navbar />
        <PageComponent {...this.props} />
        <NotificationContainer />
      </LanguageProvider>
    );
  }
}

export default Page;