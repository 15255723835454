import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { withAuthorization } from '../Session';
import * as ROUTES from '../../constants/routes';
import RecordsList from './RecordsList';
import RecordAdd from './RecordsAdd';
import RecordsEdit from './RecordsEdit';
import RecordsView from './RecordsView';
import RecordsDelete from './RecordsDelete';
import RecordsListRecords from './RecordsListRecords';

/*
trip record - driver may only add/view self; other roles may view all
candidate for record user is always driver type, what if a driver is also a super? need 2 diff accts
  since admin/super are not valid target for driver record?

triprecord (admin):
  date, driver, veh, job, start/end location, company, ticket, remarks, start/end time, amount
  (for non-implemented fields just use a blank text field for now, we are copying in the value anyway)
  to facilitate stats, we can consider the lookup:
    triprecords/rid: { ..., job: 'Some delivery' } // stored as a value so we can print the value at point of record creation, and not from edits of job
    jobs/jid: { name: 'Some delivery' }
    job_trips/jid: { ..., rid: true }
    trip_jobs/rid: { ..., jid: true } // only really needed for many many, since we dun have more than 1 job per trip; if only 1 (in this case), we can just push this id into record as a field instead
      triprecords/rid: { ..., job: 'Some delivery', jobId: jid }

triprecord (driver):
  no date, no driver
  amount calc is flat, not per km

we can use the authuser context to provide different routes based on auth level?
  either wrap around route, or wrap around the RecordAdd component > RecordAddAdmin, RecordAddDriver
similarly for list:
  admin list: list of drivers first > records of driver list
  driver list: records of own (driver) list

  tab for the waiting rate (in per hr)/other job day rate (8hr); actually link to the direct details page
  modelling the waiting/other record
  tab navi should only appear on the list view? means need to move main-wrapper
*/
const Records = () => (
  <div className="main">
    <div className="main-wrapper">
      <Switch>
        <Route exact path={ROUTES.RECORDS_ADD} component={RecordAdd} />
        <Route exact path={ROUTES.RECORDS_LIST} component={RecordsListRecords} />
        <Route exact path={ROUTES.RECORDS_DELETE} component={RecordsDelete} />
        <Route exact path={ROUTES.RECORDS_EDIT} component={RecordsEdit} />
        <Route exact path={ROUTES.RECORDS_VIEW} component={RecordsView} />
        <Route exact path={ROUTES.RECORDS} component={RecordsList} />
      </Switch>
    </div>
  </div>
);

const condition = authUser => !!authUser; 
export default withAuthorization(condition)(Records);
