import React from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import moment from 'moment';

import withFade from '../UI/withFade';
import { withTranslate } from '../Language';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { AuthUserContext } from '../Session';

const CompaniesTable = ({ companies, role, history }) => {
  const columns = [{
    Header: 'Name',
    accessor: 'name',
  }, {
    id: 'created',
    Header: 'Created on',
    accessor: row => row.createdAt ? moment(row.createdAt).calendar() : '--',
  }, {
    id: 'updated',
    Header: 'Updated on',
    accessor: row => row.updatedAt ? moment(row.updatedAt).calendar() : '--',
  }, {
    id: 'action',
    Header: 'Action',
    accessor: company => {
      if(role !== ROLES.ADMIN) return '--';
      return <Link to={{
        pathname: `${ROUTES.COMPANIES}/${company.cid}`,
        state: { company }
      }}><i className="far fa-edit fa-lg"></i></Link>
    },
    maxWidth: 80,
  }];

  return (
    <ReactTable className="-highlight" data={companies} columns={columns}
      defaultPageSize={10}
      defaultSorted={[{ id: 'name', desc: false }]}
      getTdProps={(state, rowInfo, column, instance) => {
        return {
          onClick: (e, handleOriginal) => {
            if (role === ROLES.ADMIN) history.push(`${ROUTES.COMPANIES}/${rowInfo.original.cid}`);
            if (handleOriginal) {
              handleOriginal();
            }
          }
        };
      }}
    />
  );
}

const INITIAL_STATE = {
  loading: false,
  companies: []
}

class CompaniesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE
    };
  }

  componentDidMount() {
    this.setState({ loading: true }, () => {
      this.props.firebase.companies().on('value', snap => {
        let companies = [];
        snap.forEach(item => {
          let val = item.val();
          companies.push({
            ...val,
            cid: item.key
          });
        });
        this.setState({
          loading: false,
          companies
        });
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.companies().off();
  }

  render() {
    const { loading, companies } = this.state;
    const t = this.props.t;

    return (
      <React.Fragment>
        <h1>{t('companies.title')}</h1>
        <AuthUserContext.Consumer>
          {authUser =>
            <React.Fragment>
              {authUser && authUser.claims.role === ROLES.ADMIN &&
                <Link to={ROUTES.COMPANIES_ADD} className="topnav"><i className="fas fa-plus fa-sm"></i> Add New Company</Link>
              }
              {loading
                ? <p>Loading ...</p>
                : <CompaniesTable role={authUser.claims.role} companies={companies} history={this.props.history} />
              }
            </React.Fragment>
          }
        </AuthUserContext.Consumer>
      </React.Fragment>
    );
  }
}

export default withTranslate(withFirebase(withFade(CompaniesList)));
