import React from 'react';
import { withRouter } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as EMAIL from '../../constants/email';
import Input from '../Form/Input';
import FormValidator from '../Form/FormValidator';
import Logo from '../../img/shh-logo.png';
import '../Form/CustomForm.css';

const Landing = () => (
  <div className="loginpage">
    <h1><img src={Logo} alt="Logo" width="40" /> Sign-in</h1>
    <SignInForm />
  </div>
);

const INITIAL_STATE = {
  form: {
    username: '',
    password: ''
  },
  submitting: false,
  error: null,
}

class SignInFormBase extends React.Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: 'username',
        method: 'isEmpty', // string methods if using default validator package
        validWhen: false,
        message: 'Username is required.'
      },
      {
        field: 'password',
        method: 'isEmpty', // string methods if using default validator package
        validWhen: false,
        message: 'Password is required.'
      }
    ]);

    this.state = { ...INITIAL_STATE, validation: this.validator.valid() };

    this.submitted = false;
  }

  componentDidMount() { // redirect to dashboard if already logged in!
    this.listener = this.props.firebase.auth.onAuthStateChanged(
      authUser => {
        if(!!authUser) {
          this.props.history.replace(ROUTES.DASHBOARD);
        }
    });
  }

  componentWillUnmount() {
    this.listener();
  }

  onSubmit = e => {
    e.preventDefault();
    const validForm = this.checkValidForm();
    this.submitted = true;
    // check email contains email suffix, if not we append the system one!
    // no longer email but username, and we append the suffix automatically if not gmail (for test)
    if(validForm) {
      this.setState({ submitting: true });
      let { username, password } = this.state.form;
      if(!EMAIL.validEmail(username)) {
        username = username + EMAIL.SUFFIX;
      }
      this.props.firebase.doSignInWithEmailAndPassword(username, password)
        .then(() => {
          this.setState({ ...INITIAL_STATE });
          this.props.history.push(ROUTES.DASHBOARD);
        })
        .catch(error => {
          this.setState({ submitting: false, error });
        });
    }
  }

  onChange = e => {
    const form = Object.assign({}, this.state.form);
    form[e.target.name] = e.target.value;
    this.setState({ form });
  }

  checkValidForm = () => {
    const validation = this.validator.validate(this.state.form);
    this.setState({ validation: validation });
    return validation.isValid;
  }

  render() {
    const { form, error, submitting } = this.state;
    let validation = this.submitted ? this.validator.validate(form) : this.state.validation;

    return (
      <form className="customform loginform" onSubmit={this.onSubmit}>
        <Input
          name="username"
          className=""
          labelText="Username"
          type="text"
          value={form.username}
          invalid={validation.username.isInvalid}
          message={validation.username.message}
          onChange={this.onChange}
        />
        <Input
          name="password"
          className=""
          labelText="Password"
          type="password"
          value={form.password}
          invalid={validation.password.isInvalid}
          message={validation.password.message}
          onChange={this.onChange}
        />
        <input type="submit" name="Submit" value="Sign in" disabled={submitting} />
        {error && 
          <p className="error">{error.message}</p>
        }
      </form>
    );
  }
}

const SignInForm = withRouter(withFirebase(SignInFormBase));

export default Landing;
export { SignInForm, SignInFormBase as PureSignInForm };