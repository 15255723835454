import React from 'react';
import PropTypes from 'prop-types';

/*
input component that displays floating label and active state
and allows show/hide of the password value
*/
class ShowPasswordInput extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      isFocused: false,
      hidden: true,
    };
  }

  enableFocus = () => {
    this.setState({ isFocused: true }); // also show label when focused even if empty; calc in render, else no effect 
  }

  disableFocus = () => {
    this.setState((prevState) => ({ isFocused: false }));
  }

  toggleShow = e => {
    e.preventDefault();
    this.setState(prevState => ({ hidden: !prevState.hidden }));
  }

  render() {
    let props = this.props;
    let className = "field showpasswordinput";
    if(props.className) className += ` ${props.className}`;
    if(this.state.isFocused || props.value !== '') className += ' active';
    if(this.state.isFocused) className += ' focused';
    if(props.invalid) className += ' has-error';

    return (
      <div className={className}>
        <label htmlFor={props.name}>{props.labelText}</label>
        <input id={props.name} name={props.name} placeholder={this.state.isFocused ? '' : props.labelText}
          type={this.state.hidden ? 'password' : 'text'}
          value={props.value}
          onFocus={this.enableFocus}
          onBlur={this.disableFocus}
          onChange={props.onChange}
          disabled={props.disabled}
        />
        <button onClick={this.toggleShow} tabIndex="-1"><i className={this.state.hidden ? 'fas fa-eye-slash fa-sm' : 'fas fa-eye fa-sm'}></i></button>
        {props.invalid ? (
          <span className="help-block">{props.message}</span>
        ) : null}
      </div>
    );
  }
}

export default ShowPasswordInput;

ShowPasswordInput.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  labelText: PropTypes.string,
  value: PropTypes.string,
  message: PropTypes.string,
  invalid: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

ShowPasswordInput.defaultProps = {
  disabled: false,
  value: '',
};