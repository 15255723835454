import React from 'react';

import * as ROLES from '../../constants/roles';
import { AuthUserContext } from '../Session';
import RecordsAddAdmin from './RecordsAddAdmin';
import RecordsAddDriver from './RecordsAddDriver';

/*
wrapper for add as self or as admin
*/

const RecordsAdd = () =>
  <React.Fragment>
    <AuthUserContext.Consumer>
      {authUser =>
        authUser && ((authUser.claims.role === ROLES.DRIVER &&
          <RecordsAddDriver />
        ) || ((authUser.claims.role === ROLES.ADMIN || authUser.claims.role === ROLES.SUPERVISOR) &&
          <RecordsAddAdmin />
          ))}
    </AuthUserContext.Consumer>
  </React.Fragment>

export default RecordsAdd;