import React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { withFirebase } from '../Firebase';
import { withAuthorization, AuthUserContext } from '../Session';
import { withTranslate } from '../Language';
import Fade from 'react-reveal/Fade';
// import withFade from '../UI/withFade';
import { toDollar, recordNumber } from '../../constants/math';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

import '../Form/CustomForm.css';

const moment = extendMoment(Moment);

const INITIAL_STATE = {
  loading: false,
  dayjob: null,
}

class RecordsView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE
    };

    this.dayjobRef = null;
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({ loading: true });
    const userContext = this.context;
    let uid = userContext.uid;
    if(userContext.claims.role === ROLES.ADMIN || userContext.claims.role === ROLES.SUPERVISOR) uid = this.props.match.params.uid;
    
    this.dayjobRef = this.props.firebase.dayjob(uid, this.props.match.params.id);
    this.dayjobRef
      .on('value', snapshot => {
        const record = snapshot.val();
        if (!record) return this.props.history.push(ROUTES.RECORDS);

        const dayjob = {
          date: record.date,
          driverId: record.driverId,
          vehicleId: record.vehicleId,
          jobId: record.jobId,
          siteLocationId: record.siteLocationId,
          companyId: record.companyId,
          remarks: record.remarks,
          startAt: record.startAt,
          endAt: record.endAt,
          // additional info snapped at point of save:
          amount: record.amount, // in cents, in db value for presentation
          rid: snapshot.key,
          driver: record.driver,
          vehicle: record.vehicle,
          job: record.job,
          siteLocation: record.siteLocation,
          company: record.company,
          recno: record.recno
        };

        if (this._isMounted) {
          this.setState({
            loading: false,
            dayjob
          });
        }

      });

  }

  componentWillUnmount() {
    this._isMounted = false;
    if(this.dayjobRef) this.dayjobRef.off();
  }

  render() {
    const { loading, dayjob } = this.state;
    var duration = 0;
    const t = this.props.t;

    if (dayjob && dayjob.startAt && dayjob.endAt) {
      // difference, if neg set to zero
      duration = parseInt((dayjob.endAt - dayjob.startAt) / (1000 * 60));
    }

    return (
      <React.Fragment>
        {loading
          ? <p>Loading ...</p>
          : dayjob && (
            <AuthUserContext.Consumer>
              {authUser => <React.Fragment>
                <div className="top">
                  <Fade>
                    <h1>
                      {t('record')}{' '}
                      {dayjob.recno ? recordNumber(dayjob.recno, 'dayjob') : dayjob.rid}
                    </h1>
                    <div className="month-section-record">
                      <div className="month-block">
                        <div>
                          {moment(dayjob.date).format('ddd').toUpperCase()} <br/>
                          <span>{moment(dayjob.date).format('D')}</span>
                        </div>
                      </div>
                      <div className="record-list">
                        <div>
                          <span>{dayjob.siteLocation} <i className="fas fa-calendar-day fa-sm grey"></i></span>
                          <br />{moment(dayjob.startAt, 'x').format('h:mma')} - {moment(dayjob.endAt, 'x').format('h:mma')}
                        </div>
                      </div>
                    </div>
                    <div className="record-detail">
                      <p><span>{t('form.driver')}:</span> {dayjob.driver}</p>
                      <p><span>{t('form.vehicle')}:</span> {dayjob.vehicle}</p>
                      <p><span>{t('form.job')}:</span> {dayjob.job}</p>
                      <p><span>{t('form.sitelocation')}:</span> {dayjob.siteLocation}</p>
                      <p><span>{t('form.company')}:</span> {dayjob.company}</p>
                      <p><span>{t('form.remarks')}:</span> {dayjob.remarks}</p>
                      <p><span>{t('form.startat')}:</span> {moment(dayjob.startAt, 'x').format('h:mma')}</p>
                      <p><span>{t('form.endat')}:</span> {moment(dayjob.endAt, 'x').format('h:mma')}</p>
                      <p className="note"><i className="far fa-clock fa-sm"></i> {t('form.totalhours')}: {(duration >= 60) && (parseInt(duration / 60) + ' hours')} {(duration % 60 > 0) && ((duration % 60) + ' minutes')}</p>
                      <div className="totalpay">{t('form.amount')}: ${toDollar(dayjob.amount)}</div>
                      {/* <p className="note">Entered By Tan Kok Meng (Supervisor)</p> */}
                    </div>
                  </Fade>
                </div>
                <div className="fixed-bottom">
                  <div className="quick-link quick-link-2">
                    {(authUser.claims.role === ROLES.DRIVER)
                      ? <div>
                        <Link to={{
                          pathname: ROUTES.RECORDS,
                          state: { fromDate: dayjob.startAt }
                        }}>
                          <i className="fas fa-long-arrow-alt-left fa-lg"></i><br />
                          <span className="btn-2">{t('form.back')}</span>
                        </Link>
                      </div>
                      : <div>
                        <Link to={{
                          pathname: ROUTES.RECORDS_LIST.replace(':id', dayjob.driverId),
                          state: { fromDate: dayjob.startAt }
                        }}>
                          <i className="fas fa-long-arrow-alt-left fa-lg"></i><br />
                          <span className="btn-2">{t('form.back')}</span>
                        </Link>
                      </div>
                    }
                    {(authUser.claims.role === ROLES.ADMIN)
                      ? <div>
                        <Link to={ROUTES.OTHER_RECORDS_EDIT.replace(':uid', dayjob.driverId).replace(':id', dayjob.rid)}>
                          <i className="fas fa-edit fa-lg"></i><br />
                          <span className="btn-2">{t('form.edit')}</span>
                        </Link>
                      </div>
                      : null
                    }
                  </div>
                </div>
              </React.Fragment>}
            </AuthUserContext.Consumer>
          )
        }
      </React.Fragment>
    );
  }
}

RecordsView.contextType = AuthUserContext;

const condition = authUser => !!authUser;
export default withTranslate(withAuthorization(condition)(withFirebase((RecordsView))));