import React from 'react';

import { withFirebase } from '../Firebase';
import Select from './Select';

/*
wrapper for job selection
*/
class JobSelect extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      jobsList: []
    };
  }

  componentDidMount() {
    this.props.firebase.jobs().orderByChild('type').on('value', snap => {
      const jobsList = []; // convert into array of label values for use in option
      snap.forEach(item => {
        const val = item.val();
        jobsList.push({ label: val.type, value: item.key });
      });
      this.setState({ jobsList });
    });
  }

  componentWillUnmount() {
    this.props.firebase.jobs().off();
  }

  render() {
    let props = this.props;
    let { jobsList } = this.state;

    return (
      <Select
        {...props}
        options={jobsList}
      />
    );
  }

}

export default withFirebase(JobSelect);
