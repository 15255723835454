import React from 'react';

import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import withFade from '../UI/withFade';
import { toDollar } from '../../constants/math';
import * as ROLES from '../../constants/roles';
import Input from '../Form/Input';
import '../Form/CustomForm.css';

const INITIAL_STATE = {
  loading: false,
  rate: null
}

class DayRatesView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.dayrate()
      .on('value', snapshot => {
        const rate = snapshot.val();

        this.setState({
          rate,
          loading: false,
        });
      });
  }

  componentWillUnmount() {
    this.props.firebase.dayrate().off()
  }

  render() {
    const { loading, rate } = this.state;

    return (
      <React.Fragment>
        {loading
          ? <p>Loading ...</p>
          : rate && (
            <form className="customform rateform" onSubmit={this.onSubmit}>
              <Input
                name="amount"
                className=""
                labelText="Amount ($) per day (8hr)"
                type="text"
                value={toDollar(rate.amount)}
                disabled={true}
              />
            </form>
          )
        }
      </React.Fragment>
    );
  }
}

const condition = authUser => !!authUser && (authUser.claims.role === ROLES.DRIVER || authUser.claims.role === ROLES.SUPERVISOR);
export default withAuthorization(condition)(withFirebase(withFade(DayRatesView)));