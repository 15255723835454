import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { withAuthorization } from '../Session';
import * as ROUTES from '../../constants/routes';
import CompaniesList from './CompaniesList';
import CompaniesAdd from './CompaniesAdd';
import CompaniesEdit from './CompaniesEdit';
import CompaniesDelete from './CompaniesDelete';

const Companies = () => (
  <div className="main">
    <div className="main-wrapper">
      <div className="top">
        <Switch>
          <Route exact path={ROUTES.COMPANIES_ADD} component={CompaniesAdd} />
          <Route exact path={ROUTES.COMPANIES_DELETE} component={CompaniesDelete} />
          <Route exact path={ROUTES.COMPANIES_EDIT} component={CompaniesEdit} />
          <Route exact path={ROUTES.COMPANIES} component={CompaniesList} />
        </Switch>
      </div>
    </div>
  </div>

);

const condition = authUser => !!authUser; 
export default withAuthorization(condition)(Companies);