import React from 'react';
import { Link } from 'react-router-dom';
// import withFade from '../UI/withFade';
import Fade from 'react-reveal/Fade';
import moment from 'moment';
// import SwipeToDelete from 'react-swipe-to-delete-component';

import MySwipe from './MySwipe';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import Actionbar from '../UI/Actionbar';
import { withTranslate } from '../Language';
import { withAuthorization, AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';

import 'react-swipe-to-delete-component/dist/swipe-to-delete.css';

const NoticesList = ({ notices, onDelete, history }) => {
  if (!notices || Object.keys(notices).length === 0)
    return <p>No notifications</p>;

  var onClick = path => () => {
    history.push(path);
  }

  var rows = [];
  Object.keys(notices).forEach(key => {
    const notice = notices[key];
    const action = (notice.action === 'add') ? 'plus' : ((notice.action === 'edit') ? 'edit' : 'minus');
    const color = (notice.action === 'add') ? 'green' : ((notice.action === 'edit') ? '' : 'red');
    if (notice.type === 'trip') {
      rows.push(
        <MySwipe key={key} onDelete={onDelete(key)} onClick={onClick(`${ROUTES.RECORDS}/${notice.driverId}/${notice.key}`)}>
          <div className="list-group-item trip">
            <div>
              <strong>{notice.startLocation} <i className="fas fa-arrow-right fa-sm"></i> {notice.endLocation}</strong> ({moment(notice.date).format('MMM D, ddd')})
              <br /><i className="fas fa-car fa-sm"></i> {notice.driver}
              <br /><span className={color}>
                <i className={`fas fa-user-${action} fa-sm`}></i> {notice.actor} ({notice.actorRole}), {moment(notice.dts).calendar()}
              </span>
            </div>
          </div>
        </MySwipe>
      );
    } else if (notice.type === 'wait') {
      rows.push(
        <MySwipe key={key} onDelete={onDelete(key)} onClick={onClick(`${ROUTES.WAITING_RECORDS}/${notice.driverId}/${notice.key}`)}>
          <div className="list-group-item wait">
            <div>
              <strong>{notice.siteLocation} <i className="fas fa-hourglass-half fa-sm grey"></i></strong> ({moment(notice.date).format('MMM D, ddd')})
              <br /><i className="fas fa-car fa-sm"></i> {notice.driver}
              <br /><span className={color}>
                <i className={`fas fa-user-${action} fa-sm`}></i> {notice.actor} ({notice.actorRole}), {moment(notice.dts).calendar()}
              </span>
            </div>
          </div>
        </MySwipe>
      );
    } else if (notice.type === 'dayjob') {
      rows.push(
        <MySwipe key={key} onDelete={onDelete(key)} onClick={onClick(`${ROUTES.OTHER_RECORDS}/${notice.driverId}/${notice.key}`)}>
          <div className="list-group-item dayjob">
            <div>
              <strong>{notice.siteLocation} <i className="fas fa-calendar-day fa-sm grey"></i></strong> ({moment(notice.date).format('MMM D, ddd')})
              <br /><i className="fas fa-car fa-sm"></i> {notice.driver}
              <br /><span className={color}>
                <i className={`fas fa-user-${action} fa-sm`}></i> {notice.actor} ({notice.actorRole}), {moment(notice.dts).calendar()}
              </span>
            </div>
          </div>
        </MySwipe>
      );
    } else if (notice.type === 'workshop') {
      rows.push(
        <MySwipe key={key} onDelete={onDelete(key)} onClick={onClick(`${ROUTES.WORKSHOP_RECORDS}/${notice.driverId}/${notice.key}`)}>
          <div className="list-group-item workshop">
            <div>
              <strong>{notice.siteLocation} <i className="fas fa-tools fa-sm grey"></i></strong> ({moment(notice.date).format('MMM D, ddd')})
              <br /><i className="fas fa-car fa-sm"></i> {notice.driver}
              <br /><span className={color}>
                <i className={`fas fa-user-${action} fa-sm`}></i> {notice.actor} ({notice.actorRole}), {moment(notice.dts).calendar()}
              </span>
            </div>
          </div>
        </MySwipe>
      );
    } else if (notice.type === 'waytrip') {
      rows.push(
        <MySwipe key={key} onDelete={onDelete(key)} onClick={onClick(`${ROUTES.WAY_RECORDS}/${notice.driverId}/${notice.key}`)}>
          <div className="list-group-item waytrip">
            <div>
              <strong>{notice.startLocation} <i className="fas fa-arrow-right fa-sm"></i> {notice.endLocation}</strong> ({moment(notice.date).format('MMM D, ddd')})
              <br /><i className="fas fa-car fa-sm"></i> {notice.driver}
              <br /><span className={color}>
                <i className={`fas fa-user-${action} fa-sm`}></i> {notice.actor} ({notice.actorRole}), {moment(notice.dts).calendar()}
              </span>
            </div>
          </div>
        </MySwipe>
      );
    }

  });

  return rows.reverse();
};

const reduceKey = (key) => {
  return key.substring(0, key.length - 1) + String.fromCharCode(key.charCodeAt(key.length - 1) - 1);
}

class Notices extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      hasMore: true,
      notices: [],
      lastKey: '',
    }

    this.noticesRef = null;
    this._isMounted = false;

    // Binds our scroll event handler
    window.onscroll = () => {
      const {
        loadMore,
        state: {
          loading,
          hasMore,
        },
      } = this;

      // Bails early if:
      // * there's an error
      // * it's already loading
      // * there's nothing left to load
      if (loading || !hasMore) return;

      // Checks that the page has scrolled to the bottom
      // document.documentElement.scrollTop doesnt work on ios, use window.pageYOffset instead
      if (
        window.innerHeight + window.pageYOffset
        >= document.documentElement.offsetHeight
      ) {
        loadMore();
      }
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadMore();
  }

  componentWillUnmount() {
    this._isMounted = false;
    // if(this.noticesRef) this.noticesRef.off(); // old code where .on('value') was used
    window.onscroll = null; // remove the .onscroll bind; note using this syntax instead of add/removeEventListener('scroll') means only 1 single bind
  }

  // append new page of data to current list
  loadMore = (e) => {
    if(e) e.preventDefault();
    const pageSize = 8;

    this.setState({ loading: true }, () => {
      this.noticesRef = this.props.firebase.notices(this.context.uid).orderByKey();
      if (this.state.lastKey) this.noticesRef = this.noticesRef.endAt(reduceKey(this.state.lastKey));
      this.noticesRef.limitToLast(pageSize).once('value')
        .then(snap => {
          let earlierNotices = snap.val();
          if (this._isMounted) {
            if (earlierNotices) {
              const lastKey = Object.keys(earlierNotices)[0];
              const hasMore = (snap.numChildren() >= pageSize);
              this.setState(prevState => ({ loading: false, lastKey, hasMore, notices: { ...earlierNotices, ...prevState.notices } }));
            } else {
              this.setState({ loading: false, hasMore: false });
            }
          }
        });
    });
  }

  onDelete = (key) => () => {
    this.props.firebase.notices(this.context.uid).child(key).remove();
    // no need notification popup, intuitive
  }

  render() {
    const { loading, notices, hasMore } = this.state;
    const t = this.props.t;

    return (
      <React.Fragment>
        <div className="top">
          <h1>{t('dashboard.title')}</h1>
          {this.context && this.context.claims && this.context.claims.role === ROLES.ADMIN && 
            <React.Fragment>
              <Link to={ROUTES.DASHBOARD} className="topnav">Notifications</Link>{' | '}
              <Link to={ROUTES.CHARTS} className="topnav">Performances</Link>
            </React.Fragment>
          }
          <Fade>
            <h3>{t('dashboard.notifications')}</h3>
            <NoticesList notices={notices} onDelete={this.onDelete} history={this.props.history} />
            { loading 
              ? <p>Loading ...</p>
              : <React.Fragment>
                {hasMore && 
                  <center>
                    <a href="/#" className="btn-2" onClick={this.loadMore}>Load More</a>
                  </center>
                }
              </React.Fragment>
            }
          </Fade>
        </div>
        <Actionbar />
      </React.Fragment>
    );
  }
}

Notices.contextType = AuthUserContext;

const condition = authUser => !!authUser; // accessible as long as logged in

// export default withFade(Home); // fade causes the quicklink to be not fixed bottom till fade ends
export default withTranslate(withAuthorization(condition)(withFirebase(Notices)));
  