export const LANDING = '/';
export const SIGN_IN = '/signin';
export const DASHBOARD = '/dashboard';
export const CHARTS = '/dashboard/charts';
export const ACCOUNT = '/account';

export const USERS = '/users';
export const USER_ADD = '/users/add';
export const USER_DETAILS = '/users/:id';
export const USER_PASSWORD = '/users/:id/password';

export const LOCATIONS = '/locations';
export const LOCATIONS_ADD = '/locations/points/add';
export const LOCATIONS_DELETE = '/locations/points/delete/:id';
export const LOCATIONS_EDIT = '/locations/points/:id';
export const LOCATIONS_DISTANCE = '/locations/:id'; // modal version later instead

export const RATES = '/rates';
export const RATES_ADD = '/rates/add';
export const RATES_DELETE = '/rates/delete/:id';
export const RATES_EDIT = '/rates/:id';

export const WAITING_RATES = '/rates/waitingrates';
export const DAY_RATES = '/rates/dayrates';
export const WORKSHOP_RATES = '/rates/workshoprates';

// nesting under '/rates' can be too complex; but we will need to manually fix navi active if using other subroutes
export const WAY_RATES = '/wayrates';
export const WAY_RATES_ADD = '/wayrates/add';
export const WAY_RATES_DELETE = '/wayrates/delete/:id';
export const WAY_RATES_EDIT = '/wayrates/:id';

// export const JOB_RATES = '/jobrates';
// export const JOB_RATES_ADD = '/jobrates/add';
// export const JOB_RATES_DELETE = '/jobrates/delete/:id';
// export const JOB_RATES_EDIT = '/jobrates/:id';

export const RECORDS = '/records';
export const RECORDS_ADD = '/records/add';
export const RECORDS_LIST = '/records/list/:id'; // records of driver id
export const RECORDS_DELETE = '/records/delete/:uid/:id';
export const RECORDS_EDIT = '/records/edit/:uid/:id';
export const RECORDS_VIEW = '/records/:uid/:id';

export const WAITING_RECORDS = '/waitingrecords';
export const WAITING_RECORDS_ADD = '/waitingrecords/add';
export const WAITING_RECORDS_DELETE = '/waitingrecords/delete/:uid/:id';
export const WAITING_RECORDS_EDIT = '/waitingrecords/edit/:uid/:id';
export const WAITING_RECORDS_VIEW = '/waitingrecords/:uid/:id';

export const WORKSHOP_RECORDS = '/workshoprecords';
export const WORKSHOP_RECORDS_ADD = '/workshoprecords/add';
export const WORKSHOP_RECORDS_DELETE = '/workshoprecords/delete/:uid/:id';
export const WORKSHOP_RECORDS_EDIT = '/workshoprecords/edit/:uid/:id';
export const WORKSHOP_RECORDS_VIEW = '/workshoprecords/:uid/:id';

export const OTHER_RECORDS = '/dayjobs';
export const OTHER_RECORDS_ADD = '/dayjobs/add';
export const OTHER_RECORDS_DELETE = '/dayjobs/delete/:uid/:id';
export const OTHER_RECORDS_EDIT = '/dayjobs/edit/:uid/:id';
export const OTHER_RECORDS_VIEW = '/dayjobs/:uid/:id';

export const WAY_RECORDS = '/waytrips';
export const WAY_RECORDS_ADD = '/waytrips/add';
export const WAY_RECORDS_DELETE = '/waytrips/delete/:uid/:id';
export const WAY_RECORDS_EDIT = '/waytrips/edit/:uid/:id';
export const WAY_RECORDS_VIEW = '/waytrips/:uid/:id';

export const VEHICLES = '/vehicles';
export const VEHICLES_ADD = '/vehicles/add';
export const VEHICLES_DELETE = '/vehicles/delete/:id';
export const VEHICLES_EDIT = '/vehicles/:id';

export const COMPANIES = '/companies';
export const COMPANIES_ADD = '/companies/add';
export const COMPANIES_DELETE = '/companies/delete/:id';
export const COMPANIES_EDIT = '/companies/:id';

export const JOBS = '/jobs';
export const JOBS_ADD = '/jobs/add';
export const JOBS_DELETE = '/jobs/delete/:id';
export const JOBS_EDIT = '/jobs/:id';
