import React from 'react';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { withFirebase } from '../Firebase';
import { withAuthorization, AuthUserContext } from '../Session';
import { withTranslate } from '../Language';
import withFade from '../UI/withFade';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import * as RATES from '../../constants/rates';
import { toDollar } from '../../constants/math';
import Input from '../Form/Input';
import CompanySelect from '../Form/CompanySelect';
import JobSelect from '../Form/JobSelect';
import LocationSelect from '../Form/LocationSelect';
import VehicleSelect from '../Form/VehicleSelect';
import FormValidator from '../Form/FormValidator';

import '../Form/CustomForm.css';

const moment = extendMoment(Moment);

const INITIAL_STATE = {
  saving: false,
  form: {
    dateToday: '',
    vehicleId: '',
    jobId: '',
    startLocationId: '',
    endLocationId: '',
    companyId: '',
    ticket: '',
    remarks: '',
    startAt: '',
    endAt: '',
  },
  today: null,
  distance: 0, // used for distance calc
  ratesList: [], // used for amount display (re-calc on server insert)
  recordsList: [],
}

class RecordsAddDriver extends React.Component {
  constructor(props) {
    super(props);

    const today = new Date();

    this.validator = new FormValidator([
      {
        field: 'dateToday',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.daterequired'
      },
      {
        field: 'dateToday',
        method: 'isBefore',
        args: [moment(today).add(1, 'days').startOf('day').toString()],
        validWhen: true,
        message: 'form.dateinvalid'
      },
      {
        field: 'vehicleId',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.vehiclerequired'
      },
      {
        field: 'jobId',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.jobrequired'
      },
      {
        field: 'startLocationId',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.startlocationrequired'
      },
      {
        field: 'endLocationId',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.endlocationrequired'
      },
      {
        field: 'endLocationId',
        method: this.uniqueLocation,
        validWhen: true,
        message: 'form.endlocationinvalid'
      },
      {
        field: 'companyId',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.companyrequired'
      },
      {
        field: 'startAt',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.startatrequired'
      },
      {
        field: 'endAt',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.endatrequired'
      },
      {
        field: 'endAt',
        method: this.validTimes,
        validWhen: true,
        message: 'form.endatinvalid'
      }
    ]);

    this.state = {
      ...INITIAL_STATE,
      validation: this.validator.valid(),
      today
    };

    this.submitted = false;
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    var fromSort = (a, b) => {
      if (a.from < b.from) return -1;
      if (a.from > b.from) return 1;
      return 0;
    };

    this.props.firebase.rates().on('value', snap => {
      const ratesList = [];
      snap.forEach(item => {
        const val = item.val();
        ratesList.push({
          ...val,
          key: item.key
        });
      });
      this.setState({ ratesList: ratesList.sort(fromSort) });
    });

  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.firebase.rates().off();
  }

  onSubmit = e => {
    e.preventDefault();
    const validForm = this.checkValidForm();
    const { form } = this.state;
    this.submitted = true;

    if (validForm) {
      // start/end should be timestamps; only send ids over; let server determine clash and handle approp
      this.setState({ saving: true }, () => {
        const startTime = moment(`${form.dateToday} ${form.startAt}`);
        const endTime = moment(`${form.dateToday} ${form.endAt}`);
        
        this.props.firebase.createTripRecord({
          date: form.dateToday, // just for lookup, start/end times are already contained
          vehicleId: form.vehicleId,
          jobId: form.jobId,
          startLocationId: form.startLocationId,
          endLocationId: form.endLocationId,
          companyId: form.companyId,
          ticket: form.ticket,
          remarks: form.remarks,
          // startAt: startTime.getTime(),
          // endAt: endTime.getTime()
          startAt: startTime.valueOf(),
          endAt: endTime.valueOf()
        })
          .then(() => {
            NotificationManager.success('Record created');
            this.props.history.push(ROUTES.RECORDS);
          })
          .catch(err => {
            if (this._isMounted) this.setState({ saving: false });
            NotificationManager.error(err.message);
          });
      });
    } else {
      console.log('Invalid submission');
    }
  }

  updateDistance = () => {
    const { form } = this.state;

    if (form.startLocationId && form.endLocationId) {
      this.props.firebase.distance(form.startLocationId).child(`${form.endLocationId}`).once('value', snap => {
        const val = snap.val();
        if (this._isMounted) {
          this.setState({ distance: val.distance });
        }
      });
    } else {
      if (this._isMounted) {
        this.setState({ distance: 0 }); // need to reset in case switching from named location to blank
      }
    }
  }

  updateRecordsList = () => {
    const { form } = this.state;
    const userContext = this.context;
    var recordsList = [];
    var endSort = (a, b) => { // latest first
      if (a.endAt > b.endAt) return -1;
      if (a.endAt < b.endAt) return 1;
      return 0;
    };
    
    if (userContext.uid && form.dateToday) {
      this.props.firebase.trips(userContext.uid).orderByChild('date').equalTo(form.dateToday)
        .once('value')
        .then(snap => {
          snap.forEach(item => {
            recordsList.push({ ...item.val(), recordtype: 'trip' });
          });

          return this.props.firebase.waits(userContext.uid).orderByChild('date').equalTo(form.dateToday).once('value');
        })
        .then(snap => {
          snap.forEach(item => {
            recordsList.push({ ...item.val(), recordtype: 'wait' });
          });

          return this.props.firebase.dayjobs(userContext.uid).orderByChild('date').equalTo(form.dateToday).once('value');
        })
        .then(snap => {
          snap.forEach(item => {
            recordsList.push({ ...item.val(), recordtype: 'dayjob' });
          });

          return this.props.firebase.workshops(userContext.uid).orderByChild('date').equalTo(form.dateToday).once('value');
        })
        .then(snap => {
          snap.forEach(item => {
            recordsList.push({ ...item.val(), recordtype: 'workshop' });
          });

          return this.props.firebase.waytrips(userContext.uid).orderByChild('date').equalTo(form.dateToday).once('value');
        })
        .then(snap => {
          snap.forEach(item => {
            recordsList.push({ ...item.val(), recordtype: 'waytrip' });
          });

          recordsList.sort(endSort);
          if (this._isMounted) this.setState({ recordsList });
        });
      
    }
  }

  onChange = (e, cb) => {
    const form = Object.assign({}, this.state.form);
    form[e.target.name] = e.target.value;
    this.setState({ form }, () => {
      if (cb) cb();
    });
  }

  onResetForm = e => {
    e.preventDefault();
    this.setState({
      ...INITIAL_STATE,
      validation: this.validator.valid()
    });
    this.submitted = false;
  }

  checkValidForm = () => {
    const validation = this.validator.validate(this.state.form);
    this.setState({ validation: validation });
    return validation.isValid;
  }

  uniqueLocation = (end) => { // check start/end not same
    const { form } = this.state;
    return end !== form.startLocationId;
  }

  validTimes = (endAt) => {
    const { form } = this.state;
    return endAt > form.startAt;
  }

  getAmount = (distance) => { // abstract here since we need to use state ratesList, but otherwise not used in the render
    const { ratesList, recordsList, form } = this.state; // ratesList in asc order
    var rate = 0;
    var multiplier = RATES.BASE;

    ratesList.forEach(item => { // find the last item smaller than distance
      if (item.from <= distance) {
        rate = item.amount;
      }
    });

    if(form.dateToday && form.startAt) {
      // recordsList.forEach(item => { // find the latest item earlier than this record, and update multiplier if location matches
      //   const startTime = moment(`${form.dateToday} ${form.startAt}`).valueOf();
      //   if (item.endAt <= startTime) {
      //     if (item.endLocationId === form.startLocationId) { // cannot collapse into single conditional, as we want to reset to 1 if the next later record isnt location-matched
      //       multiplier = RATES.CONTINUE;
      //     } else {
      //       multiplier = RATES.BASE;
      //     }
      //   }
      // });
      const startTime = moment(`${form.dateToday} ${form.startAt}`).valueOf();
      var recordsBefore = recordsList.filter(item => {
        return item.endAt <= startTime;
      });
      if (recordsBefore.length) {
        if ((recordsBefore[0].recordtype === 'trip') && (recordsBefore[0].endLocationId === form.startLocationId)) {
          multiplier = RATES.CONTINUE;
        } else if (
          (['wait', 'dayjob'].indexOf(recordsBefore[0].recordtype) > -1) &&
          (recordsBefore[0].siteLocationId === form.startLocationId) &&
          (recordsBefore.length > 1) &&
          (recordsBefore[1].recordtype === 'trip') &&
          (recordsBefore[1].endLocationId === form.startLocationId)
        ) {
          multiplier = RATES.CONTINUE;
        }
      }
    }

    return rate * multiplier;
  }

  render() {
    const { form, saving, today, distance } = this.state;
    const validation = this.submitted ? this.validator.validate(form) : this.state.validation;
    const amount = distance ? this.getAmount(distance) : 0;
    var duration = 0;
    const t = this.props.t;
    
    if (form.dateToday && form.startAt && form.endAt) {
      // difference, if neg set to zero
      // const startTime = new Date(`${form.dateToday} ${form.startAt}`); // ios doesnt support space separator for time, require 'T' or use momentjs
      // const endTime = new Date(`${form.dateToday} ${form.endAt}`);
      const startTime = moment(`${form.dateToday} ${form.startAt}`);
      const endTime = moment(`${form.dateToday} ${form.endAt}`);
      const range = moment.range(startTime, endTime);
      if (range.diff() > 0) {
        duration = range.diff('minutes');
      }
    }

    return (
      <React.Fragment>
        <h1>{t('trips.add')}</h1>
        <Link to={ROUTES.RECORDS} className="topnav"><i className="fas fa-arrow-left fa-sm"></i> {t('form.backtorecords')}</Link>
        <form className="customform tripform" onSubmit={this.onSubmit}>
          <Input
            name="dateToday"
            className=""
            labelText={t('form.date')}
            type="date"
            value={form.dateToday}
            invalid={validation.dateToday.isInvalid}
            message={t(validation.dateToday.message)}
            onChange={e => this.onChange(e, this.updateRecordsList)}
            inputProps={{
              max: moment(today).format('YYYY-MM-DD')
            }}
          />
          <VehicleSelect
            name="vehicleId"
            className="noappearance"
            labelText={t('form.vehicle')}
            value={form.vehicleId}
            invalid={validation.vehicleId.isInvalid}
            message={t(validation.vehicleId.message)}
            onChange={this.onChange}
          />
          <JobSelect
            name="jobId"
            className="noappearance"
            labelText={t('form.job')}
            value={form.jobId}
            invalid={validation.jobId.isInvalid}
            message={t(validation.jobId.message)}
            onChange={this.onChange}
          />
          <LocationSelect
            name="startLocationId"
            className="noappearance"
            labelText={t('form.startlocation')}
            value={form.startLocationId}
            invalid={validation.startLocationId.isInvalid}
            message={t(validation.startLocationId.message)}
            onChange={e => this.onChange(e, this.updateDistance)}
            disabledOptions={[form.endLocationId]}
          />
          <LocationSelect
            name="endLocationId"
            className="noappearance"
            labelText={t('form.endlocation')}
            value={form.endLocationId}
            invalid={validation.endLocationId.isInvalid}
            message={t(validation.endLocationId.message)}
            onChange={e => this.onChange(e, this.updateDistance)}
            disabledOptions={[form.startLocationId]}
          />
          <p className="note">
            <i className="fas fa-route fa-sm"></i> Distance: {distance}km
          </p>
          <CompanySelect
            name="companyId"
            className="noappearance"
            labelText={t('form.company')}
            value={form.companyId}
            invalid={validation.companyId.isInvalid}
            message={t(validation.companyId.message)}
            onChange={this.onChange}
          />
          <Input
            name="ticket"
            className=""
            labelText={t('form.ticket')}
            type="text"
            value={form.ticket}
            onChange={this.onChange}
          />
          <Input
            name="remarks"
            className=""
            labelText={t('form.remarks')}
            type="text"
            value={form.remarks}
            onChange={this.onChange}
          />
          <Input
            name="startAt"
            className=""
            labelText={t('form.startat')}
            type="time"
            value={form.startAt}
            invalid={validation.startAt.isInvalid}
            message={t(validation.startAt.message)}
            onChange={this.onChange}
          />
          <Input
            name="endAt"
            className=""
            labelText={t('form.endat')}
            type="time"
            value={form.endAt}
            invalid={validation.endAt.isInvalid}
            message={t(validation.endAt.message)}
            onChange={this.onChange}
            inputProps={{
              min: form.startAt || ''
            }}
          />
          <p className="note"><i className="far fa-clock fa-sm"></i> {t('form.totalhours')}: {(duration >= 60) && (parseInt(duration / 60) + ' hours')} {(duration % 60 > 0) && ((duration % 60) + ' minutes')}</p>
          <div className="totalpay">{t('form.amount')}: ${toDollar(amount)}</div>
          <center>
            <button type="submit" className="positive" disabled={saving}><i className="fas fa-save fa-md"></i> {saving ? t('form.saving') : t('form.createtrip')}</button>{' '}
            <button onClick={this.onResetForm} disabled={saving}><i className="fas fa-times fa-md"></i> {t('form.reset')}</button>
          </center>
        </form>
      </React.Fragment>
    );
  }
}

RecordsAddDriver.contextType = AuthUserContext;

const condition = authUser => !!authUser && authUser.claims.role === ROLES.DRIVER;
export default withTranslate(withAuthorization(condition)(withFirebase(withFade(RecordsAddDriver))));