import React from 'react';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import { withTranslate } from '../Language';
import withFade from '../UI/withFade';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import Input from '../Form/Input';
// import Select from '../Form/Select';
import CompanySelect from '../Form/CompanySelect';
import DriverSelect from '../Form/DriverSelect';
import JobSelect from '../Form/JobSelect';
import LocationSelect from '../Form/LocationSelect';
import VehicleSelect from '../Form/VehicleSelect';
import FormValidator from '../Form/FormValidator';
import { toDollar } from '../../constants/math';

import '../Form/CustomForm.css';

const moment = extendMoment(Moment);

const INITIAL_STATE = {
  loading: false,
  saving: false,
  form: {
    dateToday: '',
    driverId: '',
    vehicleId: '',
    jobId: '',
    siteLocationId: '',
    companyId: '',
    remarks: '',
    startAt: '',
    endAt: '',
  },
  today: null,
  rate: null,
}

class RecordsAddAdmin extends React.Component {
  constructor(props) {
    super(props);

    const today = new Date();

    this.validator = new FormValidator([
      {
        field: 'driverId',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.driverrequired'
      },
      {
        field: 'dateToday',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.daterequired'
      },
      {
        field: 'dateToday',
        method: 'isBefore',
        args: [moment(today).add(1, 'days').startOf('day').toString()],
        validWhen: true,
        message: 'form.dateinvalid'
      },
      {
        field: 'vehicleId',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.vehiclerequired'
      },
      {
        field: 'jobId',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.jobrequired'
      },
      {
        field: 'siteLocationId',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.sitelocationrequired'
      },
      {
        field: 'companyId',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.companyrequired'
      },
      {
        field: 'startAt',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.startatrequired'
      },
      {
        field: 'endAt',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.endatrequired'
      },
      {
        field: 'endAt',
        method: this.validTimes,
        validWhen: true,
        message: 'form.endatinvalid'
      }
    ]);

    this.state = {
      ...INITIAL_STATE,
      validation: this.validator.valid(),
      today
    };

    this.submitted = false;
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({ loading: true }, () => {
      this.props.firebase.waitingrate().child('amount').on('value', snap => {
        const rate = snap.val();
        this.setState({ 
          rate,
          loading: false
        });
      });
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.firebase.waitingrate().child('amount').off();
  }

  onSubmit = e => {
    e.preventDefault();
    const validForm = this.checkValidForm();
    const { form } = this.state;
    this.submitted = true;

    if (validForm) {
      // start/end should be timestamps; only send ids over; let server determine clash and handle approp
      this.setState({ saving: true }, () => {
        const startTime = moment(`${form.dateToday} ${form.startAt}`);
        const endTime = moment(`${form.dateToday} ${form.endAt}`);

        this.props.firebase.createWaitRecordAdmin({
          date: form.dateToday, // just for lookup, start/end times are already contained
          driverId: form.driverId,
          vehicleId: form.vehicleId,
          jobId: form.jobId,
          siteLocationId: form.siteLocationId,
          companyId: form.companyId,
          remarks: form.remarks,
          startAt: startTime.valueOf(),
          endAt: endTime.valueOf()
        })
          .then(() => {
            NotificationManager.success('Record created');
            this.props.history.push(ROUTES.RECORDS);
          })
          .catch(err => {
            if (this._isMounted) this.setState({ saving: false });
            NotificationManager.error(err.message);
          });
      });
    } else {
      console.log('Invalid submission');
    }
  }

  onChange = (e, cb) => {
    const form = Object.assign({}, this.state.form);
    form[e.target.name] = e.target.value;
    this.setState({ form }, () => {
      if (cb) cb();
    });
  }

  onResetForm = e => {
    e.preventDefault();
    this.setState({
      ...INITIAL_STATE,
      validation: this.validator.valid()
    });
    this.submitted = false;
  }

  checkValidForm = () => {
    const validation = this.validator.validate(this.state.form);
    this.setState({ validation: validation });
    return validation.isValid;
  }

  validTimes = (endAt) => {
    const { form } = this.state;
    return endAt > form.startAt;
  }

  render() {
    const { form, saving, today, rate } = this.state;
    const validation = this.submitted ? this.validator.validate(form) : this.state.validation;
    var duration = 0;

    if (form.dateToday && form.startAt && form.endAt) {
      // difference, if neg set to zero
      const startTime = moment(`${form.dateToday} ${form.startAt}`);
      const endTime = moment(`${form.dateToday} ${form.endAt}`);
      const range = moment.range(startTime, endTime);
      if (range.diff() > 0) {
        duration = range.diff('minutes');
      }
    }

    const amount = (duration && rate) ? (duration * rate / 60) : 0;
    const t = this.props.t;

    return (
      <React.Fragment>
        <h1>{t('waits.add')}</h1>
        <Link to={ROUTES.RECORDS} className="topnav"><i className="fas fa-arrow-left fa-sm"></i> {t('form.backtorecords')}</Link>
        <form className="customform waitingform" onSubmit={this.onSubmit}>
          <DriverSelect
            name="driverId"
            className="noappearance"
            labelText={t('form.driver')}
            value={form.driverId}
            invalid={validation.driverId.isInvalid}
            message={t(validation.driverId.message)}
            onChange={this.onChange}
          />
          <Input
            name="dateToday"
            className=""
            labelText={t('form.date')}
            type="date"
            value={form.dateToday}
            invalid={validation.dateToday.isInvalid}
            message={t(validation.dateToday.message)}
            onChange={this.onChange}
            inputProps={{
              max: moment(today).format('YYYY-MM-DD')
            }}
          />
          <VehicleSelect
            name="vehicleId"
            className="noappearance"
            labelText={t('form.vehicle')}
            value={form.vehicleId}
            invalid={validation.vehicleId.isInvalid}
            message={t(validation.vehicleId.message)}
            onChange={this.onChange}
          />
          <JobSelect
            name="jobId"
            className="noappearance"
            labelText={t('form.job')}
            value={form.jobId}
            invalid={validation.jobId.isInvalid}
            message={t(validation.jobId.message)}
            onChange={this.onChange}
          />
          <LocationSelect
            name="siteLocationId"
            className="noappearance"
            labelText={t('form.sitelocation')}
            value={form.siteLocationId}
            invalid={validation.siteLocationId.isInvalid}
            message={t(validation.siteLocationId.message)}
            onChange={this.onChange}
          />
          <CompanySelect
            name="companyId"
            className="noappearance"
            labelText={t('form.company')}
            value={form.companyId}
            invalid={validation.companyId.isInvalid}
            message={t(validation.companyId.message)}
            onChange={this.onChange}
          />
          <Input
            name="remarks"
            className=""
            labelText={t('form.remarks')}
            type="text"
            value={form.remarks}
            onChange={this.onChange}
          />
          <Input
            name="startAt"
            className=""
            labelText={t('form.startat')}
            type="time"
            value={form.startAt}
            invalid={validation.startAt.isInvalid}
            message={t(validation.startAt.message)}
            onChange={this.onChange}
          />
          <Input
            name="endAt"
            className=""
            labelText={t('form.endat')}
            type="time"
            value={form.endAt}
            invalid={validation.endAt.isInvalid}
            message={t(validation.endAt.message)}
            onChange={this.onChange}
            inputProps={{
              min: form.startAt || ''
            }}
          />
          <p className="note"><i className="far fa-clock fa-sm"></i> {t('form.totalhours')}: {(duration >= 60) && (parseInt(duration / 60) + ' hours')} {(duration % 60 > 0) && ((duration % 60) + ' minutes')}</p>
          <div className="totalpay">{t('form.amount')}: ${toDollar(amount)}</div>
          <center>
            <button type="submit" className="positive" disabled={saving}><i className="fas fa-save fa-md"></i> {saving ? t('form.saving') : 'Create Waiting Record'}</button>{' '}
            <button onClick={this.onResetForm} disabled={saving}><i className="fas fa-times fa-md"></i> {t('form.reset')}</button>
          </center>
        </form>
      </React.Fragment>
    );
  }
}

const condition = authUser => !!authUser && (authUser.claims.role === ROLES.ADMIN || authUser.claims.role === ROLES.SUPERVISOR);
export default withTranslate(withAuthorization(condition)(withFirebase(withFade(RecordsAddAdmin))));