import React from 'react';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import Switch from 'react-switch';
import moment from 'moment';

import withFade from '../UI/withFade';
import { withTranslate } from '../Language';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import * as EMAIL from '../../constants/email';
import Input from '../Form/Input';
import Select from '../Form/Select';
import FormValidator from '../Form/FormValidator';
import { AuthUserContext } from '../Session';

import '../Form/CustomForm.css';

/*
1. view/edit form ui toggle
  we will render together as disabled form, easier ux no jumps
  change the details as a stateful then so we dun have to manage state in parent and pass down?
  but the parent does nothing, might as well bring it up to parent;
  a. toggle [v]
  b. only name, nothing else [v]
2. update details in dbref only [v]
3. update details in auth, (eg username/email), will require node server <<<< do this 
    a. email/uname (as far as the users know, they are the same, but we need to include/exclude the prefix ourselves)
      for convenience, we should store the same value for both?
      before display we should sanitize
      after submit, we should append
        nope, auth version is really only used on login, which is handled by the login page only.
        everywhere else we are only viewing username, never email, so can just display username as is (email or not)
        only when saving to the auth, node side, is where the username is manipulated to become an email for updating
        counter! if we allow username storage as both email/nonemail, then aaa and aaa@domain would be unique db usernames, but both map to the same auth user!
        so, server-side, should always store with domain (ie as email matching auth) [v]
            client-side, should sanitise if not the default domain
            db storage, as email
    b. role
  consider a const suffix in both react/node for the email;  create/update user; 
  create user (will need node server)
  user attributes: {
    username
    name
    displayRole (clone of claims role, used only for display of other users w/o need to go into admin sdk)
    createdAt, modifiedAt, loginAt (not too sure we can effect the 3rd)
    employeeid/tel?
    disabled (in auth, so another displayDisabled?); this is just so we can use the db.ref instead of going to cloudfn for retrieval, and use rules for control
    photoURL (displayPhotoURL?)
    // seems to be getting too many duplicate fields, if so, we should really consider using cloud fn for retrieval instead
      // of db.ref; retain for now, additional fields can wait, retain so we can use db ref as reference for the other functions: trips etc
  }

    next: update of role/displayRole
      seriously consider merging with common endpt; too much duplication/sync
      duplicate is good; else too dependant on auth; eg when need to migrate, we have no access to roles/email etc
    next: creating user (without the extra fields)
      <<<< DO THIS NEXT; UI form (basic fields); nodejs endpt
    next: db direct populating vehicles, routes...
    next: modelling the pricing
    next: trips
    next: the order paradigm for pricing
    enhancements: online/offline status prescence; lastlogin listener to update dbref (duplicate); table sort; photo webcam; 
                  logging/audit trail
                  language remembering; local cache
                  still figure out how to make our validator async?? (later as enhancement) xxx
                  validate fn has to be async/promise
                    so maybe we need to use resolve/reject, not then; then used in the caller

                  language for form validation messages as well (if not possible then just append both lang together)


*/


const INITIAL_STATE = {
  loading: false,
  saving: false,
  user: null,
  form: null,
  editMode: false,
}

class UsersEdit extends React.Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: 'username',
        method: 'isEmpty',
        validWhen: false,
        message: 'Username is required.'
      },
      {
        field: 'name',
        method: 'isEmpty',
        validWhen: false,
        message: 'Name is required.'
      },
      {
        field: 'displayRole',
        method: 'isEmpty',
        validWhen: false,
        message: 'Role is required.'
      }
    ]);

    this.state = { 
      ...INITIAL_STATE,
      ...props.location.state,
      validation: this.validator.valid()
    };

    this.submitted = false;
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    if(this.state.user) {
      let user = this.state.user;
      let username = user.username.replace(EMAIL.SUFFIX, '');
      this.setState({ 
        form: {
          ...user,
          username: username
        } 
      }); // reset form vars on toggle
      // note browser history cache/state will be an issue if we change perm and use browser history (state) to navigate, since the obj exists in state and not from db
      if (user.createdBy) {
        this.props.firebase.user(user.createdBy).once('value', snapshot => {
          const creator = snapshot.val();
          if (creator && this._isMounted) {
            this.setState(prevState => ({
              user: {
                ...prevState.user,
                createdByName: creator.name
              },
              form: {
                ...prevState.form,
                createdByName: creator.name
              }
            }));
          }
        });
      }

      if (user.updatedBy) {
        this.props.firebase.user(user.updatedBy).once('value', snapshot => {
          const creator = snapshot.val();
          if (creator && this._isMounted) {
            this.setState(prevState => ({
              user: {
                ...prevState.user,
                updatedByName: creator.name
              },
              form: {
                ...prevState.form,
                updatedByName: creator.name
              }
            }));
          }
        });
      }
      return; // if user was passed from list via props, no need to get info again
    }

    this.setState({ loading: true });

    this.props.firebase.getUser(this.props.match.params.id)
      .then(response => {
        const userObj = response.data;
        const username = userObj.email.replace(EMAIL.SUFFIX, '');
        
        const user = {
          receiveEmails: userObj.receiveEmails,
          disabled: userObj.disabled,
          uid: userObj.uid,
          name: userObj.name,
          displayRole: userObj.customClaims.role,
          username,
          createdAt: userObj.createdAt,
          createdBy: userObj.createdBy,
          updatedAt: userObj.updatedAt,
          updatedBy: userObj.updatedBy,
        };

        if(this._isMounted) {
          this.setState({
            user,
            loading: false,
            form: {
              ...user
            }
          }, () => {
            if (user.createdBy) {
              this.props.firebase.user(user.createdBy).once('value', snapshot => {
                const creator = snapshot.val();
                if (creator && this._isMounted) {
                  this.setState(prevState => ({
                    user: {
                      ...prevState.user,
                      createdByName: creator.name
                    },
                    form: {
                      ...prevState.form,
                      createdByName: creator.name
                    }
                  }));
                }
              });
            }

            if (user.updatedBy) {
              this.props.firebase.user(user.updatedBy).once('value', snapshot => {
                const creator = snapshot.val();
                if (creator && this._isMounted) {
                  this.setState(prevState => ({
                    user: {
                      ...prevState.user,
                      updatedByName: creator.name
                    },
                    form: {
                      ...prevState.form,
                      updatedByName: creator.name
                    }
                  }));
                }
              });
            }
          });
        }
      })
      .catch(err => {
        NotificationManager.error(err.message);
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
    // this.props.firebase.user(this.props.match.params.id).off();
  }

  onSubmit = e => {
    e.preventDefault();
    const validForm = this.checkValidForm();
    const { form } = this.state;
    this.submitted = true;

    if(validForm) {
      this.setState({ saving: true }, () => {
        this.props.firebase.updateUser(form).then(res => {
          if(res.data) {
            switch(res.data.status) {
              case 200:
                this.setState(prevstate => ({
                  editMode: false,
                  user: { 
                    ...prevstate.form,
                    updatedAt: (new Date()).getTime(), // estimate based on current time, not actual ts in dbrecord; saves 1 db retrieval trip
                    updatedBy: this.context.uid,
                    updatedByName: this.context.name
                  },
                  saving: false
                }));

                NotificationManager.success(res.data.message);
                break;
              case 500:
                this.setState(prevstate => ({
                  saving: false
                }));

                NotificationManager.error(res.data.message);
                break;
              default:
                this.setState(prevstate => ({
                  saving: false
                }));

                NotificationManager.info(res.data.message);
            }
          }
        }).catch(err => {
          NotificationManager.error(err.message);
          // console.log('Server error', err.code, err.message, err.details);
        });
      });
    } else {
      console.log('Invalid submission');
    }
  }

  onChange = e => {
    const form = Object.assign({}, this.state.form);
    form[e.target.name] = e.target.value;
    this.setState({ form });
  }

  onToggleEdit = e => {
    e.preventDefault();
    this.setState(prevState => ({ 
      editMode: !prevState.editMode,
      form: { 
        ...prevState.user,
        username: prevState.user.username.replace(EMAIL.SUFFIX, '')
      },
    }));
  }  

  onToggleAccount = checked => {
    const form = Object.assign({}, this.state.form);
    form.disabled = !checked;
    this.setState({ form });
  }

  onToggleNotice = checked => {
    const form = Object.assign({}, this.state.form);
    form.receiveEmails = checked;
    this.setState({ form });
  }

  checkValidForm = () => {
    const validation = this.validator.validate(this.state.form);
    this.setState({ validation: validation });
    return validation.isValid;
  }

  render() {
    const { loading, saving, user, editMode, form } = this.state;
    let validation = this.submitted ? this.validator.validate(form) : this.state.validation;
    const rolesList = ROLES.LIST;
    const t = this.props.t;

    return (
      <React.Fragment>
        <h1>{t('users.edit')}</h1>
        <Link to={ROUTES.USERS} className="topnav"><i className="fas fa-arrow-left fa-sm"></i> Back to Users</Link>
        {loading
          ? <p>Loading ...</p>
          : user && form && (
            <form className="customform userform" onSubmit={this.onSubmit}>
              <Input
                name="name"
                className=""
                labelText={t('name')}
                type="text"
                value={form.name}
                invalid={validation.name.isInvalid}
                message={validation.name.message}
                onChange={this.onChange}
                disabled={!editMode}
              />
              <Select
                name="displayRole"
                className="noappearance"
                labelText="Role"
                value={form.displayRole}
                invalid={validation.displayRole.isInvalid}
                message={validation.displayRole.message}
                onChange={this.onChange}
                options={rolesList}
                disabled={!editMode || (this.context.uid === user.uid)}
              />
              <Input
                name="username"
                className=""
                labelText={t('users.username')}
                type="text"
                value={form.username}
                invalid={validation.username.isInvalid}
                message={validation.username.message}
                onChange={this.onChange}
                disabled={!editMode}
              />
              <div style={{ marginBottom: '20px' }}>
                <label htmlFor="account-switch">
                  <Switch
                    className="input-switch"
                    onChange={this.onToggleAccount}
                    checked={!form.disabled}
                    id="account-switch"
                    disabled={!editMode || (this.context.uid === user.uid)}
                  />
                  <span style={{ verticalAlign: 'middle', display: 'inline-block', marginLeft: '5px' }}>Account Enabled</span>
                </label>
              </div>
              <div style={{ marginBottom: '20px' }}>
                <label htmlFor="notice-switch">
                  <Switch
                    className="input-switch"
                    onChange={this.onToggleNotice}
                    checked={!!form.receiveEmails && form.displayRole === ROLES.ADMIN}
                    id="notice-switch"
                    disabled={!editMode || (form.displayRole !== ROLES.ADMIN)}
                  />
                  <span style={{ verticalAlign: 'middle', display: 'inline-block', marginLeft: '5px' }}>Receive Account Notices</span>
                </label>
              </div>
              {editMode
                ? <React.Fragment>
                  <button type="submit" className="positive" disabled={saving}><i className="fas fa-save fa-md"></i> {saving ? t('form.saving') : 'Save Changes'}</button>{' '}
                  <button onClick={this.onToggleEdit} disabled={saving}><i className="fas fa-times fa-md"></i> {t('form.cancelchanges')}</button>
                </React.Fragment>
                : <React.Fragment>
                  <button onClick={this.onToggleEdit}><i className="fas fa-pencil-alt fa-md"></i> Edit User</button>{' '}
                </React.Fragment>
              }
              <p><Link to={{
                pathname: `${ROUTES.USERS}/${user.uid}/password`,
                state: { user }
              }} style={{ display:'inline-block', marginTop:'10px' }}>Update Password</Link>
              </p>
              <hr />
              {user.updatedAt && user.updatedByName &&
                <p className="footnote">
                Updated by {user.updatedByName} ({moment(user.updatedAt).calendar()})
                </p>
              }
              {user.createdAt && user.createdByName &&
                <p className="footnote">
                Created by {user.createdByName} ({moment(user.createdAt).calendar()})
                </p>
              }
            </form>
          )
        }
      </React.Fragment>
    );
  }
}

UsersEdit.contextType = AuthUserContext;

export default withTranslate(withFirebase(withFade(UsersEdit)));
