import React from 'react';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import moment from 'moment';

import { withFirebase } from '../Firebase';
import { withAuthorization, AuthUserContext } from '../Session';
import { withTranslate } from '../Language';
import withFade from '../UI/withFade';
import { toFBKey } from '../../constants/math';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import Input from '../Form/Input';
import FormValidator from '../Form/FormValidator';
import '../Form/CustomForm.css';

const INITIAL_STATE = {
  loading: false,
  saving: false,
  vehicle: null,
  form: null,
  editMode: false,
  usedvehicles: {},
}

class VehiclesEdit extends React.Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: 'number',
        method: 'isEmpty',
        validWhen: false,
        message: 'Vehicle Number is required.'
      },
      {
        field: 'number',
        method: this.uniqueNumber,   // custom function
        validWhen: true,
        message: 'Vehicle Number already exists.'
      }
    ]);

    this.state = {
      ...INITIAL_STATE,
      validation: this.validator.valid()
    };

    this.submitted = false;
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({ loading: true });

    this.props.firebase.vehicle(this.props.match.params.id)
      .once('value', snapshot => {
        const obj = snapshot.val();
        if (!obj) return this.setState({ ...INITIAL_STATE });

        const vehicle = {
          number: obj.number,
          type: obj.type,
          createdAt: obj.createdAt,
          createdBy: obj.createdBy,
          updatedAt: obj.updatedAt,
          updatedBy: obj.updatedBy,
          vid: snapshot.key
        };

        if(this._isMounted) {
          this.setState({
            vehicle,
            loading: false,
            form: {
              ...vehicle
            }
          }, () => {
            if (vehicle.createdBy) {
              this.props.firebase.user(vehicle.createdBy).once('value', snapshot => {
                const creator = snapshot.val();
                if (creator && this._isMounted) {
                  this.setState(prevState => ({
                    vehicle: {
                      ...prevState.vehicle,
                      createdByName: creator.name
                    },
                    form: {
                      ...prevState.form,
                      createdByName: creator.name
                    }
                  }));
                }
              });
            }

            if (vehicle.updatedBy) {
              this.props.firebase.user(vehicle.updatedBy).once('value', snapshot => {
                const creator = snapshot.val();
                if (creator && this._isMounted) {
                  this.setState(prevState => ({
                    vehicle: {
                      ...prevState.vehicle,
                      updatedByName: creator.name
                    },
                    form: {
                      ...prevState.form,
                      updatedByName: creator.name
                    }
                  }));
                }
              });
            }
          });
        }
        
      });

    this.props.firebase.usedvehicles().once('value')
      .then(snap => {
        const usedvehicles = snap.val();
        if(this._isMounted) {
          this.setState({ usedvehicles });
        }
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onSubmit = e => {
    e.preventDefault();
    const validForm = this.checkValidForm();
    const { form } = this.state;
    this.submitted = true;
    
    if (validForm) {
      this.setState({ saving: true }, () => {
        this.props.firebase.vehicles().child(form.vid).update({
          number: form.number,
          type: form.type,
          hashNumber: toFBKey(form.number),
          updatedAt: this.props.firebase.ServerValue.TIMESTAMP,
          updatedBy: this.context.uid
        })
          .then(() => {
            this.setState(prevstate => ({
              editMode: false,
              vehicle: { 
                ...prevstate.form,
                updatedAt: (new Date()).getTime(), // estimate based on current time, not actual ts in dbrecord; saves 1 db retrieval trip
                updatedBy: this.context.uid,
                updatedByName: this.context.name
              },
              saving: false
            }));
            NotificationManager.success('Vehicle updated');
          })
          .catch(err => {
            this.setState(prevstate => ({
              saving: false
            }));
            NotificationManager.error(err.message);
          });
      });
    } else {
      console.log('Invalid submission');
    }
  }

  onChange = e => {
    const form = Object.assign({}, this.state.form);
    form[e.target.name] = e.target.value;
    this.setState({ form });
  }

  onToggleEdit = e => {
    e.preventDefault();
    this.setState(prevState => ({
      editMode: !prevState.editMode,
      form: {
        ...prevState.vehicle,
      },
    }));
  }

  checkValidForm = () => {
    const validation = this.validator.validate(this.state.form);
    this.setState({ validation: validation });
    return validation.isValid;
  }

  uniqueNumber = (number) => {
    const { usedvehicles, vehicle } = this.state;
    const hashNumber = toFBKey(number);
    return !usedvehicles[hashNumber] || (usedvehicles[hashNumber] === vehicle.vid);
  }

  render() {
    const { form, saving, loading, vehicle, editMode } = this.state;
    const validation = this.submitted ? this.validator.validate(form) : this.state.validation;
    const t = this.props.t;

    return (
      <React.Fragment>
        <h1>{t('vehicles.edit')}</h1>
        <Link to={ROUTES.VEHICLES} className="topnav"><i className="fas fa-arrow-left fa-sm"></i> Back to Vehicles</Link>
        {loading
          ? <p>Loading ...</p>
          : vehicle && form && (
            <form className="customform vehicleform" onSubmit={this.onSubmit}>
              <Input
                name="number"
                className=""
                labelText="Vehicle Number"
                type="text"
                value={form.number}
                invalid={validation.number.isInvalid}
                message={validation.number.message}
                onChange={this.onChange}
                disabled={!editMode}
              />
              <Input
                name="type"
                className=""
                labelText="Type"
                type="text"
                value={form.type}
                onChange={this.onChange}
                disabled={!editMode}
              />
              {editMode
                ? <center>
                  <button type="submit" className="positive" disabled={saving}><i className="fas fa-save fa-md"></i> {saving ? t('form.saving') : 'Save Changes'}</button>{' '}
                  <button onClick={this.onToggleEdit} disabled={saving}><i className="fas fa-times fa-md"></i> {t('form.cancelchanges')}</button>
                </center>
                : <center>
                  <button onClick={this.onToggleEdit}><i className="fas fa-pencil-alt fa-md"></i> Edit Vehicle</button>{' '}
                  <Link to={{
                    pathname: `${ROUTES.VEHICLES}/delete/${vehicle.vid}`,
                    state: { vehicle }
                  }} className="button warn"><i className="fas fa-trash-alt fa-md"></i> Delete Vehicle</Link>
                </center>
              }
              <hr />
              {vehicle.updatedAt && vehicle.updatedByName &&
                <p className="footnote">
                Updated by {vehicle.updatedByName} ({moment(vehicle.updatedAt).calendar()})
                </p>
              }
              {vehicle.createdAt && vehicle.createdByName &&
                <p className="footnote">
                Created by {vehicle.createdByName} ({moment(vehicle.createdAt).calendar()})
                </p>
              }
            </form>
          )
        }
      </React.Fragment>
    );
  }
}

VehiclesEdit.contextType = AuthUserContext;

const condition = authUser => !!authUser && authUser.claims.role === ROLES.ADMIN;
export default withTranslate(withAuthorization(condition)(withFirebase(withFade(VehiclesEdit))));