import React from 'react';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import { withTranslate } from '../Language';
import withFade from '../UI/withFade';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import * as RATES from '../../constants/rates';
import Input from '../Form/Input';
// import Select from '../Form/Select';
import CompanySelect from '../Form/CompanySelect';
import DriverSelect from '../Form/DriverSelect';
import JobSelect from '../Form/JobSelect';
import LocationSelect from '../Form/LocationSelect';
import VehicleSelect from '../Form/VehicleSelect';
import FormValidator from '../Form/FormValidator';
import { toDollar } from '../../constants/math';

import '../Form/CustomForm.css';

const moment = extendMoment(Moment);

/*
 as admin
  date, driver, did, veh, vid, job, jid, start/end location, company, ticket, remarks, start/end time, amount
  on save need to do an async validation! so no overlap date/driver/vehicle/time
    given date, time range; there should not be any entry with overlap and the same driver OR vehicle
    will need to introduce vehicles as well, else the logic will need to be done again

adding a record as admin
  because the overlap date-time, + driver/vehicle is too complex to use fb db rules, we will need to provide a server fn for doing this instead
    cannot depend on client side check solely
  dun bother about async validation before submission, since will require a rewrite of formvalidator, perhaps later

TODO:
1. form fields ui
    date/time selectors
2. form submit, mock no db
    distance
    total hrs
    amt

3. server side handler - ref user fn, just a direct insert, need listener as well if we are builidng the id list 
    in driver/job/company/vehicle/startloc/endloc to cross link; 
    if worried about adding too much size to the current item (e.g. if we append inside a vehicle the list of related recordids, the list is going to grow
      bigger over time; logical since more trips overtime per vehicle, but this means when we retrieve the grid for vehicle the data is growing),
      we can consider placing it in yet another flat table, e.g. vehicle_triprecords: { vid: { tripid: true } }
      this feature can then be stored in db, and we can consider if we want to expose in the client later
4. server side with clash check [v]
5. server insertion AMK A > AMK Site A, 12-1pm, tester2; 1000 [v]
6. server insertion with clash detection; tester2, 1130-1230, 1230-1; deny [v]
7. server insertion with discount rate continuing; tester2, AMK Site A > Bedok, 1-130pm, 1300*0.75 [v]

8. insertion that affects the rate of a saved entry, next in time/location (seems like a good spot to use listener but with guard
    guard condition: 
      oncreate
        trigger recalc of next entry record, of the same driver/day, may cause update of the next entry of loc matches AND amt calc is different
          no guard condition, since only causes update, not new creates
      onupdate
        danger - if cause next entry update, and next entry update cause next entry update ...
        guard - after earlier fields updated (job/veh/.. relation), THEN, 
          if endlocation and/or endtime has not changed, no need to call next entry to update
            (this means altho next entry's amt is updated, its end* are not changed which will then not trigger any further update)
            
      ondelete
        similar to oncreate, but instead of comparing next entry with self, compare with prev entry if exist, and if loc match AND amt diff, 
          cause next entry update
  )
      AMK Site B > AMK A, 9-10am, tester2; 1200, and edits first entry to 1000*0.75
      e.g given tester2, AMK BC > AMK Site B, 8-830pm, 1200 (A)
      insert tester2, X > Y, 5-530pm, should not affect (But should still check! (see below))
      insert tester2, X > AMK BC, 530-6pm, should update (A) price discount (simply recalc, provide a function for this)
      insert tester3, X > Y, 6-630pm, should update (A) back up to 1200 since no longer continuing, need to recalc next trip

      similar for edit

      can be an overall income loss!
      trip A - 1000
      trip B continue before A - 10, causes A to be 750
      trip C noncontinue before A, after B - 10, causes A to be 1000
9. false - driver different should not have any clash, discount

list view
9. to give a slight improved ux without async validator, download records when driver/date updated to have a local copy for checking overlaps
    may need to reset veh/times whenever this happens since the date/driver change may invalidate veh/times, different changed fields will create 
    confusing ui if not reset


    onsubmit, saving, need to add a screen blocker, else they can edit the form fields xxxx
  
*/

const INITIAL_STATE = {
  saving: false,
  form: {
    // date, driver, did, veh, vid, job, jid, start/ end location, company, ticket, remarks, start/ end time, amount
    // xxx for best comparison we should store start/end times as dts
    // xxx think about multi timezones; date should still be a separate field just so that it is easier to extract?
    // xxx OR always assume fixed timezone? in settings?
    // if we dun make timezone assumption, it will take the browser (local)
    // which means it should be okay, if we just join the date-time together
    // and then create a date object using them (local time), before converting to ts
    // and sending to server (ie copy sent for db has to be ts (no more manipulation))
    // manipulation is thus done at client (for retreival too) to render in the correct
    // zone time
    // xxx but cant really form a date field with timezone, need a full dts
    // we will try to use timestamps instead (ms), and let UI handle conversion display
    // altho the form here are only passing Ids, the server should copy the display values as well, so that we can easily display in grid without
    // access to the dropdown list of fields, without additional reads
    dateToday: '',
    driverId: '',
    vehicleId: '',
    jobId: '',
    startLocationId: '',
    endLocationId: '',
    companyId: '',
    ticket: '',
    remarks: '',
    startAt: '',
    endAt: '',
  },
  // driversList: [],
  // vehiclesList: [],
  // jobsList: [],
  // locationsList: [], // used in form option
  // companiesList: [],
  today: null,
  distance: 0, // used for distance calc
  ratesList: [], // used for amount display (re-calc on server insert)
  recordsList: [], // xxx KIV list of records for dateToday, for local validation, ensure server validation first
  // xxx will need key, dId, vId, startId, endId, start/end time; amt is calc server side, dun trust client
  // xxx recordsList: { date, driverId, records: []  }; store date/driverId in here to determine if need to update? no need, can just activate onchange
  // xxx confusion: if we are verifying the in-use status (or overlap), then we need vehicleId
  // xxx confusion: if we are only concerned about the continuing trip for discount rate, then should be by driver purely
  // xxx assume no vehicle check atm; only doing discount rate display
}

class RecordsAddAdmin extends React.Component {
  constructor(props) {
    super(props);

    const today = new Date();
    // should not directly use t() here since constructor is run once
    // so the language will not be changed if switching language; need to move into render instead
    // and store the translation keys here
    this.validator = new FormValidator([
      {
        field: 'driverId',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.driverrequired'
      },
      {
        field: 'dateToday',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.daterequired'
      },
      {
        field: 'dateToday',
        method: 'isBefore',
        args: [ moment(today).add(1, 'days').startOf('day').toString() ],
        validWhen: true,
        message: 'form.dateinvalid'
      },
      {
        field: 'vehicleId',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.vehiclerequired'
      },
      {
        field: 'jobId',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.jobrequired'
      },
      {
        field: 'startLocationId',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.startlocationrequired'
      },
      {
        field: 'endLocationId',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.endlocationrequired'
      },
      {
        field: 'endLocationId',
        method: this.uniqueLocation,
        validWhen: true,
        message: 'form.endlocationinvalid'
      },
      {
        field: 'companyId',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.companyrequired'
      },
      {
        field: 'startAt',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.startatrequired'
      },
      {
        field: 'endAt',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.endatrequired'
      },
      {
        field: 'endAt',
        method: this.validTimes,
        validWhen: true,
        message: 'form.endatinvalid'
      }
    ]);

    this.state = {
      ...INITIAL_STATE,
      validation: this.validator.valid(),
      today
    };

    this.submitted = false;
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    // var nameSort = (a, b) => {
    //   if (a.name < b.name) return -1;
    //   if (a.name > b.name) return 1;
    //   return 0;
    // };
    var fromSort = (a, b) => {
      if (a.from < b.from) return -1;
      if (a.from > b.from) return 1;
      return 0;
    };

    // load all the reference fields
    // this.props.firebase.vehicles().orderByChild('number').on('value', snap => {
    //   const vehiclesList = []; // convert into array of label values for use in option
    //   snap.forEach(item => {
    //     const val = item.val();
    //     vehiclesList.push({ label: val.number, value: item.key });
    //   });
    //   this.setState({ vehiclesList });
    // });

    // this.props.firebase.jobs().orderByChild('type').on('value', snap => {
    //   const jobsList = []; // convert into array of label values for use in option
    //   snap.forEach(item => {
    //     const val = item.val();
    //     jobsList.push({ label: val.type, value: item.key });
    //   });
    //   this.setState({ jobsList });
    // });

    // this.props.firebase.locations().orderByChild('name').on('value', snap => {
    //   const locationsList = []; // convert into array of label values for use in option
    //   snap.forEach(item => {
    //     const val = item.val();
    //     locationsList.push({ label: val.name, value: item.key });
    //   });
    //   this.setState({ locationsList });
    // });

    // this.props.firebase.companies().orderByChild('name').on('value', snap => {
    //   const companiesList = []; // convert into array of label values for use in option
    //   snap.forEach(item => {
    //     const val = item.val();
    //     companiesList.push({ label: val.name, value: item.key });
    //   });
    //   this.setState({ companiesList });
    // });

    this.props.firebase.rates().on('value', snap => {
      const ratesList = [];
      snap.forEach(item => {
        const val = item.val();
        ratesList.push({
          ...val,
          key: item.key
        });
      });
      this.setState({ ratesList: ratesList.sort(fromSort) });
    });


    // this.props.firebase.distances().on('value')
    // xxx rather than storing this locally, we should consider calling distance(startid).child(endid/distance).val
    // xxx but how do we effect this? there has to be additional callbacks on the location selects

    /* xxx this is really not that great a design! every time record creation form is generated,
     xxx most info from db needs to be downloaded!
      - eg vehicle/job etc
      - eg distance/rates
      - eg other records for clash checking
      - the likeliest biggest is other records since this will grow, the others should be fixed size
      - worst case clash checking on server instead of client <<< DO THIS!
     */
  }

  componentWillUnmount() {
    this._isMounted = false;
    // this.props.firebase.vehicles().off();
    // this.props.firebase.jobs().off();
    // this.props.firebase.locations().off();
    // this.props.firebase.companies().off();
    this.props.firebase.rates().off();
    // this.props.firebase.users().off();
  }

  onSubmit = e => {
    e.preventDefault();
    const validForm = this.checkValidForm();
    const { form } = this.state;
    this.submitted = true;

    if (validForm) {
      // start/end should be timestamps; only send ids over; let server determine clash and handle approp
      this.setState({ saving: true }, () => {
        const startTime = moment(`${form.dateToday} ${form.startAt}`);
        const endTime = moment(`${form.dateToday} ${form.endAt}`);

        this.props.firebase.createTripRecordAdmin({
          date: form.dateToday, // just for lookup, start/end times are already contained
          driverId: form.driverId,
          vehicleId: form.vehicleId,
          jobId: form.jobId,
          startLocationId: form.startLocationId,
          endLocationId: form.endLocationId,
          companyId: form.companyId,
          ticket: form.ticket,
          remarks: form.remarks,
          startAt: startTime.valueOf(),
          endAt: endTime.valueOf()
        })
          .then(() => {
            NotificationManager.success('Record created');
            this.props.history.push(ROUTES.RECORDS);
          })
          .catch(err => {
            if(this._isMounted) this.setState({ saving: false });
            NotificationManager.error(err.message);
          });
      });
    } else {
      console.log('Invalid submission');
    }
  }

  updateDistance = () => {
    const { form } = this.state;
    
    if(form.startLocationId && form.endLocationId) {
      this.props.firebase.distance(form.startLocationId).child(`${form.endLocationId}`).once('value', snap => {
        const val = snap.val();
        if(this._isMounted) {
          this.setState({ distance: val.distance });
        }
      });
    } else {
      if (this._isMounted) {
        this.setState({ distance: 0 }); // need to reset in case switching from named location to blank
      }
    }
  }

  updateRecordsList = () => {
    const { form } = this.state;
    var recordsList = [];
    var endSort = (a, b) => { // latest first
      if (a.endAt > b.endAt) return -1;
      if (a.endAt < b.endAt) return 1;
      return 0;
    };

    if(form.driverId && form.dateToday) {
      this.props.firebase.trips(form.driverId).orderByChild('date').equalTo(form.dateToday)
        .once('value')
        .then(snap => {
          snap.forEach(item => {
            recordsList.push({ ...item.val(), recordtype: 'trip' });
          });

          return this.props.firebase.waits(form.driverId).orderByChild('date').equalTo(form.dateToday).once('value');
        })
        .then(snap => {
          snap.forEach(item => {
            recordsList.push({ ...item.val(), recordtype: 'wait' });
          });

          return this.props.firebase.dayjobs(form.driverId).orderByChild('date').equalTo(form.dateToday).once('value');
        })
        .then(snap => {
          snap.forEach(item => {
            recordsList.push({ ...item.val(), recordtype: 'dayjob' });
          });

          return this.props.firebase.workshops(form.driverId).orderByChild('date').equalTo(form.dateToday).once('value');
        })
        .then(snap => {
          snap.forEach(item => {
            recordsList.push({ ...item.val(), recordtype: 'workshop' });
          });

          return this.props.firebase.waytrips(form.driverId).orderByChild('date').equalTo(form.dateToday).once('value');
        })
        .then(snap => {
          snap.forEach(item => {
            recordsList.push({ ...item.val(), recordtype: 'waytrip' });
          });

          recordsList.sort(endSort);
          if (this._isMounted) this.setState({ recordsList });  
        });
      
    }
  }

  onChange = (e, cb) => {
    const form = Object.assign({}, this.state.form);
    form[e.target.name] = e.target.value;
    this.setState({ form }, () => {
      if(cb) cb();
    });
  }

  onResetForm = e => {
    e.preventDefault();
    this.setState({
      ...INITIAL_STATE,
      validation: this.validator.valid()
    });
    this.submitted = false;
  }

  checkValidForm = () => {
    const validation = this.validator.validate(this.state.form);
    this.setState({ validation: validation });
    return validation.isValid;
  }

  uniqueLocation = (end) => { // check start/end not same
    const { form } = this.state;
    return end !== form.startLocationId;
  }

  validTimes = (endAt) => {
    const { form } = this.state;
    return endAt > form.startAt;
  }

  getAmount = (distance) => { // abstract here since we need to use state ratesList, but otherwise not used in the render
    const { ratesList, recordsList, form } = this.state; // ratesList in asc order
    var rate = 0;
    var multiplier = RATES.BASE;

    ratesList.forEach(item => { // find the last item smaller than distance
      if(item.from <= distance) {
        rate = item.amount;
      }
    });
    
    if (form.dateToday && form.startAt) {
      // recordsList.forEach(item => { // find the latest item earlier than this record, and update multiplier if location matches
      //   const startTime = moment(`${form.dateToday} ${form.startAt}`).valueOf();
      //   if (item.endAt <= startTime) {
      //     if(item.endLocationId === form.startLocationId) { // cannot collapse into single conditional, as we want to reset to 1 if the next later record isnt location-matched
      //       multiplier = RATES.CONTINUE;
      //       console.log('As multi');
      //     } else {
      //       multiplier = RATES.BASE;
      //       console.log('As single');
      //     }
      //   }
      // });
      const startTime = moment(`${form.dateToday} ${form.startAt}`).valueOf();
      var recordsBefore = recordsList.filter(item => {
        return item.endAt <= startTime;
      });
      if (recordsBefore.length) {
        if ((recordsBefore[0].recordtype === 'trip') && (recordsBefore[0].endLocationId === form.startLocationId)) {
          multiplier = RATES.CONTINUE;
        } else if (
          (['wait', 'dayjob'].indexOf(recordsBefore[0].recordtype) > -1) &&
          (recordsBefore[0].siteLocationId === form.startLocationId) &&
          (recordsBefore.length > 1) &&
          (recordsBefore[1].recordtype === 'trip') &&
          (recordsBefore[1].endLocationId === form.startLocationId)
        ) {
          multiplier = RATES.CONTINUE;
        }
      }
    }
    
    return rate * multiplier;
  }

  render() {
    const { form, saving, today, distance } = this.state;
    const validation = this.submitted ? this.validator.validate(form) : this.state.validation;
    const amount = distance ? this.getAmount(distance) : 0;
    var duration = 0;
    const t = this.props.t;

    if(form.dateToday && form.startAt && form.endAt) {
      // difference, if neg set to zero
      const startTime = moment(`${form.dateToday} ${form.startAt}`);
      const endTime = moment(`${form.dateToday} ${form.endAt}`);
      const range = moment.range(startTime, endTime);
      if(range.diff() > 0) {
        duration = range.diff('minutes');
      }
    }

    return (
      <React.Fragment>
        <h1>{t('trips.add')}</h1>
        <Link to={ROUTES.RECORDS} className="topnav"><i className="fas fa-arrow-left fa-sm"></i> {t('form.backtorecords')}</Link>
        <form className="customform tripform" onSubmit={this.onSubmit}>
          <DriverSelect
            name="driverId"
            className="noappearance"
            labelText={t('form.driver')}
            value={form.driverId}
            invalid={validation.driverId.isInvalid}
            message={t(validation.driverId.message)}
            onChange={e => this.onChange(e, this.updateRecordsList)}
          />
          <Input
            name="dateToday"
            className=""
            labelText={t('form.date')}
            type="date"
            value={form.dateToday}
            invalid={validation.dateToday.isInvalid}
            message={t(validation.dateToday.message)}
            onChange={e => this.onChange(e, this.updateRecordsList)}
            inputProps={{
              max: moment(today).format('YYYY-MM-DD')
            }}
          />
          {/* <Select
            name="driverId"
            className="noappearance"
            labelText={t('form.driver')}
            value={form.driverId}
            invalid={validation.driverId.isInvalid}
            message={t(validation.driverId.message)}
            onChange={e => this.onChange(e, this.updateRecordsList)}
            options={driversList}
          /> */}
          <VehicleSelect
            name="vehicleId"
            className="noappearance"
            labelText={t('form.vehicle')}
            value={form.vehicleId}
            invalid={validation.vehicleId.isInvalid}
            message={t(validation.vehicleId.message)}
            onChange={this.onChange}
          />
          <JobSelect
            name="jobId"
            className="noappearance"
            labelText={t('form.job')}
            value={form.jobId}
            invalid={validation.jobId.isInvalid}
            message={t(validation.jobId.message)}
            onChange={this.onChange}
          />
          <LocationSelect
            name="startLocationId"
            className="noappearance"
            labelText={t('form.startlocation')}
            value={form.startLocationId}
            invalid={validation.startLocationId.isInvalid}
            message={t(validation.startLocationId.message)}
            onChange={e => this.onChange(e, this.updateDistance)}
            disabledOptions={[form.endLocationId]}
          />
          <LocationSelect
            name="endLocationId"
            className="noappearance"
            labelText={t('form.endlocation')}
            value={form.endLocationId}
            invalid={validation.endLocationId.isInvalid}
            message={t(validation.endLocationId.message)}
            onChange={e => this.onChange(e, this.updateDistance)}
            disabledOptions={[form.startLocationId]}
          />
          <p className="note">
            <i className="fas fa-route fa-sm"></i> {t('form.distance')}: {distance}km
          </p>
          <CompanySelect
            name="companyId"
            className="noappearance"
            labelText={t('form.company')}
            value={form.companyId}
            invalid={validation.companyId.isInvalid}
            message={t(validation.companyId.message)}
            onChange={this.onChange}
          />
          <Input
            name="ticket"
            className=""
            labelText={t('form.ticket')}
            type="text"
            value={form.ticket}
            onChange={this.onChange}
          />
          <Input
            name="remarks"
            className=""
            labelText={t('form.remarks')}
            type="text"
            value={form.remarks}
            onChange={this.onChange}
          />
          <Input
            name="startAt"
            className=""
            labelText={t('form.startat')}
            type="time"
            value={form.startAt}
            invalid={validation.startAt.isInvalid}
            message={t(validation.startAt.message)}
            onChange={this.onChange}
          />
          <Input
            name="endAt"
            className=""
            labelText={t('form.endat')}
            type="time"
            value={form.endAt}
            invalid={validation.endAt.isInvalid}
            message={t(validation.endAt.message)}
            onChange={this.onChange}
            inputProps={{
              min: form.startAt || ''
            }}
          />
          <p className="note"><i className="far fa-clock fa-sm"></i> {t('form.totalhours')}: {(duration >= 60) && (parseInt(duration / 60) + ' hours')} {(duration % 60 > 0) && ((duration % 60) + ' minutes')}</p>
          <div className="totalpay">{t('form.amount')}: ${toDollar(amount)}</div>
          <center>
            <button type="submit" className="positive" disabled={saving}><i className="fas fa-save fa-md"></i> {saving ? t('form.saving') : t('form.createtrip')}</button>{' '}
            <button onClick={this.onResetForm} disabled={saving}><i className="fas fa-times fa-md"></i> {t('form.reset')}</button>
          </center>
        </form>
      </React.Fragment>
    );
  }
}

const condition = authUser => !!authUser && (authUser.claims.role === ROLES.ADMIN || authUser.claims.role === ROLES.SUPERVISOR);
export default withTranslate(withAuthorization(condition)(withFirebase(withFade(RecordsAddAdmin))));