import React from 'react';
import { LanguageConsumer } from '../Language';

const LanguageToggle = props => (
  <LanguageConsumer>
    {({ language, updateLanguage }) => (
      <div className="lang-section">
        <button className={language === 'english' ? 'btn active' : 'btn'} onClick={updateLanguage('english')}>English</button>
        <button className={language === 'tamil' ? 'btn active' : 'btn'} onClick={updateLanguage('tamil')}>தமிழ்</button>
      </div>
    )}
  </LanguageConsumer>
);

export default LanguageToggle;
