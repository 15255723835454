import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import { withTranslate } from '../Language';
import LanguageToggle from './LanguageToggle';
import SignOutButton from '../SignOut';
import { AuthUserContext } from '../Session';

import Logo from '../../img/system-logo.png';
// import Wifi from '../../img/wifi.svg';
// import WifiOff from '../../img/wifi-off.svg';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { withFirebase } from '../Firebase';
import basePack from '../../../package.json';

const NavAdmin = ({ t, offOverlay }) => (
  <ul className="menu">
    <li><NavLink activeClassName="active" to={ROUTES.DASHBOARD} onClick={offOverlay}>
      <i className="fas fa-th-large fa-lg"></i> {t('dashboard.title')}
    </NavLink></li>
    <li><NavLink activeClassName="active" to={ROUTES.RECORDS} onClick={offOverlay}
      isActive={(match, location) => {
        return location.pathname.startsWith(ROUTES.RECORDS) 
          || location.pathname.startsWith(ROUTES.WAITING_RECORDS) 
          || location.pathname.startsWith(ROUTES.WORKSHOP_RECORDS) 
          || location.pathname.startsWith(ROUTES.WAY_RECORDS) 
          || location.pathname.startsWith(ROUTES.OTHER_RECORDS);
      }}
    >
      <i className="fas fa-file-alt fa-lg"></i> {t('records.driver')}
    </NavLink></li>
    <li><NavLink activeClassName="active" to={ROUTES.USERS} onClick={offOverlay}>
      <i className="fas fa-user-friends fa-lg"></i> {t('users.title')}
    </NavLink></li>
    <li><NavLink activeClassName="active" to={ROUTES.VEHICLES} onClick={offOverlay}>
      <i className="fas fa-truck fa-lg"></i> {t('vehicles.numbers')}
    </NavLink></li>
    <li><NavLink activeClassName="active" to={ROUTES.RATES} onClick={offOverlay}
      isActive={(match, location) => {
        return location.pathname.startsWith(ROUTES.RATES) || location.pathname.startsWith(ROUTES.WAY_RATES);
      }}
    >
      <i className="fas fa-dollar-sign fa-lg"></i> {t('rates')}
    </NavLink></li>
    <li><NavLink activeClassName="active" to={ROUTES.LOCATIONS} onClick={offOverlay}>
      <i className="fas fa-map-marker-alt fa-lg"></i> {t('locations.title')}
    </NavLink></li>
    <li><NavLink activeClassName="active" to={ROUTES.COMPANIES} onClick={offOverlay}>
      <i className="fas fa-building fa-lg"></i> {t('companies.title')}
    </NavLink></li>
    <li><NavLink activeClassName="active" to={ROUTES.JOBS} onClick={offOverlay}>
      <i className="fas fa-truck-loading fa-lg"></i> {t('jobs.title')}
    </NavLink></li>
  </ul>
);

const NavSupervisor = ({ t, offOverlay }) => (
  <ul className="menu">
    <li><NavLink activeClassName="active" to={ROUTES.DASHBOARD} onClick={offOverlay}>
      <i className="fas fa-th-large fa-lg"></i> {t('dashboard.title')}
    </NavLink></li>
    <li><NavLink activeClassName="active" to={ROUTES.RECORDS} onClick={offOverlay}
      isActive={(match, location) => {
        return location.pathname.startsWith(ROUTES.RECORDS) || location.pathname.startsWith(ROUTES.WAITING_RECORDS) || location.pathname.startsWith(ROUTES.OTHER_RECORDS);
      }}
    >
      <i className="fas fa-file-alt fa-lg"></i> {t('records.driver')}
    </NavLink></li>
    <li><NavLink activeClassName="active" to={ROUTES.VEHICLES} onClick={offOverlay}>
      <i className="fas fa-truck fa-lg"></i> {t('vehicles.numbers')}
    </NavLink></li>
    <li><NavLink activeClassName="active" to={ROUTES.RATES} onClick={offOverlay}>
      <i className="fas fa-dollar-sign fa-lg"></i> {t('rates')}
    </NavLink></li>
    <li><NavLink activeClassName="active" to={ROUTES.LOCATIONS} onClick={offOverlay}>
      <i className="fas fa-map-marker-alt fa-lg"></i> {t('locations.title')}
    </NavLink></li>
    <li><NavLink activeClassName="active" to={ROUTES.COMPANIES} onClick={offOverlay}>
      <i className="fas fa-building fa-lg"></i> {t('companies.title')}
    </NavLink></li>
    <li><NavLink activeClassName="active" to={ROUTES.JOBS} onClick={offOverlay}>
      <i className="fas fa-truck-loading fa-lg"></i> {t('jobs.title')}
    </NavLink></li>
  </ul>
);

const NavDriver = ({ t, offOverlay }) => (
  <ul className="menu">
    <li><NavLink activeClassName="active" to={ROUTES.DASHBOARD} onClick={offOverlay}>
      <i className="fas fa-th-large fa-lg"></i> {t('dashboard.title')}
    </NavLink></li>
    <li><NavLink activeClassName="active" to={ROUTES.RECORDS} onClick={offOverlay}
      isActive={(match, location) => {
        return location.pathname.startsWith(ROUTES.RECORDS) || location.pathname.startsWith(ROUTES.WAITING_RECORDS) || location.pathname.startsWith(ROUTES.OTHER_RECORDS);
      }}
    >
      <i className="fas fa-file-alt fa-lg"></i> {t('records.my')}
    </NavLink></li>
    <li><NavLink activeClassName="active" to={ROUTES.VEHICLES} onClick={offOverlay}>
      <i className="fas fa-truck fa-lg"></i> {t('vehicles.numbers')}
    </NavLink></li>
    <li><NavLink activeClassName="active" to={ROUTES.RATES} onClick={offOverlay}>
      <i className="fas fa-dollar-sign fa-lg"></i> {t('rates')}
    </NavLink></li>
    <li><NavLink activeClassName="active" to={ROUTES.LOCATIONS} onClick={offOverlay}>
      <i className="fas fa-map-marker-alt fa-lg"></i> {t('locations.title')}
    </NavLink></li>
    <li><NavLink activeClassName="active" to={ROUTES.COMPANIES} onClick={offOverlay}>
      <i className="fas fa-building fa-lg"></i> {t('companies.title')}
    </NavLink></li>
    <li><NavLink activeClassName="active" to={ROUTES.JOBS} onClick={offOverlay}>
      <i className="fas fa-truck-loading fa-lg"></i> {t('jobs.title')}
    </NavLink></li>
  </ul>
);

const Nav = ({ authUser, t, offOverlay }) => {
  if(authUser.claims.role === ROLES.ADMIN) {
    return <NavAdmin t={t} offOverlay={offOverlay} />
  } else if (authUser.claims.role === ROLES.SUPERVISOR) {
    return <NavSupervisor t={t} offOverlay={offOverlay} />
  } else if (authUser.claims.role === ROLES.DRIVER) {
    return <NavDriver t={t} offOverlay={offOverlay} />
  }
  return null;
}

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showOverlay: false,
      // connected: true,
    }
  }

  // componentDidMount() {
  //   this.connectedRef = this.props.firebase.online();
  //   this.connectedRef.on('value', snap => {
  //     console.log('update');
  //     if(snap.val() === true) {
  //       console.log('on');
  //       this.setState({ connected: true });
  //     } else {
  //       console.log('off');
  //       this.setState({ connected: false });
  //     }
  //   });
  // }

  // componentWillUnmount() {
  //   if(this.connectedRef) this.connectedRef.off();
  // }

  // use currying (instead of a single func with multi param, to a sequence of func with single param) to handle param in addition to event
  toggleOverlay = (on, preventDefault) => (e) => { 
    if(preventDefault) e.preventDefault();
    this.setState({ showOverlay: !!on });
  }

  // instead of wrapping everything in the consumer we can add withautho, but we are not redirecting this comp, so consumer will do 
  render() {
    // const { connected } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => 
          authUser
          ? <React.Fragment>
              <div className={this.state.showOverlay ? 'overlay show' : 'overlay'} onClick={this.toggleOverlay(false, true)}></div>
              <div className="top-bar">
                <div className="wrapper">
                  <a className="navicon" href="/#" onClick={this.toggleOverlay(true, true)}><i className="fas fa-bars fa-lg"></i></a>
                  <Link to={ROUTES.LANDING} className="logo" onClick={this.toggleOverlay(false)}><img src={Logo} alt="logo" width="150" /></Link>
                </div>
              </div>
              <div className={this.state.showOverlay ? 'sidebar open' : 'sidebar'} >
                <a className="close-menu" href="/#" onClick={this.toggleOverlay(false, true)}><i className="far fa-times-circle fa-fw"></i></a>
                <Link to={ROUTES.LANDING} className="logo" onClick={this.toggleOverlay(false)}><img src={Logo} alt="logo" width="252" /></Link>
                <div className="sidebar-scrolling">
                  <div className="profile-section">
                    <SignOutButton className="signout" />
                    <Link to={ROUTES.ACCOUNT} onClick={this.toggleOverlay(false)}><i className="fas fa-user-circle fa-lg"></i> {authUser.name}</Link>
                  </div>
                  <Nav authUser={authUser} t={this.props.t} offOverlay={this.toggleOverlay(false)} />
                  <LanguageToggle />
                  <span className="versionNo">v{basePack.version}</span>
                  {/* <img src={ connected ? Wifi : WifiOff } alt="Online" width="20" className="wifi" /> */}
                </div>
              </div>
            </React.Fragment>
          : null
        }
      </AuthUserContext.Consumer>
    );
  }
}
  
export default withFirebase(withTranslate(Navbar));
