import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { withAuthorization } from '../Session';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import UsersList from './UsersList';
import UsersAdd from './UsersAdd';
import UsersEdit from './UsersEdit';
import UsersPassword from './UsersPassword';

const Users = () => (
  <div className="main">
    <div className="main-wrapper">
      <div className="top">
        <Switch>
          <Route exact path={ROUTES.USER_ADD} component={UsersAdd} />
          <Route exact path={ROUTES.USER_PASSWORD} component={UsersPassword} />
          <Route exact path={ROUTES.USER_DETAILS} component={UsersEdit} />
          <Route exact path={ROUTES.USERS} component={UsersList} />
        </Switch>
      </div>
    </div>
  </div>
);

const condition = authUser => !!authUser && authUser.claims.role === ROLES.ADMIN;
export default withAuthorization(condition)(Users);
