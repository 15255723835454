import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { withAuthorization } from '../Session';
import * as ROUTES from '../../constants/routes';
import RatesList from './RatesList';
import RatesAdd from './RatesAdd';
import RatesEdit from './RatesEdit';
import RatesDelete from './RatesDelete';
import WaitingRates from './WaitingRates';
import DayRates from './DayRates';
import WayRatesList from './WayRatesList';
import WorkshopRates from './WorkshopRates';

const Rates = () => (
  <div className="main">
    <Switch>
      <Route exact path={ROUTES.WORKSHOP_RATES} component={WorkshopRates} />
      <Route exact path={ROUTES.WAITING_RATES} component={WaitingRates} />
      <Route exact path={ROUTES.DAY_RATES} component={DayRates} />
      <Route exact path={ROUTES.RATES_ADD} component={RatesAdd} />
      <Route exact path={ROUTES.RATES_DELETE} component={RatesDelete} />
      <Route exact path={ROUTES.RATES_EDIT} component={RatesEdit} />
      <Route exact path={ROUTES.RATES} component={RatesList} />
      <Route exact path={ROUTES.WAY_RATES} component={WayRatesList} />
    </Switch>
  </div>
);

const condition = authUser => !!authUser; 
export default withAuthorization(condition)(Rates);
