import validator from 'validator';

/*
usage:
this.validator = new ReservationValidator([
  {
    field: 'eventName',
    method: 'isEmpty', // string methods if using default validator package
    validWhen: false,
    message: 'Event Name is required.'
  },
  {
    field: 'mediaReleaseDate',
    method: this.validOrEmptyDate, // function reference if using custom validation logic
    validWhen: true,
    message: 'Media Release Date is invalid.'
  },
  {
    field: 'age',
    method: 'isInt',
    args: [{min: 21, max: 65}],  // an array of additional arguments
    validWhen: true,
    message: 'Your age must be an integer between 21 and 65'
  }
]);
*/
class FormValidator {
  constructor(validations) { // array of rules for a form
    this.validations = validations;
  }

  validate(state) {
    let validation = this.valid(); // singular is for result; plural is for rules

    this.validations.forEach(rule => {
      if (!validation[rule.field].isInvalid) {
        const field_value = (state[rule.field] === null) ? '' : state[rule.field].toString();
        const args = rule.args || [];
        // whether is a named rule which we import from validator package
        const validation_method = typeof rule.method === 'string' ? validator[rule.method] : rule.method;

        if (validation_method(field_value, ...args, state) !== rule.validWhen) {
          validation[rule.field] = {
            isInvalid: true,
            message: rule.message
          }
          validation.isValid = false;
        }
      }
    });

    return validation;
  }

  // default validation object creation
  valid() {
    const validation = {};

    this.validations.map(rule => (
      validation[rule.field] = { isInvalid: false, message: '' }
    ));

    // default form & fields valid
    return { isValid: true, ...validation };
  }
}

export default FormValidator;