import React from 'react';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import { withTranslate } from '../Language';
import withFade from '../UI/withFade';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import Input from '../Form/Input';
import '../Form/CustomForm.css';

const INITIAL_STATE = {
  deleting: false,
  location: null,
}

class LocationsDelete extends React.Component {
  constructor(props) {
    super(props);

    this.state = { 
      ...INITIAL_STATE, 
      ...props.location.state,
    };

  }

  componentDidMount() {
    // check we arrived from details (this.state.location; else we return back)
    if (!this.state.location) {
      this.props.history.push(`${ROUTES.LOCATIONS}/points/${this.props.match.params.id}`);
    }
  }

  onSubmit = e => {
    e.preventDefault();

    this.setState({ deleting: true }, () => {
      this.props.firebase.db.ref(`/locations/${this.state.location.lid}`).remove()
        .then(() => {
          NotificationManager.success('Location removed');
          this.props.history.push(ROUTES.LOCATIONS);
        })
        .catch(err => {
          NotificationManager.error(err.message);
        })
    })
    
  }

  render() {
    const { location, deleting } = this.state;
    const id = this.props.match.params.id;
    const t = this.props.t;

    return (
      <React.Fragment>
        <h1>{t('locations.delete')}</h1>
        <Link to={`${ROUTES.LOCATIONS}/points/${id}`} className="topnav"><i className="fas fa-arrow-left fa-sm"></i> Back to &quot;{location.name}&quot;</Link>
        <form className="customform locationform" onSubmit={this.onSubmit}>
          <p>Confirm delete this location?</p>
          <button type="submit" disabled={deleting} className="warn" style={{marginBottom:'20px'}}><i className="fas fa-trash-alt fa-md"></i> {deleting ? t('form.deleting') : 'Delete Location'}</button>{' '}
          <Input
            name="name"
            className=""
            labelText={t('name')}
            type="text"
            value={location.name}
            disabled={true}
          />
          <Input
            name="address"
            className=""
            labelText={t('address')}
            type="text"
            value={location.address}
            disabled={true}
          />
        </form>
      </React.Fragment>
    );
  }
}

const condition = authUser => !!authUser && authUser.claims.role === ROLES.ADMIN;
export default withTranslate(withAuthorization(condition)(withFirebase(withFade(LocationsDelete))));