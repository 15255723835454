import React from 'react';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import { withTranslate } from '../Language';
import withFade from '../UI/withFade';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import Input from '../Form/Input';
import '../Form/CustomForm.css';

const INITIAL_STATE = {
  deleting: false,
  rate: null,
}

class RatesDelete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
      ...props.location.state,
    };

  }

  componentDidMount() {
    if (!this.state.rate) {
      this.props.history.push(`${ROUTES.RATES}/${this.props.match.params.id}`);
    }
  }

  onSubmit = e => {
    e.preventDefault();

    this.setState({ deleting: true }, () => {
      this.props.firebase.rate(this.state.rate.rid).remove()
        .then(() => {
          NotificationManager.success('Rate removed');
          this.props.history.push(ROUTES.RATES);
        })
        .catch(err => {
          NotificationManager.error(err.message);
        })
    })
  }

  render() {
    const { rate, deleting } = this.state;
    const id = this.props.match.params.id;
    const t = this.props.t;

    return (
      <div className="main-wrapper">
        <h1>{t('distancerates.delete')}</h1>
        <Link to={`${ROUTES.RATES}/${id}`} className="topnav"><i className="fas fa-arrow-left fa-sm"></i> Back to Distance Rate</Link>
        <form className="customform rateform" onSubmit={this.onSubmit}>
          <Input
            name="from"
            className=""
            labelText="From Distance (km)"
            type="text"
            value={rate.from}
            disabled={true}
          />
          <Input
            name="amount"
            className=""
            labelText="Amount ($)"
            type="text"
            value={rate.amount}
            disabled={true}
          />
          <center>
            <p>Confirm delete this rate?</p>
            <button type="submit" disabled={deleting} className="warn" style={{ marginBottom: '20px' }}><i className="fas fa-trash-alt fa-md"></i> {deleting ? t('form.deleting') : 'Delete Rate'}</button>{' '}
          </center>
        </form>
      </div>
    );
  }
}

const condition = authUser => !!authUser && authUser.claims.role === ROLES.ADMIN;
export default withTranslate(withAuthorization(condition)(withFirebase(withFade(RatesDelete))));