import React from 'react';
import Fade from 'react-reveal/Fade';

// HOC providing fade effect
const withFade = (WrappedComponent, direction) => {
  class HOC extends React.Component {
    constructor(props) {
      super(props);
      this.directions = {
        left: false,
        right: false,
        top: false,
        bottom: false,
      };
      if (direction) {
        this.directions[direction] = true;
      }
    }
    render() {
      const d = this.directions;
      return (
        <Fade left={d.left} right={d.right} top={d.top} bottom={d.bottom}>
          <WrappedComponent
            {...this.props}
          />
        </Fade> 
      );
    }
  }

  return HOC;
};

export default withFade;