import React from 'react';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import { withTranslate } from '../Language';
import withFade from '../UI/withFade';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import * as RATES from '../../constants/rates';
import Input from '../Form/Input';
import CompanySelect from '../Form/CompanySelect';
import DriverSelect from '../Form/DriverSelect';
import JobSelect from '../Form/JobSelect';
import LocationSelect from '../Form/LocationSelect';
import VehicleSelect from '../Form/VehicleSelect';
import FormValidator from '../Form/FormValidator';
import { toDollar } from '../../constants/math';

import '../Form/CustomForm.css';

const moment = extendMoment(Moment);

const INITIAL_STATE = {
  loading: false,
  saving: false,
  trip: null,
  form: null,
  // editMode: false,
  today: null,
  distance: 0, // used for distance calc
  ratesList: [], // used for amount display (re-calc on server insert)
  recordsList: [],
}

class RecordsEdit extends React.Component {
  constructor(props) {
    super(props);

    const today = new Date();

    this.validator = new FormValidator([
      {
        field: 'dateToday',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.daterequired'
      },
      {
        field: 'dateToday',
        method: 'isBefore',
        args: [moment(today).add(1, 'days').startOf('day').toString()],
        validWhen: true,
        message: 'form.dateinvalid'
      },
      {
        field: 'driverId',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.driverrequired'
      },
      {
        field: 'vehicleId',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.vehiclerequired'
      },
      {
        field: 'jobId',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.jobrequired'
      },
      {
        field: 'startLocationId',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.startlocationrequired'
      },
      {
        field: 'endLocationId',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.endlocationrequired'
      },
      {
        field: 'endLocationId',
        method: this.uniqueLocation,
        validWhen: true,
        message: 'form.endlocationinvalid'
      },
      {
        field: 'companyId',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.companyrequired'
      },
      {
        field: 'startAt',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.startatrequired'
      },
      {
        field: 'endAt',
        method: 'isEmpty',
        validWhen: false,
        message: 'form.endatrequired'
      },
      {
        field: 'endAt',
        method: this.validTimes,
        validWhen: true,
        message: 'form.endatinvalid'
      }
    ]);

    this.state = {
      ...INITIAL_STATE,
      validation: this.validator.valid(),
      today
    };

    this.submitted = false;
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({ loading: true });
    
    var fromSort = (a, b) => {
      if (a.from < b.from) return -1;
      if (a.from > b.from) return 1;
      return 0;
    };

    // load all the reference fields
    this.props.firebase.rates().on('value', snap => {
      const ratesList = [];
      snap.forEach(item => {
        const val = item.val();
        ratesList.push({
          ...val,
          key: item.key
        });
      });
      this.setState({ ratesList: ratesList.sort(fromSort) });
    });

    // load the record itself:
    this.props.firebase.trip(this.props.match.params.uid, this.props.match.params.id)
      .once('value', snapshot => {
        const tripObj = snapshot.val();
        if (!tripObj) {
          // this.setState({ ...INITIAL_STATE });
          return this.props.history.push(ROUTES.RECORDS);
        }

        const trip = {
          dateToday: tripObj.date,
          driverId: tripObj.driverId,
          vehicleId: tripObj.vehicleId,
          jobId: tripObj.jobId,
          startLocationId: tripObj.startLocationId,
          endLocationId: tripObj.endLocationId,
          companyId: tripObj.companyId,
          ticket: tripObj.ticket,
          remarks: tripObj.remarks,
          startAt: moment(tripObj.startAt).format('kk:mm'),
          endAt: moment(tripObj.endAt).format('kk:mm'),
          // additional info snapped at point of save:
          amount: tripObj.amount, // in cents, in db value for presentation
          distance: tripObj.distance,
          tid: snapshot.key
        };

        if(this._isMounted) {
          this.setState({
            trip,
            loading: false,
            form: {
              ...trip
            },
            distance: tripObj.distance
          }, () => {
            this.updateRecordsList(); // initial list based on date/driver
            this.updateDistance(); // re-compute distance in event of location-distance changes
          });
        }
        
      });

  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.firebase.rates().off();
  }

  onSubmit = e => {
    e.preventDefault();
    const validForm = this.checkValidForm();
    const { trip, form } = this.state;
    this.submitted = true;

    if (validForm) {
      this.setState({ saving: true }, () => {
        const startTime = moment(`${form.dateToday} ${form.startAt}`);
        const endTime = moment(`${form.dateToday} ${form.endAt}`);

        this.props.firebase.updateTripRecordAdmin(trip.tid, trip.driverId, {
          date: form.dateToday, // just for lookup, start/end times are already contained
          driverId: trip.driverId, // form.driverId, not editable
          vehicleId: form.vehicleId,
          jobId: form.jobId,
          startLocationId: form.startLocationId,
          endLocationId: form.endLocationId,
          companyId: form.companyId,
          ticket: form.ticket,
          remarks: form.remarks,
          startAt: startTime.valueOf(),
          endAt: endTime.valueOf()
        })
          .then(() => {
            if(this._isMounted) {
              this.setState(prevstate => ({
                // editMode: false,
                trip: {
                  ...prevstate.form,
                  distance: prevstate.distance,
                  amount: this.getAmount(prevstate.distance)
                },
                form: {
                  ...prevstate.form,
                  distance: prevstate.distance,
                  amount: this.getAmount(prevstate.distance)
                },
                saving: false
              }));
            }
            NotificationManager.success('Record updated');
          })
          .catch(err => {
            if(this._isMounted) {
              this.setState({ saving: false });
            }
            NotificationManager.error(err.message);
          });
        
      });
    } else {
      console.log('Invalid submission');
    }
  }

  updateDistance = () => {
    const { form } = this.state;

    if (form.startLocationId && form.endLocationId) {
      this.props.firebase.distance(form.startLocationId).child(`${form.endLocationId}`).once('value', snap => {
        const val = snap.val();
        if (this._isMounted) {
          this.setState({ distance: val.distance });
        }
      });
    } else {
      if (this._isMounted) {
        this.setState({ distance: 0 }); // need to reset in case switching from named location to blank
      }
    }
  }

  updateRecordsList = () => {
    const { form } = this.state;
    var recordsList = [];
    var endSort = (a, b) => { // latest first
      if (a.endAt > b.endAt) return -1;
      if (a.endAt < b.endAt) return 1;
      return 0;
    };

    if (form && form.driverId && form.dateToday) {
      this.props.firebase.trips(form.driverId).orderByChild('date').equalTo(form.dateToday)
        .once('value')
        .then(snap => {
          snap.forEach(item => {
            recordsList.push({ ...item.val(), recordtype: 'trip' });
          });

          return this.props.firebase.waits(form.driverId).orderByChild('date').equalTo(form.dateToday).once('value');
        })
        .then(snap => {
          snap.forEach(item => {
            recordsList.push({ ...item.val(), recordtype: 'wait' });
          });

          return this.props.firebase.dayjobs(form.driverId).orderByChild('date').equalTo(form.dateToday).once('value');
        })
        .then(snap => {
          snap.forEach(item => {
            recordsList.push({ ...item.val(), recordtype: 'dayjob' });
          });

          return this.props.firebase.workshops(form.driverId).orderByChild('date').equalTo(form.dateToday).once('value');
        })
        .then(snap => {
          snap.forEach(item => {
            recordsList.push({ ...item.val(), recordtype: 'workshop' });
          });

          return this.props.firebase.waytrips(form.driverId).orderByChild('date').equalTo(form.dateToday).once('value');
        })
        .then(snap => {
          snap.forEach(item => {
            recordsList.push({ ...item.val(), recordtype: 'waytrip' });
          });

          recordsList.sort(endSort);
          if (this._isMounted) this.setState({ recordsList });
        });
      
    }
  }

  onChange = (e, cb) => {
    const form = Object.assign({}, this.state.form);
    form[e.target.name] = e.target.value;
    this.setState({ form }, () => {
      if (cb) cb();
    });
  }

  onToggleEdit = e => {
    e.preventDefault();
    this.setState(prevState => ({
      // editMode: !prevState.editMode,
      form: {
        ...prevState.trip,
      }
    }), () => {
      this.updateDistance(); // re-compute
    });
  }

  checkValidForm = () => {
    const validation = this.validator.validate(this.state.form);
    this.setState({ validation: validation });
    return validation.isValid;
  }

  uniqueLocation = (end) => { // check start/end not same
    const { form } = this.state;
    return end !== form.startLocationId;
  }

  validTimes = (endAt) => {
    const { form } = this.state;
    return endAt > form.startAt;
  }

  getAmount = (distance) => { // abstract here since we need to use state ratesList, but otherwise not used in the render
    const { ratesList, recordsList, form } = this.state; // ratesList in asc order
    var rate = 0;
    var multiplier = RATES.BASE;

    ratesList.forEach(item => { // find the last item smaller than distance
      if (item.from <= distance) {
        rate = item.amount;
      }
    });

    if (form.dateToday && form.startAt) {
      // recordsList.forEach(item => { // find the latest item earlier than this record, and update multiplier if location matches
      //   const startTime = moment(`${form.dateToday} ${form.startAt}`).valueOf();
      //   if (item.endAt <= startTime) {
      //     if (item.endLocationId === form.startLocationId) { // cannot collapse into single conditional, as we want to reset to 1 if the next later record isnt location-matched
      //       multiplier = RATES.CONTINUE;
      //     } else {
      //       multiplier = RATES.BASE;
      //     }
      //   }
      // });
      const startTime = moment(`${form.dateToday} ${form.startAt}`).valueOf();
      var recordsBefore = recordsList.filter(item => {
        return item.endAt <= startTime;
      });
      if (recordsBefore.length) {
        if ((recordsBefore[0].recordtype === 'trip') && (recordsBefore[0].endLocationId === form.startLocationId)) {
          multiplier = RATES.CONTINUE;
        } else if (
          (['wait', 'dayjob'].indexOf(recordsBefore[0].recordtype) > -1) &&
          (recordsBefore[0].siteLocationId === form.startLocationId) &&
          (recordsBefore.length > 1) &&
          (recordsBefore[1].recordtype === 'trip') &&
          (recordsBefore[1].endLocationId === form.startLocationId)
        ) {
          multiplier = RATES.CONTINUE;
        }
      }
    }

    return rate * multiplier;
  }

  render() {
    const { form, trip, today, distance, saving, loading } = this.state;
    const validation = this.submitted ? this.validator.validate(form) : this.state.validation;
    const amount = distance ? this.getAmount(distance) : 0;
    var duration = 0;
    const t = this.props.t;

    if (form && form.dateToday && form.startAt && form.endAt) {
      // difference, if neg set to zero
      const startTime = moment(`${form.dateToday} ${form.startAt}`);
      const endTime = moment(`${form.dateToday} ${form.endAt}`);
      const range = moment.range(startTime, endTime);
      if (range.diff() > 0) {
        duration = range.diff('minutes');
      }
    }

    return (
      <React.Fragment>
        <h1>{t('trips.edit')}</h1>
        {loading
          ? <p>Loading ...</p>
          : trip && form && (
            <form className="customform tripform" onSubmit={this.onSubmit}>
              <Link to={ROUTES.RECORDS_VIEW.replace(':uid', trip.driverId).replace(':id', trip.tid)} className="topnav"><i className="fas fa-arrow-left fa-sm"></i> {t('form.backtorecord')}</Link>
              <DriverSelect
                name="driverId"
                className="noappearance"
                labelText={t('form.driver')}
                value={form.driverId}
                invalid={validation.driverId.isInvalid}
                message={t(validation.driverId.message)}
                onChange={e => this.onChange(e, this.updateRecordsList)}
                disabled={true}
              />
              <Input
                name="dateToday"
                className=""
                labelText={t('form.date')}
                type="date"
                value={form.dateToday}
                invalid={validation.dateToday.isInvalid}
                message={t(validation.dateToday.message)}
                onChange={e => this.onChange(e, this.updateRecordsList)}
                inputProps={{
                  max: moment(today).format('YYYY-MM-DD')
                }}
              />
              <VehicleSelect
                name="vehicleId"
                className="noappearance"
                labelText={t('form.vehicle')}
                value={form.vehicleId}
                invalid={validation.vehicleId.isInvalid}
                message={t(validation.vehicleId.message)}
                onChange={this.onChange}
              />
              <JobSelect
                name="jobId"
                className="noappearance"
                labelText={t('form.job')}
                value={form.jobId}
                invalid={validation.jobId.isInvalid}
                message={t(validation.jobId.message)}
                onChange={this.onChange}
              />
              <LocationSelect
                name="startLocationId"
                className="noappearance"
                labelText={t('form.startlocation')}
                value={form.startLocationId}
                invalid={validation.startLocationId.isInvalid}
                message={t(validation.startLocationId.message)}
                onChange={e => this.onChange(e, this.updateDistance)}
                disabledOptions={[form.endLocationId]}
              />
              <LocationSelect
                name="endLocationId"
                className="noappearance"
                labelText={t('form.endlocation')}
                value={form.endLocationId}
                invalid={validation.endLocationId.isInvalid}
                message={t(validation.endLocationId.message)}
                onChange={e => this.onChange(e, this.updateDistance)}
                disabledOptions={[form.startLocationId]}
              />
              <p className="note">
                <i className="fas fa-route fa-sm"></i> {t('form.distance')}:{' '}
                {trip.distance !== distance &&
                  <React.Fragment>
                    <span className="distanceChanged">{trip.distance}km</span>{' '}
                  </React.Fragment>
                }
                {distance}km
              </p>
              <CompanySelect
                name="companyId"
                className="noappearance"
                labelText={t('form.company')}
                value={form.companyId}
                invalid={validation.companyId.isInvalid}
                message={t(validation.companyId.message)}
                onChange={this.onChange}
              />
              <Input
                name="ticket"
                className=""
                labelText={t('form.ticket')}
                type="text"
                value={form.ticket}
                onChange={this.onChange}
              />
              <Input
                name="remarks"
                className=""
                labelText={t('form.remarks')}
                type="text"
                value={form.remarks}
                onChange={this.onChange}
              />
              <Input
                name="startAt"
                className=""
                labelText={t('form.startat')}
                type="time"
                value={form.startAt}
                invalid={validation.startAt.isInvalid}
                message={t(validation.startAt.message)}
                onChange={this.onChange}
              />
              <Input
                name="endAt"
                className=""
                labelText={t('form.endat')}
                type="time"
                value={form.endAt}
                invalid={validation.endAt.isInvalid}
                message={t(validation.endAt.message)}
                onChange={this.onChange}
                inputProps={{
                  min: form.startAt || ''
                }}
              />
              <p className="note"><i className="far fa-clock fa-sm"></i> {t('form.totalhours')}: {(duration >= 60) && (parseInt(duration / 60) + ' hours')} {(duration % 60 > 0) && ((duration % 60) + ' minutes')}</p>
              <div className="totalpay">{t('form.amount')}:{' '}
                {trip.amount !== amount &&
                  <React.Fragment>
                    <span className="distanceChanged">${toDollar(trip.amount)}</span>{' '}
                  </React.Fragment>
                }
                ${toDollar(amount)}
              </div>
              <center>
                <button type="submit" className="positive" disabled={saving}><i className="fas fa-save fa-md"></i> {saving ? t('form.saving') : t('form.savechanges')}</button>{' '}
                <button onClick={this.onToggleEdit} disabled={saving}><i className="fas fa-times fa-md"></i> {t('form.cancelchanges')}</button>{' '}
                <Link to={{
                  pathname: ROUTES.RECORDS_DELETE.replace(':uid', trip.driverId).replace(':id', trip.tid),
                  state: { trip }
                }} className="button warn"><i className="fas fa-trash-alt fa-md"></i> {t('form.deletetrip')}</Link>
              </center>
            </form>
          )
        }
      </React.Fragment>
    );
  }
}

const condition = authUser => !!authUser && authUser.claims.role === ROLES.ADMIN;
export default withTranslate(withAuthorization(condition)(withFirebase(withFade(RecordsEdit))));