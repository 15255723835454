import React from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import moment from 'moment';

import withFade from '../UI/withFade';
import { withTranslate } from '../Language';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { toDollar } from '../../constants/math';
import { AuthUserContext } from '../Session';
import Ratebar from '../UI/Ratebar';

const RatesTable = ({ rates, role, history }) => {
  const columns = [{
    id: 'startFrom',
    Header: 'Starting From',
    accessor: rate => `${rate.from} km`,
    sortMethod: (a, b, desc) => (a - b) // default array.sort casts to strings, so floats wun sort correctly
  }, {
    id: 'rate',
    Header: 'Rate',
    accessor: rate => `$${toDollar(rate.amount)}`
  }, {
    id: 'created',
    Header: 'Created on',
    accessor: row => row.createdAt ? moment(row.createdAt).calendar() : '--',
  }, {
    id: 'updated',
    Header: 'Updated on',
    accessor: row => row.updatedAt ? moment(row.updatedAt).calendar() : '--',
  }, {
    id: 'action',
    Header: 'Action',
    accessor: rate => {
      if(role !== ROLES.ADMIN) {
        return '--';
      }
      return <Link to={{
        pathname: `${ROUTES.RATES}/${rate.rid}`,
        state: { rate }
      }}><i className="far fa-edit fa-lg"></i></Link>
    },
    maxWidth: 80,
  }];

  return (
    <ReactTable className="-highlight" data={rates} columns={columns}
      defaultPageSize={20}
      defaultSorted={[{ id: 'startFrom', desc: false }]}
      getTdProps={(state, rowInfo, column, instance) => {
        return {
          onClick: (e, handleOriginal) => {
            if (role === ROLES.ADMIN) history.push(`${ROUTES.RATES}/${rowInfo.original.rid}`);
            if (handleOriginal) {
              handleOriginal();
            }
          }
        };
      }}
    />
  );
}

const INITIAL_STATE = {
  loading: false,
  rates: []
}

class RatesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE
    };
  }

  componentDidMount() {
    this.setState({ loading: true }, () => {
      this.props.firebase.rates().orderByChild('from').on('value', snap => {
        let rates = [];
        // NOTE we have to use .forEach here, there is no guarantee snap.val() is in order, snap.forEach however enforces the sort
        snap.forEach(item => { 
          // rates[item.key] = item.val();
          rates.push({
            ...item.val(),
            rid: item.key
          })
        })
        this.setState({
          loading: false,
          rates: rates
        });
      });
    });
  }

  componentWillUnmount() {
    this.props.firebase.rates().off();
  }

  render() {
    const { loading, rates } = this.state;
    const t = this.props.t;

    return (
      <React.Fragment>
        <Ratebar />
        <div className="main-wrapper">
          <h1>{t('rates')}</h1>
          <AuthUserContext.Consumer>
            {authUser => 
              <React.Fragment>
                { authUser && authUser.claims.role === ROLES.ADMIN &&
                  <Link to={ROUTES.RATES_ADD} className="topnav"><i className="fas fa-plus fa-sm"></i> Add New Distance Rate</Link>
                }
                {loading
                  ? <p>Loading ...</p>
                  : <RatesTable role={authUser.claims.role} rates={rates} history={this.props.history} />
                }
              </React.Fragment>
            }
          </AuthUserContext.Consumer>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslate(withFirebase(withFade(RatesList)));
