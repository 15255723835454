import React from 'react';
import { NavLink } from 'react-router-dom';

// import { TranslatableText as TT } from '../Language';
// import { AuthUserContext } from '../Session';

import * as ROUTES from '../../constants/routes';
// import * as ROLES from '../../constants/roles';

// note custom active for first link, as the other 2 links will trigger the first by default since nested under
const Ratebar = () => (
  <div className="tab">
    <div className="quick-link">
      <NavLink activeClassName="active" to={ROUTES.RATES}
        isActive={(match, location) => {
          return location.pathname === ROUTES.RATES;
        }}
      >
        <i className="fas fa-route fa-lg"></i><br />
        <span>Distance Rate<br /></span>
      </NavLink>
      <NavLink activeClassName="active" to={ROUTES.WAITING_RATES}>
        <i className="fas fa-hourglass-half fa-lg"></i><br />
        <span>Waiting Time Rate<br /></span>
      </NavLink>
      <NavLink activeClassName="active" to={ROUTES.DAY_RATES}>
        <i className="fas fa-calendar-day fa-lg"></i><br />
        <span>Day Job Rate<br /></span>
      </NavLink>
      <NavLink activeClassName="active" to={ROUTES.WAY_RATES}>
        <i className="fas fa-directions fa-lg"></i><br />
        <span>Way Rate<br /></span>
      </NavLink>
      <NavLink activeClassName="active" to={ROUTES.WORKSHOP_RATES}>
        <i className="fas fa-tools fa-lg"></i><br />
        <span>Workshop Time Rate<br /></span>
      </NavLink>
    </div>
  </div>
);

export default Ratebar;
