import React from 'react';

import { withFirebase } from '../Firebase';
import Select from './Select';
import * as ROLES from '../../constants/roles';

/*
wrapper for driver selection
*/
class DriverSelect extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      driversList: []
    };
  }

  componentDidMount() {
    var nameSort = (a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    };

    this.props.firebase.users().orderByChild('displayRole').equalTo(ROLES.DRIVER).on('value', snap => {
      const driversList = []; // convert into array of label values for use in option
      snap.forEach(item => {
        const val = item.val();
        driversList.push({ label: val.name, value: item.key });
      });

      this.setState({ driversList: driversList.sort(nameSort) });
    });
  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  render() {
    let props = this.props;
    let { driversList } = this.state;

    return (
      <Select
        {...props}
        options={driversList}
      />
    );
  }

}

export default withFirebase(DriverSelect);
export { DriverSelect as PureDriverSelect };