import React from 'react';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { generate } from 'generate-password';

import withFade from '../UI/withFade';
import { withTranslate } from '../Language';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
// import * as EMAIL from '../../constants/email';
import Input from '../Form/Input';
import Select from '../Form/Select';
import ShowPasswordInput from '../Form/ShowPasswordInput';
import FormValidator from '../Form/FormValidator';
import '../Form/CustomForm.css';

/*
- list of users from api
- sortable, fitlerable? need state then, but that is the other component inside this page, the grid itself
*/

const INITIAL_STATE = {
  saving: false,
  form: {
    username: '',
    name: '',
    displayRole: '',
    password: '',
    passwordConfirmation: '',
  },
}

class UsersAdd extends React.Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: 'name',
        method: 'isEmpty',
        validWhen: false,
        message: 'Name is required.'
      },
      {
        field: 'displayRole',
        method: 'isEmpty',
        validWhen: false,
        message: 'Role is required.'
      },
      {
        field: 'username',
        method: 'isEmpty',
        validWhen: false,
        message: 'Username is required.'
      },
      {
        field: 'password',
        method: 'isEmpty',
        validWhen: false,
        message: 'Password is required.'
      },
      {
        field: 'password',
        method: 'isAlphanumeric',
        args: ['en-US'],
        validWhen: true,
        message: 'Only alpha-numerics are allowed for Password.'
      },
      {
        field: 'password',
        method: 'isLength',
        args: [{ min: 8, max: undefined }],
        validWhen: true,
        message: 'Password at least 8 characters.'
      },
      {
        field: 'password',
        method: 'matches',
        args: [/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])([0-9a-zA-Z]+)$/],
        validWhen: true,
        message: 'Password must contain upper/lowercase letters and digits.'
      },
      {
        field: 'passwordConfirmation',
        method: 'isEmpty',
        validWhen: false,
        message: 'Password Confirmation is required.'
      },
      {
        field: 'passwordConfirmation',
        method: this.passwordMatch,   // custom function
        validWhen: true,
        message: 'Password confirmation does not match.'
      },
    ]);

    this.state = {
      ...INITIAL_STATE,
      validation: this.validator.valid()
    };

    this.submitted = false;
  }

  onSubmit = e => {
    e.preventDefault();
    const validForm = this.checkValidForm();
    const { form } = this.state;
    this.submitted = true;

    if (validForm) {
      this.setState({ saving: true }, () => {
        this.props.firebase.createUser(form).then(res => {
          if (res.data) {
            switch (res.data.status) {
              case 200:
                this.setState({ saving: false });
                NotificationManager.success(res.data.message);
                this.props.history.push(ROUTES.USERS);
                break;
              case 500:
                this.setState({ saving: false });
                NotificationManager.error(res.data.message);
                break;
              default:
                this.setState({ saving: false });
                NotificationManager.info(res.data.message);
            }
          }
        }).catch(err => {
          NotificationManager.error(err.message);
          // console.log('Server error', err.code, err.message, err.details);
        });
      });
    } else {
      console.log('Invalid submission');
    }
  }

  onChange = e => {
    const form = Object.assign({}, this.state.form);
    form[e.target.name] = e.target.value;
    this.setState({ form });
  }

  onResetForm = e => {
    e.preventDefault();
    this.setState({ 
      ...INITIAL_STATE
    });
    this.submitted = false;
  }

  checkValidForm = () => {
    const validation = this.validator.validate(this.state.form);
    this.setState({ validation: validation });
    return validation.isValid;
  }

  passwordMatch = (confirmation) => (this.state.form.password === confirmation)

  generatePassword = e => {
    e.preventDefault();
    const form = Object.assign({}, this.state.form);
    form.password = generate({
      numbers: true,
      uppercase: true,
      strict: true,
    });
    this.setState({ form });
  }

  render() {
    const { saving, form } = this.state;
    let validation = this.submitted ? this.validator.validate(form) : this.state.validation;
    const rolesList = ROLES.LIST;
    const t = this.props.t;

    return (
      <React.Fragment>
        <h1>{t('users.add')}</h1>
        <Link to={ROUTES.USERS} className="topnav"><i className="fas fa-arrow-left fa-sm"></i> Back to Users</Link>
        <form className="customform userform" onSubmit={this.onSubmit}>
          <Input
            name="name"
            className=""
            labelText={t('name')}
            type="text"
            value={form.name}
            invalid={validation.name.isInvalid}
            message={validation.name.message}
            onChange={this.onChange}
          />
          <Select
            name="displayRole"
            className="noappearance"
            labelText="Role"
            value={form.displayRole}
            invalid={validation.displayRole.isInvalid}
            message={validation.displayRole.message}
            onChange={this.onChange}
            options={rolesList}
          />
          <Input
            name="username"
            className=""
            labelText={t('users.username')}
            type="text"
            value={form.username}
            invalid={validation.username.isInvalid}
            message={validation.username.message}
            onChange={this.onChange}
          />
          <a href="/#" onClick={this.generatePassword}>Generate Password</a>
          <ShowPasswordInput
            name="password"
            className=""
            labelText={t('users.password')}
            value={form.password}
            invalid={validation.password.isInvalid}
            message={validation.password.message}
            onChange={this.onChange}
            generator={true}
          />
          <Input
            name="passwordConfirmation"
            className=""
            labelText="Confirm Password"
            type="password"
            value={form.passwordConfirmation}
            invalid={validation.passwordConfirmation.isInvalid}
            message={validation.passwordConfirmation.message}
            onChange={this.onChange}
          />
          <button type="submit" className="positive" disabled={saving}><i className="fas fa-save fa-md"></i> {saving ? t('form.saving') : 'Create User'}</button>{' '}
          <button onClick={this.onResetForm} disabled={saving}><i className="fas fa-times fa-md"></i> {t('form.reset')}</button>
        </form>
      </React.Fragment>
    );
  }
}

export default withTranslate(withFirebase(withFade(UsersAdd)));
