import React from 'react';
import PropTypes from 'prop-types';

/*
select component that displays floating label and active state
*/
class Select extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      isFocused: false,
    };
  }

  enableFocus = () => {
    this.setState({ isFocused: true }); // also show label when focused even if empty
  }

  disableFocus = () => {
    this.setState((prevState) => ({ isFocused: false }));
  }

  render() {
    let props = this.props;
    let className = "field";
    if(props.className) className += ` ${props.className}`;
    if(this.state.isFocused || props.value !== '') className += ' active';
    if(this.state.isFocused) className += ' focused';
    if(props.invalid) className += ' has-error';

    return (
      <div className={className}>
        <label htmlFor={props.name}>{props.labelText}</label>
        <select id={props.name} name={props.name}
          value={props.value}
          onFocus={this.enableFocus}
          onBlur={this.disableFocus}
          onChange={props.onChange}
          disabled={props.disabled}
        >
          <option value="">{props.labelText}</option>
          {props.options.map((option, i) => (
            <option key={i} value={option.value} disabled={props.disabledOptions.includes(option.value)}>
              {option.label}
            </option>
          ))}
        </select>
        {props.invalid ? (
          <span className="help-block">{props.message}</span>
        ) : null}
      </div>
    );
  }
}

export default Select;

Select.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  labelText: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array.isRequired,
  message: PropTypes.string,
  invalid: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  disabledOptions: PropTypes.array
};

Select.defaultProps = {
  disabled: false,
  disabledOptions: []
}