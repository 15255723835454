import React from 'react';
import PropTypes from 'prop-types';

/*
input component that displays floating label and active state
*/
class Input extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      isFocused: false,
    };
  }

  enableFocus = () => {
    this.setState({ isFocused: true }); // also show label when focused even if empty; calc in render, else no effect 
  }

  disableFocus = () => {
    this.setState((prevState) => ({ isFocused: false }));
  }

  render() {
    let props = this.props;
    let className = "field";
    if(props.className) className += ` ${props.className}`;
    if(this.state.isFocused || props.value !== '') className += ' active';
    if(this.state.isFocused) className += ' focused';
    if(props.invalid) className += ' has-error';

    return (
      <div className={className}>
        <label htmlFor={props.name}>{props.labelText}</label>
        {(props.type === 'textarea') ? (
          <textarea id={props.name} name={props.name} placeholder={this.state.isFocused ? '' : props.labelText}
            value={props.value}
            onFocus={this.enableFocus}
            onBlur={this.disableFocus}
            onChange={props.onChange}
            disabled={props.disabled}
            {...props.inputProps}
          />
        ) : (
          <input type={props.type} id={props.name} name={props.name} placeholder={this.state.isFocused ? '' : props.labelText}
            value={props.value}
            onFocus={this.enableFocus}
            onBlur={this.disableFocus}
            onChange={props.onChange}
            disabled={props.disabled}
            {...props.inputProps}
          />
        )}
        {props.invalid ? (
          <span className="help-block">{props.message}</span>
        ) : null}
      </div>
    );
  }
}

export default Input;

Input.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  labelText: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  message: PropTypes.string,
  invalid: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
};

Input.defaultProps = {
  type: 'text',
  disabled: false,
  value: '',
  inputProps: {},
};