import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';

// import Navbar from './components/UI/Navbar';
import Page from './components/Pages/Page';
import Landing from './components/Landing'
import Dashboard from './components/Dashboard';
import Users from './components/Users';
import Locations from './components/Locations';
import Rates from './components/Rates';
import Records from './components/Records';
import WayTripRecords from './components/WayTripRecords';
import WaitingRecords from './components/WaitingRecords';
import WorkshopRecords from './components/WorkshopRecords';
import DayJobRecords from './components/DayJobRecords';
import Vehicles from './components/Vehicles';
import Jobs from './components/Jobs';
import Companies from './components/Companies';
import Account from './components/Account';

// import { withFirebase } from './components/Firebase';
import { withAuthentication } from './components/Session';
import * as ROUTES from './constants/routes';
// import logo from './logo.svg';
import './css/normalize.css';
import './css/App.css';

class App extends Component {
  // component dun need state if - not used in render, or can be calc from props
  // hoist up if - one component's state affect another component to re-render; likely when 2 components use the same data/api
  // hoist up and let the common ancestor perform the api and store the data, and pass the data to descendants as props
  // here, recentworks' items are not used by other comps, so it can handle its own data without hoist
  // react spring

  /*
  routes - links; need to use Link component in order not to trigger refresh; nothing changes for the markup, just extra js gen to watch the link
  routes - links (navbar) need to be nested in the router for it to watch the links
  router must always contain 1 child only, it is like how render must only contain 1 child; router figures out and then renders
  if we only want 1 route to render in a group (multiple routes may match the same path and render multiple comps), use a switch comp
  how to update title when changing route? use document.title in componentdidmount (and wrap a component so DRY), or use react-helmet
    https://stackoverflow.com/questions/52447828/is-there-a-way-to-modify-the-page-title-with-react-router-v4
  server needs to serve the correct page if visiting by url directly instead of routing (node server should route everything to index.html)
  */


/* routes needed:
profile - my own page, just view, no edit; eg to verify my own name and login dts
dashboard - notifications list page, can be long, need to have 'load more' instead of fetching entire list; dismiss via side swipe?
login - redirected here if not logged in
drivers
users
vehicles
rates
locations
companies
jobs

dun have protected routes yet; nor session (page refresh)
*/
  
  render() {
    const env = process.env.NODE_ENV;
    const forceDev = Boolean(process.env.REACT_APP_FORCE_DEV);

    return (
      <Router>
        <div className="App">
          {(env === 'development' || forceDev) && 
            <div className="EnvRef">Environment: development</div>
          }
          <Switch>
            {/*<Route exact path="/" component={Home} />*/}
            <Route exact path={ROUTES.LANDING} component={Landing} />
            <Route path={ROUTES.DASHBOARD} render={props => (
              <Page {...props} component={Dashboard} title="Dashboard" />
            )} />
            <Route path={ROUTES.ACCOUNT} render={props => (
              <Page {...props} component={Account} title="Account" />
            )} />
            <Route path={ROUTES.USERS} render={props => (
              <Page {...props} component={Users} title="Users" />
            )} />
            <Route path={ROUTES.LOCATIONS} render={props => (
              <Page {...props} component={Locations} title="Locations" />
            )} />
            <Route path={ROUTES.RATES} render={props => (
              <Page {...props} component={Rates} title="Rates" />
            )} />
            <Route path={ROUTES.WAY_RATES} render={props => (
              <Page {...props} component={Rates} title="Rates" />
            )} />
            <Route path={ROUTES.RECORDS} render={props => (
              <Page {...props} component={Records} title="Records" />
            )} />
            <Route path={ROUTES.WAY_RECORDS} render={props => (
              <Page {...props} component={WayTripRecords} title="Way Trip Records" />
            )} />
            <Route path={ROUTES.WAITING_RECORDS} render={props => (
              <Page {...props} component={WaitingRecords} title="Waiting Records" />
            )} />
            <Route path={ROUTES.WORKSHOP_RECORDS} render={props => (
              <Page {...props} component={WorkshopRecords} title="Workshop Records" />
            )} />
            <Route path={ROUTES.OTHER_RECORDS} render={props => (
              <Page {...props} component={DayJobRecords} title="Day Job Records" />
            )} />
            <Route path={ROUTES.VEHICLES} render={props => (
              <Page {...props} component={Vehicles} title="Vehicles" />
            )} />
            <Route path={ROUTES.JOBS} render={props => (
              <Page {...props} component={Jobs} title="Jobs" />
            )} />
            <Route path={ROUTES.COMPANIES} render={props => (
              <Page {...props} component={Companies} title="Companies" />
            )} />

            <Route path="*" component={Landing} />
          </Switch>
        </div>
      </Router>
    );
  }
}

// export default withFirebase(App);
export default withAuthentication(App); // no need to wrap withFirebase, withAuthentication already provides that since it is dependent
