import React from 'react';

import { AuthUserContext } from '../Session';
import { withTranslate } from '../Language';
import * as ROLES from '../../constants/roles';
import Ratebar from '../UI/Ratebar';
import WaitingRatesEdit from './WaitingRatesEdit';
import WaitingRatesView from './WaitingRatesView';

const WaitingRates = ({ t }) =>
  <React.Fragment>
    <Ratebar />
    <div className="main-wrapper">
      <h1>{t('waitingrates')}</h1>
      <AuthUserContext.Consumer>
        {authUser =>
          authUser && ((authUser.claims.role === ROLES.ADMIN &&
            <WaitingRatesEdit />
          ) || ((authUser.claims.role === ROLES.DRIVER || authUser.claims.role === ROLES.SUPERVISOR) &&
            <WaitingRatesView />
            ))}
      </AuthUserContext.Consumer>
    </div>
  </React.Fragment>

export default withTranslate(WaitingRates);
