import React from 'react';
import { NotificationManager } from 'react-notifications';
import moment from 'moment';

import { withFirebase } from '../Firebase';
import { withAuthorization, AuthUserContext } from '../Session';
import withFade from '../UI/withFade';
import { toDollar } from '../../constants/math';
import * as ROLES from '../../constants/roles';
import Input from '../Form/Input';
import FormValidator from '../Form/FormValidator';
import '../Form/CustomForm.css';
import { withTranslate } from '../Language';

const INITIAL_STATE = {
  loading: false,
  saving: false,
  rate: null,
  form: null,
  editMode: false
}

class WorkshopRatesEdit extends React.Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: 'amount',
        method: 'isEmpty',
        validWhen: false,
        message: 'Amount is required.'
      },
      {
        field: 'amount',
        method: 'isFloat',
        args: [{ gt: 0 }],
        validWhen: true,
        message: 'Amount is invalid.'
      }
    ]);

    this.state = {
      ...INITIAL_STATE,
      validation: this.validator.valid()
    };

    this.submitted = false;
    this._isMounted = false;
  }

  componentDidMount() {
    this.setState({ loading: true });
    this._isMounted = true;

    this.props.firebase.workshoprate()
      .on('value', snapshot => {
        const obj = snapshot.val();

        const rate = {
          amount: toDollar(obj.amount), // convert first to match 2dp of input
          createdAt: obj.createdAt,
          createdBy: obj.createdBy,
          updatedAt: obj.updatedAt,
          updatedBy: obj.updatedBy,
        };

        this.setState({
          rate,
          loading: false,
          form: {
            ...rate
          },
        }, () => {
          if (rate.createdBy) {
            this.props.firebase.user(rate.createdBy).once('value', snapshot => {
              const creator = snapshot.val();
              if (creator && this._isMounted) {
                this.setState(prevState => ({
                  rate: {
                    ...prevState.rate,
                    createdByName: creator.name
                  },
                  form: {
                    ...prevState.form,
                    createdByName: creator.name
                  }
                }));
              }
            });
          }

          if (rate.updatedBy) {
            this.props.firebase.user(rate.updatedBy).once('value', snapshot => {
              const creator = snapshot.val();
              if (creator && this._isMounted) {
                this.setState(prevState => ({
                  rate: {
                    ...prevState.rate,
                    updatedByName: creator.name
                  },
                  form: {
                    ...prevState.form,
                    updatedByName: creator.name
                  }
                }));
              }
            });
          }

        });
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.firebase.workshoprate().off()
  }

  onSubmit = e => {
    e.preventDefault();
    const validForm = this.checkValidForm();
    const { form } = this.state;
    const newAmount = parseFloat(form.amount) * 100;
    this.submitted = true;

    if (validForm) {
      this.setState({ saving: true }, () => {
        this.props.firebase.workshoprate().update({
          amount: newAmount,
          updatedAt: this.props.firebase.ServerValue.TIMESTAMP,
          updatedBy: this.context.uid
        })
          .then(() => {
            this.setState(prevstate => ({
              editMode: false,
              rate: {
                ...prevstate.form,
                amount: toDollar(newAmount), // do this way so that we can make 2dp again
                updatedAt: (new Date()).getTime(), // estimate based on current time, not actual ts in dbrecord; saves 1 db retrieval trip
                updatedBy: this.context.uid,
                updatedByName: this.context.name
              },
              form: {
                ...prevstate.form,
                amount: toDollar(newAmount), // do this way so that we can make 2dp again
              },
              saving: false
            }));
            NotificationManager.success('Rate updated');
          })
          .catch(err => {
            this.setState(prevstate => ({
              saving: false
            }));
            NotificationManager.error(err.message);
          });
      });
    } else {
      console.log('Invalid submission');
    }
  }

  onChange = e => {
    const form = Object.assign({}, this.state.form);
    form[e.target.name] = e.target.value;
    this.setState({ form });
  }

  onToggleEdit = e => {
    e.preventDefault();
    this.setState(prevState => ({
      editMode: !prevState.editMode,
      form: {
        ...prevState.rate
      },
    }));
  }

  checkValidForm = () => {
    const validation = this.validator.validate(this.state.form);
    this.setState({ validation: validation });
    return validation.isValid;
  }

  render() {
    const { form, saving, loading, rate, editMode } = this.state;
    const validation = this.submitted ? this.validator.validate(form) : this.state.validation;
    const t = this.props.t;

    return (
      <React.Fragment>
        {loading
          ? <p>Loading ...</p>
          : rate && form && (
            <form className="customform rateform" onSubmit={this.onSubmit}>
              <Input
                name="amount"
                className=""
                labelText="Amount ($) per hour"
                type="text"
                value={form.amount}
                invalid={validation.amount.isInvalid}
                message={validation.amount.message}
                onChange={this.onChange}
                disabled={!editMode}
              />
              {editMode
                ? <center>
                  <button type="submit" className="positive" disabled={saving}><i className="fas fa-save fa-md"></i> {saving ? t('form.saving') : 'Save Changes'}</button>{' '}
                  <button onClick={this.onToggleEdit} disabled={saving}><i className="fas fa-times fa-md"></i> {t('form.cancelchanges')}</button>
                </center>
                : <center>
                  <button onClick={this.onToggleEdit}><i className="fas fa-pencil-alt fa-md"></i> Edit Rate</button>{' '}
                </center>
              }
              <hr />
              {rate.updatedAt && rate.updatedByName &&
                <p className="footnote">
                Updated by {rate.updatedByName} ({moment(rate.updatedAt).calendar()})
                </p>
              }
              {rate.createdAt && rate.createdByName &&
                <p className="footnote">
                Created by {rate.createdByName} ({moment(rate.createdAt).calendar()})
                </p>
              }
            </form>
          )
        }
      </React.Fragment>
    );
  }
}

WorkshopRatesEdit.contextType = AuthUserContext;

const condition = authUser => !!authUser && authUser.claims.role === ROLES.ADMIN;
export default withTranslate(withAuthorization(condition)(withFirebase(withFade(WorkshopRatesEdit))));