import React from 'react';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import moment from 'moment';

import { withAuthorization, AuthUserContext } from '../Session';
import withFade from '../UI/withFade';
import { withTranslate } from '../Language';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import Input from '../Form/Input';
import FormValidator from '../Form/FormValidator';
// import Geocode from '../Geocode';
import '../Form/CustomForm.css';

const INITIAL_STATE = {
  loading: false,
  saving: false,
  location: null,
  form: null,
  editMode: false,
}

class LocationsEdit extends React.Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: 'name',
        method: 'isEmpty',
        validWhen: false,
        message: 'Name is required.'
      }
    ]);

    this.state = {
      ...INITIAL_STATE,
      validation: this.validator.valid()
    }

    this.submitted = false;
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({ loading: true });

    this.props.firebase.location(this.props.match.params.id)
      .once('value', snapshot => {
        const locObj = snapshot.val();
        if (!locObj) return this.setState({ ...INITIAL_STATE });

        const location = {
          ...locObj,
          lid: snapshot.key
        };

        if(this._isMounted) {
          this.setState({
            location,
            loading: false,
            form: {
              ...location
            }
          }, () => {
            if (location.createdBy) {
              this.props.firebase.user(location.createdBy).once('value', snapshot => {
                const creator = snapshot.val();
                if (creator && this._isMounted) {
                  this.setState(prevState => ({
                    location: {
                      ...prevState.location,
                      createdByName: creator.name
                    },
                    form: {
                      ...prevState.form,
                      createdByName: creator.name
                    }
                  }));
                }
              });
            }

            if (location.updatedBy) {
              this.props.firebase.user(location.updatedBy).once('value', snapshot => {
                const creator = snapshot.val();
                if (creator && this._isMounted) {
                  this.setState(prevState => ({
                    location: {
                      ...prevState.location,
                      updatedByName: creator.name
                    },
                    form: {
                      ...prevState.form,
                      updatedByName: creator.name
                    }
                  }));
                }
              });
            }
          });
        }
      });
    
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onSubmit = e => {
    e.preventDefault();
    const validForm = this.checkValidForm();
    const { form } = this.state;
    this.submitted = true;

    if (validForm) {
      this.setState({ saving: true }, () => {
        this.props.firebase.db.ref('/locations').child(form.lid).update({
          name: form.name,
          address: form.address,
          updatedAt: this.props.firebase.ServerValue.TIMESTAMP,
          updatedBy: this.context.uid
        })
          .then(() => {
            this.setState(prevstate => ({
              editMode: false,
              location: {
                ...prevstate.form,
                updatedAt: (new Date()).getTime(), // estimate based on current time, not actual ts in dbrecord; saves 1 db retrieval trip
                updatedBy: this.context.uid,
                updatedByName: this.context.name
              },
              saving: false
            }));
            NotificationManager.success('Location updated');
          })
          .catch(err => {
            this.setState(prevstate => ({
              saving: false
            }));
            NotificationManager.error(err.message);
          });
      });

      // Geocode.fromAddress(form.address)
      //   .then(
      //     response => {
      //       const { lat, lng } = response.results[0].geometry.location;
      //       return { lat, lng };
      //     },
      //     error => {
      //       // console.error(error);
      //       return { lat: null, lng: null };
      //     }
      //   )
      //   .then(({ lat, lng }) => {
      //     // update here if using geocode
      //   });
    } else {
      console.log('Invalid submission');
    }
  }

  onChange = e => {
    const form = Object.assign({}, this.state.form);
    form[e.target.name] = e.target.value;
    this.setState({ form });
  }

  onToggleEdit = e => {
    e.preventDefault();
    this.setState(prevState => ({
      editMode: !prevState.editMode,
      form: {
        ...prevState.location,
      },
    }));
  }

  checkValidForm = () => {
    const validation = this.validator.validate(this.state.form);
    this.setState({ validation: validation });
    return validation.isValid;
  }

  render() {
    const { loading, saving, location, editMode, form } = this.state;
    let validation = this.submitted ? this.validator.validate(form) : this.state.validation;
    const t = this.props.t;

    return (
      <React.Fragment>
        <h1>{t('locations.edit')}</h1>
        <Link to={ROUTES.LOCATIONS} className="topnav"><i className="fas fa-arrow-left fa-sm"></i> Back to Locations</Link>
        {loading
          ? <p>Loading ...</p>
          : location && form && (
            <form className="customform locationform" onSubmit={this.onSubmit}>
              <Input
                name="name"
                className=""
                labelText={t('name')}
                type="text"
                value={form.name}
                invalid={validation.name.isInvalid}
                message={validation.name.message}
                onChange={this.onChange}
                disabled={!editMode}
              />
              <Input
                name="address"
                className=""
                labelText={t('address')}
                type="text"
                value={form.address}
                onChange={this.onChange}
                disabled={!editMode}
              />
              {editMode
                ? <center>
                  <button type="submit" className="positive" disabled={saving}><i className="fas fa-save fa-md"></i> {saving ? t('form.saving') : 'Save Changes'}</button>{' '}
                  <button onClick={this.onToggleEdit} disabled={saving}><i className="fas fa-times fa-md"></i> {t('form.cancelchanges')}</button>
                </center>
                : <center>
                  <button onClick={this.onToggleEdit}><i className="fas fa-pencil-alt fa-md"></i> Edit Location</button>{' '}
                  <Link to={{
                    pathname: `${ROUTES.LOCATIONS}/points/delete/${location.lid}`,
                    state: { location }
                  }} className="button warn"><i className="fas fa-trash-alt fa-md"></i> Delete Location</Link>
                </center>
              }
              <hr />
              {location.updatedAt && location.updatedByName &&
                <p className="footnote">
                Updated by {location.updatedByName} ({moment(location.updatedAt).calendar()})
                </p>
              }
              {location.createdAt && location.createdByName &&
                <p className="footnote">
                Created by {location.createdByName} ({moment(location.createdAt).calendar()})
                </p>
              }
            </form>
          )
        }
      </React.Fragment>
    );
  }
}

LocationsEdit.contextType = AuthUserContext;

const condition = authUser => !!authUser && authUser.claims.role === ROLES.ADMIN;
export default withTranslate(withAuthorization(condition)(withFirebase(withFade(LocationsEdit))));