import React from 'react';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

import { withFirebase } from '../Firebase';
import { withAuthorization, AuthUserContext } from '../Session';
import { withTranslate } from '../Language';
import withFade from '../UI/withFade';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import Input from '../Form/Input';
import FormValidator from '../Form/FormValidator';
import '../Form/CustomForm.css';

const INITIAL_STATE = {
  saving: false,
  form: {
    from: '',
    amount: ''
  },
  useddistances: {},
}

class RatesAdd extends React.Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: 'from',
        method: 'isEmpty',
        validWhen: false,
        message: 'From Distance is required.'
      },
      {
        field: 'from',
        method: 'isFloat',
        args: [{ min: 0 }],
        validWhen: true,
        message: 'From Distance is invalid.'
      },
      {
        field: 'from',
        method: this.uniqueFrom,   // custom function
        validWhen: true,
        message: 'From Distance already exists.'
      },
      {
        field: 'amount',
        method: 'isEmpty',
        validWhen: false,
        message: 'Amount is required.'
      },
      {
        field: 'amount',
        method: 'isFloat',
        args: [{ gt: 0 }],
        validWhen: true,
        message: 'Amount is invalid.'
      }
    ]);

    this.state = {
      ...INITIAL_STATE,
      validation: this.validator.valid()
    };

    this.submitted = false;
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.firebase.useddistances().once('value')
      .then(snap => {
        const distances = snap.val();
        if(this._isMounted) {
          this.setState({ useddistances: distances });
        }
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onSubmit = e => {
    e.preventDefault();
    const validForm = this.checkValidForm();
    const { form } = this.state;
    this.submitted = true;

    if (validForm) {
      this.setState({ saving: true }, () => {
        this.props.firebase.rates().push().set({
          from: parseFloat(form.from),
          amount: parseFloat(form.amount)*100,
          createdAt: this.props.firebase.ServerValue.TIMESTAMP,
          createdBy: this.context.uid
        })
          .then(() => {
            NotificationManager.success('Rate created');
            this.props.history.push(ROUTES.RATES);
          })
          .catch(err => {
            NotificationManager.error(err.message);
          });
      });
    } else {
      console.log('Invalid submission');
    }
  }

  onChange = e => {
    const form = Object.assign({}, this.state.form);
    form[e.target.name] = e.target.value;
    this.setState({ form });
  }

  onResetForm = e => {
    e.preventDefault();
    this.setState({
      ...INITIAL_STATE,
      validation: this.validator.valid()
    });
    this.submitted = false;
  }

  checkValidForm = () => {
    const validation = this.validator.validate(this.state.form);
    this.setState({ validation: validation });
    return validation.isValid;
  }

  uniqueFrom = (from) => {
    const { useddistances } = this.state;
    const fromKey = (parseFloat(from) + '').replace(/\./g, ',');
    return !useddistances || !useddistances[fromKey];
  }

  render() {
    const { form, saving } = this.state;
    const validation = this.submitted ? this.validator.validate(form) : this.state.validation;
    const t = this.props.t;

    return (
      <div className="main-wrapper">
        <h1>{t('distancerates.add')}</h1>
        <Link to={ROUTES.RATES} className="topnav"><i className="fas fa-arrow-left fa-sm"></i> Back to Rates</Link>
        <form className="customform rateform" onSubmit={this.onSubmit}>
          <Input
            name="from"
            className=""
            labelText="From Distance (km)"
            type="text"
            value={form.from}
            invalid={validation.from.isInvalid}
            message={validation.from.message}
            onChange={this.onChange}
          />
          <Input
            name="amount"
            className=""
            labelText="Amount ($)"
            type="text"
            value={form.amount}
            invalid={validation.amount.isInvalid}
            message={validation.amount.message}
            onChange={this.onChange}
          />
          <center>
            <button type="submit" className="positive" disabled={saving}><i className="fas fa-save fa-md"></i> {saving ? t('form.saving') : 'Create Rate'}</button>{' '}
            <button onClick={this.onResetForm} disabled={saving}><i className="fas fa-times fa-md"></i> {t('form.reset')}</button>
          </center>
        </form>
      </div>
    );
  }
}

RatesAdd.contextType = AuthUserContext;

const condition = authUser => !!authUser && authUser.claims.role === ROLES.ADMIN;
export default withTranslate(withAuthorization(condition)(withFirebase(withFade(RatesAdd))));