import React from 'react';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

import withFade from '../UI/withFade';
import { withTranslate } from '../Language';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as EMAIL from '../../constants/email';
import Input from '../Form/Input';
import ShowPasswordInput from '../Form/ShowPasswordInput';
import FormValidator from '../Form/FormValidator';
import '../Form/CustomForm.css';

class UsersPassword extends React.Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: 'password',
        method: 'isEmpty',
        validWhen: false,
        message: 'Password is required.'
      },
      {
        field: 'password',
        method: 'isAlphanumeric',
        args: ['en-US'],
        validWhen: true,
        message: 'Only alpha-numerics are allowed for Password.'
      },
      {
        field: 'password',
        method: 'isLength',
        args: [{ min: 8, max: undefined }],
        validWhen: true,
        message: 'Password at least 8 characters.'
      },
      {
        field: 'password',
        method: 'matches',
        args: [/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])([0-9a-zA-Z]+)$/],
        validWhen: true,
        message: 'Password must contain upper/lowercase letters and digits.'
      },
      {
        field: 'passwordConfirmation',
        method: 'isEmpty',
        validWhen: false,
        message: 'Password Confirmation is required.'
      },
      {
        field: 'passwordConfirmation',
        method: this.passwordMatch,   // custom function
        validWhen: true,
        message: 'Password confirmation does not match.'
      },
    ]);

    this.state = {
      saving: false,
      form: {
        password: '',
        passwordConfirmation: '',
      },
      ...props.location.state,
      validation: this.validator.valid()
    };

    this.submitted = false;
  }

  componentDidMount() {
    // check we arrived from details (this.state.user; else we return back)
    if(!this.state.user) {
      this.props.history.push(`${ROUTES.USERS}/${this.props.match.params.id}`);
    }
  }

  onSubmit = e => {
    e.preventDefault();
    const validForm = this.checkValidForm();
    const { form } = this.state;
    this.submitted = true;

    if (validForm) {
      this.setState({ saving: true }, () => {
        this.props.firebase.updatePassword(this.state.user.uid, { 
          ...form, 
          username: this.state.user.username.replace(EMAIL.SUFFIX, '')
        }).then(res => {
          if (res.data) {
            switch (res.data.status) {
              case 200:
                this.setState({ saving: false });
                NotificationManager.success(res.data.message);
                this.props.history.push(`${ROUTES.USERS}/${this.props.match.params.id}`);
                break;
              case 500:
                this.setState({ saving: false });
                NotificationManager.error(res.data.message);
                break;
              default:
                this.setState({ saving: false });
                NotificationManager.info(res.data.message);
            }
          }
        }).catch(err => {
          NotificationManager.error(err.message);
          // console.log('Server error', err.code, err.message, err.details);
        });
      });
    } else {
      console.log('Invalid submission');
    }
  }

  onChange = e => {
    const form = Object.assign({}, this.state.form);
    form[e.target.name] = e.target.value;
    this.setState({ form });
  }

  checkValidForm = () => {
    const validation = this.validator.validate(this.state.form);
    this.setState({ validation: validation });
    return validation.isValid;
  }

  passwordMatch = (confirmation) => (this.state.form.password === confirmation)

  render() {
    const { saving, form, user } = this.state;
    let validation = this.submitted ? this.validator.validate(form) : this.state.validation;
    const uid = this.props.match.params.id;
    const t = this.props.t;

    return (
      <React.Fragment>
        <h1>{t('users.updatepassword')}</h1>
        <Link to={`${ROUTES.USERS}/${uid}`} className="topnav"><i className="fas fa-arrow-left fa-sm"></i> Back to &quot;{user.name}&quot;</Link>
        <form className="customform userform" onSubmit={this.onSubmit}>
          <ShowPasswordInput
            name="password"
            className=""
            labelText={t('users.password')}
            value={form.password}
            invalid={validation.password.isInvalid}
            message={validation.password.message}
            onChange={this.onChange}
          />
          <Input
            name="passwordConfirmation"
            className=""
            labelText={t('users.confirmpassword')}
            type="password"
            value={form.passwordConfirmation}
            invalid={validation.passwordConfirmation.isInvalid}
            message={validation.passwordConfirmation.message}
            onChange={this.onChange}
          />
          <button type="submit" className="positive" disabled={saving}><i className="fas fa-save fa-md"></i> {saving ? t('form.saving') : 'Update Password'}</button>{' '}
          {saving
            ? <span className="button disabled"><i className="fas fa-times fa-md"></i> Cancel Update</span>
            : <Link to={`${ROUTES.USERS}/${uid}`} className="button"><i className="fas fa-times fa-md"></i> Cancel Update</Link>
          }
        </form>
      </React.Fragment>
    );
  }
}

export default withTranslate(withFirebase(withFade(UsersPassword)));
