import React from 'react';

import { withFirebase } from '../Firebase';

const SignOutButton = ({ firebase, ...props }) => (
  <button type="button" {...props} onClick={firebase.doSignOut}>
    <i className="fas fa-sign-out-alt fa-sm"></i>
  </button>
);

export default withFirebase(SignOutButton);