import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { withAuthorization } from '../Session';
import * as ROUTES from '../../constants/routes';
import RecordAdd from './RecordsAdd';
import RecordsEdit from './RecordsEdit';
import RecordsView from './RecordsView';
import RecordsDelete from './RecordsDelete';

/*
consider moving into route of /records
List - all may view; 
    - combined; should be in the records list together with the rest; no point having a separate list
    struct: /waitingrecords/driverId/rId 
    date, driver/id, vehicle/id, job/id, sitelocation/id, company/id, startat, endat, 
    amount, (PROBLEM: this relates to trips as well! if rate is ever changed, this will NOT be affected, unless it is edited)
    dateDriver, monthDriver, remarks


MONDAY 2019-03-11 TODO: 
1. list in all records to include this and job counts, to include in calendar, [v] 
2. to allow view for driver; edit button for admin (double check trip similar, super may not edit, may add) [v]
3. edit view for admin; test save [v]
4. repeat for jobs
5. main done!
* note wait/day jobs are not add-able by the driver themselves so no need to consider the cases
*/
const Records = () => (
  <div className="main">
    <div className="main-wrapper">
      <Switch>
        <Route exact path={ROUTES.WAITING_RECORDS_ADD} component={RecordAdd} />
        <Route exact path={ROUTES.WAITING_RECORDS_EDIT} component={RecordsEdit} />
        <Route exact path={ROUTES.WAITING_RECORDS_DELETE} component={RecordsDelete} />
        <Route exact path={ROUTES.WAITING_RECORDS_VIEW} component={RecordsView} />
      </Switch>
    </div>
  </div>
);

const condition = authUser => !!authUser; 
export default withAuthorization(condition)(Records);
