import React from 'react';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

import withFade from '../UI/withFade';
import { withTranslate } from '../Language';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { AuthUserContext } from '../Session';
import { StickyTable, Row, Cell } from 'react-sticky-table';
import BaseTable, { Column, AutoResizer } from 'react-base-table';
import Input from '../Form/Input';
import FormValidator from '../Form/FormValidator';
import '../Form/CustomForm.css';
import 'react-base-table/styles.css';
import 'react-sticky-table/dist/react-sticky-table.css';
import '../Locations/matrix.css';
import '../Locations/modal.css';
import Ratebar from '../UI/Ratebar';
import { toDollar } from '../../constants/math';

const LocDistMatrix = ({ locdistances, handleOpen, highlighted, role }) => {
  if (!locdistances.length)
    return <p>No data</p>;

  var data = [];
  var columns = [{
    key: 'col-0',
    dataKey: 'col-0',
    title: '',
    width: 250,
    frozen: Column.FrozenDirection.LEFT
  }];

  locdistances.forEach((col, cindex) => {
    columns.push({
      key: `col-${cindex + 1}`,
      dataKey: `col-${cindex + 1}`,
      title: col.name,
      width: 65
    });

    var row = {
      id: `row-${cindex}`,
      parentId: null,
      'col-0': { name: col.name, y: col.key }
    };
    col.distances.forEach((item, index) => {
      const wayRate = item.wayRate ? toDollar(item.wayRate) : '0';
      if (item.key === col.key) {
        row[`col-${index + 1}`] = { name: '', x: item.key, y: item.key };
      } else {
        row[`col-${index + 1}`] = { name: wayRate, x: item.key, y: col.key, tooltip: `${col.name} » ${item.name}` };
      }
    });
    data.push(row);
  });

  const CustomHeaderCell = ({ className, column }) => (
    <div className={className + ' text-smaller'}><span title={column.title}>{column.title}</span></div>
  )

  const CustomCell = ({ className, cellData, rowIndex, columnIndex }) => {
    if (columnIndex === 0) {
      return <div
        className={className + ' text-smaller'}
      >
        <Link to={`${ROUTES.LOCATIONS}/points/${cellData.y}`} title={cellData.name}>{cellData.name}</Link>
      </div>
    }

    if (rowIndex === columnIndex - 1) {
      className += ' no-data';
    } else {
      className += ' with-data';
    }

    if (highlighted && ((highlighted.x === cellData.x && highlighted.y === cellData.y) || (highlighted.y === cellData.x && highlighted.x === cellData.y))) {
      className += ' highlighted';
    }

    if (role === ROLES.ADMIN) {
      if (!cellData) {
        return <div
          className={className}
        >
          (NA)
        </div>
      }
      
      return <div
        className={className}
        onClick={handleOpen(cellData.x, cellData.y)}
        title={cellData.tooltip}
      >
        {cellData.name}
      </div>
    }

    return <div
      className={className}
      title={cellData.tooltip}
    >
      {cellData.name}
    </div>
  }

  return (
    <div className="MatrixTbl" style={{ width: '100%', height: '600px' }}>
      <AutoResizer>
        {({ width, height }) => (
          <BaseTable
            fixed
            width={width}
            height={height}
            data={data}
            columns={columns}
            components={{
              TableCell: CustomCell,
              TableHeaderCell: CustomHeaderCell
            }}
            headerHeight={150}
          />
        )}
      </AutoResizer>
    </div>
  );
}

const LocDistMatrixV1 = ({ locdistances, handleOpen, highlighted, role }) => {
  if (!locdistances.length)
    return <p>No data</p>;

  var rows = [];
  // header row
  rows.push(
    <Row key="-1">
      <Cell>&nbsp;</Cell>
      {locdistances.map((col, cindex) => (
        <Cell key={cindex}><div><span title={col.name}>{col.name}</span></div></Cell>
      ))}
    </Row>
  );

  locdistances.forEach((item, index) => {
    rows.push(
      <Row key={index}>
        <Cell><Link to={`${ROUTES.LOCATIONS}/points/${item.key}`}>{item.name}</Link></Cell>
        {item.distances.map((col, cindex) => {
          const wayRate = col.wayRate ? toDollar(col.wayRate) : '0';
          return (
            <React.Fragment key={cindex}>
              {(item.key === col.key)
                ? <Cell className="no-data">&nbsp;</Cell>
                : (role === ROLES.ADMIN)
                  ? <Cell
                    className={
                      (highlighted && ((highlighted.x === item.key && highlighted.y === col.key) || (highlighted.x === col.key && highlighted.y === item.key)))
                        ? 'with-data highlighted'
                        : 'with-data'
                    }
                    onClick={handleOpen(col.key, item.key)}>{wayRate}
                  </Cell>
                  : <Cell
                    className={
                      (highlighted && ((highlighted.x === item.key && highlighted.y === col.key) || (highlighted.x === col.key && highlighted.y === item.key)))
                        ? 'with-data highlighted'
                        : 'with-data'
                    }>{wayRate}
                  </Cell>

              }
            </React.Fragment>
          );
        })}
      </Row>
    );
  });

  return (
    <div className="MatrixTbl" style={{ width: '100%', height: '600px' }}>
      <StickyTable>
        {rows}
      </StickyTable>
    </div>
  );
}

const Modal = ({ show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <div className="modal-bg"></div>
      <div className="modal-main">
        {children}
      </div>
    </div>
  );
}

const INITIAL_STATE = {
  loading: false,
  locdistances: [],
  show: false,
  modalLoading: false,
  form: null,
  saving: false,
  highlighted: null,
}

class WayRatesList extends React.Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: 'wayRate',
        method: 'isEmpty',
        validWhen: false,
        message: 'Way Rate is required.'
      },
      {
        field: 'wayRate',
        method: 'isFloat',
        args: [{ min: 0 }],
        validWhen: true,
        message: 'Way Rate is invalid.'
      }
    ]);

    this.state = {
      ...INITIAL_STATE,
      validation: this.validator.valid()
    };

    this._isMounted = false;
    this.submitted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({ loading: true });

    var nameSort = (a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    };

    // need .on here instead of .once, when adding location, the server listener for distance insert may not update immediately
    // SO maybe if we swap distance with location, we can attach .on to distance instead?
    var tmpLocKeys = [];
    return this.props.firebase.locations().once('value').then(locSnap => {
      locSnap.forEach(item => {
        tmpLocKeys.push(item.key);
      });
    }).then(() => {
      this.props.firebase.distances().on('value', dstSnap => {
        let dstValues = [];
        dstSnap.forEach(item => {
          if (tmpLocKeys.indexOf(item.key) === -1) {
            return; // only show if in actual locations, phantom distances records
          }
          let dstValue = item.val();
          dstValue = Object.keys(dstValue).filter(k => (tmpLocKeys.indexOf(k) > -1)).map(k => ({ ...dstValue[k], key: k })); // convert to array and embed key as prop
          let me = dstValue.find(obj => (obj.key === item.key)); // find that child that is the self node, contains name for use
          let name = (me !== undefined) ? me.name : item.key;
  
          dstValues.push({
            name: name,
            key: item.key,
            distances: dstValue.sort(nameSort)
          });
        });
  
        this.setState({
          loading: false,
          locdistances: dstValues.sort(nameSort)
        });
      });
    });
    
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.firebase.distances().off();
  }

  showModal = (x, y) => e => {
    e.preventDefault();
    this.setState({ show: true, modalLoading: true }, () => {

      let { locdistances } = this.state;
      let yItem = locdistances.find(item => (item.key === y));
      if (!yItem) return;

      let distanceItem = yItem.distances.find(item => (item.key === x));

      if (this._isMounted) {
        this.setState({
          modalLoading: false,
          form: {
            y: yItem.key,
            yName: yItem.name,
            x: distanceItem.key,
            xName: distanceItem.name,
            wayRate: distanceItem.wayRate ? toDollar(distanceItem.wayRate) : '0',
          }
        });
      }
    });
  }

  hideModal = e => {
    e.preventDefault();
    this.setState({ show: false, form: null, validation: this.validator.valid(), highlighted: null });
    this.submitted = false;
  }

  submitModal = e => {
    e.preventDefault();
    const validForm = this.checkValidForm();
    const { form } = this.state;
    this.submitted = true;

    if (validForm) {
      this.setState({ saving: true }, () => {
        let update = {};
        const newRate = parseFloat(form.wayRate) * 100;

        update[`${form.x}/${form.y}/wayRate`] = newRate;
        update[`${form.y}/${form.x}/wayRate`] = newRate;

        this.props.firebase.distances().update(update)
          .then(() => {
            this.submitted = false;
            this.setState({ show: false, form: null, saving: false, validation: this.validator.valid(), highlighted: { x: form.x, y: form.y } });
            NotificationManager.success('Way Rate updated');
          })
          .catch(err => {
            this.setState(prevstate => ({
              saving: false
            }));
            NotificationManager.error(err.message);
          });
      });
    } else {
      console.log('Invalid submission');
    }
  }

  onChange = e => {
    const form = Object.assign({}, this.state.form);
    form[e.target.name] = e.target.value;
    this.setState({ form });
  }

  checkValidForm = () => {
    const validation = this.validator.validate(this.state.form);
    this.setState({ validation: validation });
    return validation.isValid;
  }

  render() {
    const { loading, locdistances, modalLoading, form, show, saving, highlighted } = this.state;
    let modalValidation = this.submitted ? this.validator.validate(form) : this.state.validation;
    const t = this.props.t;

    return (
      <React.Fragment>
        <Ratebar />
        <div className="main-wrapper">
          <h1>{t('wayrates.list')}</h1>
          <AuthUserContext.Consumer>
            {authUser =>
              <div style={{ position: 'relative' }}>
                {loading
                  ? <p>Loading ...</p>
                  : <LocDistMatrix role={authUser.claims.role} locdistances={locdistances} handleOpen={this.showModal} highlighted={highlighted} />
                }
                <Modal show={show}>
                  {modalLoading
                    ? <p>Loading ...</p>
                    : form && <form className="customform distanceform" onSubmit={this.submitModal}>
                      <h2>
                        {form.yName} <i className="fas fa-arrows-alt-h fa-md"></i> {form.xName}
                      </h2>
                      <Input
                        name="wayRate"
                        className=""
                        labelText="Way Rate ($)"
                        type="text"
                        value={form.wayRate}
                        invalid={modalValidation.wayRate.isInvalid}
                        message={modalValidation.wayRate.message}
                        onChange={this.onChange}
                      />
                      <button type="submit" className="positive" disabled={saving}><i className="fas fa-save fa-md"></i> {saving ? t('form.saving') : 'Save Changes'}</button>{' '}
                      <button onClick={this.hideModal} disabled={saving}><i className="fas fa-times fa-md"></i> Close</button>
                    </form>
                  }
                </Modal>
              </div>
            }
          </AuthUserContext.Consumer>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslate(withFirebase(withFade(WayRatesList)));
export { WayRatesList as PureWayRatesList, LocDistMatrix };
