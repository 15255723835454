import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { withAuthorization } from '../Session';
import * as ROUTES from '../../constants/routes';
import LocationsList from './LocationsList';
import LocationsAdd from './LocationsAdd';
import LocationsEdit from './LocationsEdit';
import LocationsDelete from './LocationsDelete';
import DistancesEdit from './DistancesEdit';

/*
super/driver may only view
makes sense to show the distance grid as primary view, then the location as sub (add points)?
once a pt A is added, the entry for A>N should also be automatically created, so the distance view should only be about
editing the distance value, not about selecting pts A/N in order to create the value! 
naive model will be: {
  A: {
    B: 10,
    C: 7,
    D: 11
  }
  B: {
    A: 10,
    C: 5,
    D: 13
  }
  ...
}
Some duplication (so distance value update needs to update both parts in a single update) is necessary for speed of read:
  .update({
    '/distances/idA/idB' : 15,
    '/distances/idB/idA' : 15
  })
Also, update to the location names will also be multi nodes? no need, we should be using idA/idB not the name as keys:
  .update({
    '/locations/idA' : { name: 'newname', address: 'new address' },
  })

remember reading 2 flat entries sequentially is faster, then reading entire nested nodes, but to get the entire distance table:
if we just go thru '/distances', we cannot know the 'name' of the entry, unless we make, for each entry, the .ref('/locations/id')
that will be a lot of single reads, STILL OKAY
alternatively, model distance with the name appended in, but this is more nesting again
fb always advise flatter data when possible; pulling distances out of locations is good

remember to ensure write only by admin

also need to think about how to draw that triangular table, a table with undetermined number of columns
  probably need to massage the data first; also disallow sort, paging etc, which makes the table static 
  "matrix"
  assuming we get the matrix, how do we edit the distance entry (add is trivial, as above, via location point adding)
    double click on the value?
      show the textbox, with save/cancel/estimate? cramped
      maybe a modal instead, more space
        so on doubleclick, update state for opening modal as well as which item was clicked by passing params

*/

const Locations = () => (
  <div className="main">
    <div className="main-wrapper">
      <div className="top">
        <Switch>
          <Route exact path={ROUTES.LOCATIONS_ADD} component={LocationsAdd} />
          <Route exact path={ROUTES.LOCATIONS_DELETE} component={LocationsDelete} />
          <Route exact path={ROUTES.LOCATIONS_EDIT} component={LocationsEdit} />
          <Route exact path={ROUTES.LOCATIONS_DISTANCE} component={DistancesEdit} />
          <Route exact path={ROUTES.LOCATIONS} component={LocationsList} />
        </Switch>
      </div>
    </div>
  </div>
);

const condition = authUser => !!authUser; 
export default withAuthorization(condition)(Locations);
