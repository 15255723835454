import React from 'react';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

import { withFirebase } from '../Firebase';
import { withAuthorization, AuthUserContext } from '../Session';
import { withTranslate } from '../Language';
import withFade from '../UI/withFade';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import Input from '../Form/Input';
import FormValidator from '../Form/FormValidator';
import { toFBKey } from '../../constants/math';
import '../Form/CustomForm.css';

const INITIAL_STATE = {
  saving: false,
  form: {
    number: '',
    type: ''
  },
  usedvehicles: {},
}

class VehiclesAdd extends React.Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: 'number',
        method: 'isEmpty',
        validWhen: false,
        message: 'Vehicle Number is required.'
      },
      {
        field: 'number',
        method: this.uniqueNumber,   // custom function
        validWhen: true,
        message: 'Vehicle Number already exists.'
      }
    ]);

    this.state = {
      ...INITIAL_STATE,
      validation: this.validator.valid()
    };

    this.submitted = false;
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.firebase.usedvehicles().once('value')
      .then(snap => {
        const usedvehicles = snap.val();
        if(this._isMounted) {
          this.setState({ usedvehicles });
        }
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onSubmit = e => {
    e.preventDefault();
    const validForm = this.checkValidForm();
    const { form } = this.state;
    this.submitted = true;

    if (validForm) {
      this.setState({ saving: true }, () => {
        this.props.firebase.vehicles().push().set({
          number: form.number,
          type: form.type,
          hashNumber: toFBKey(form.number),
          createdAt: this.props.firebase.ServerValue.TIMESTAMP,
          createdBy: this.context.uid
        })
          .then(() => {
            NotificationManager.success('Vehicle created');
            this.props.history.push(ROUTES.VEHICLES);
          })
          .catch(err => {
            NotificationManager.error(err.message);
          });
      });
    } else {
      console.log('Invalid submission');
    }
  }

  onChange = e => {
    const form = Object.assign({}, this.state.form);
    form[e.target.name] = e.target.value;
    this.setState({ form });
  }

  onResetForm = e => {
    e.preventDefault();
    this.setState({
      ...INITIAL_STATE,
      validation: this.validator.valid()
    });
    this.submitted = false;
  }

  checkValidForm = () => {
    const validation = this.validator.validate(this.state.form);
    this.setState({ validation: validation });
    return validation.isValid;
  }

  uniqueNumber = (number) => {
    const { usedvehicles } = this.state;
    const hashNumber = toFBKey(number);
    return !usedvehicles || !usedvehicles[hashNumber];
  }

  render() {
    const { form, saving } = this.state;
    const validation = this.submitted ? this.validator.validate(form) : this.state.validation;
    const t = this.props.t;

    return (
      <React.Fragment>
        <h1>{t('vehicles.add')}</h1>
        <Link to={ROUTES.VEHICLES} className="topnav"><i className="fas fa-arrow-left fa-sm"></i> Back to Vehicles</Link>
        <form className="customform vehicleform" onSubmit={this.onSubmit}>
          <Input
            name="number"
            className=""
            labelText="Vehicle Number"
            type="text"
            value={form.number}
            invalid={validation.number.isInvalid}
            message={validation.number.message}
            onChange={this.onChange}
          />
          <Input
            name="type"
            className=""
            labelText="Type"
            type="text"
            value={form.type}
            onChange={this.onChange}
          />
          <button type="submit" className="positive" disabled={saving}><i className="fas fa-save fa-md"></i> {saving ? t('form.saving') : 'Create Vehicle'}</button>{' '}
          <button onClick={this.onResetForm} disabled={saving}><i className="fas fa-times fa-md"></i> {t('form.reset')}</button>
        </form>
      </React.Fragment>
    );
  }
}

VehiclesAdd.contextType = AuthUserContext;

const condition = authUser => !!authUser && authUser.claims.role === ROLES.ADMIN;
export default withTranslate(withAuthorization(condition)(withFirebase(withFade(VehiclesAdd))));