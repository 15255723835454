import React from 'react';
import { withRouter } from 'react-router-dom';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

// this does route protection (components not matching condition is redirected to landing)
// returning class instead of just a stateless wrapped component due to life cycle injection
const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      this.listener = this.props.firebase.onAuthUserListener(
        authUser => {
          if(!condition(authUser)) 
            this.props.history.push(ROUTES.LANDING);
        },
        () => this.props.history.push(ROUTES.LANDING)
      );
    }

    componentWillUnmount() {
      this.listener(); // onAuthStateChanged returns an unsubscribe function, calling which removes the listener
    }

    /**
     * strictly we dun have to wrap/use consumer here since in the listener above, the user would have been moved if not matching condition
     * but that only triggers if auth has changed, so if by some link, we arrive to an unauthorized page, w/o change of auth state, then that code
     * doesnt run and the component renders; additionally even if auth state is changed, it being async means the component may render before authstate
     * returns, using the consumer avoids that
     */
    render() {
      return (
        <AuthUserContext.Consumer>
          {authUser => 
            condition(authUser)
              ? <Component {...this.props} />
              : null
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return withRouter(withFirebase(WithAuthorization)); // so that we can use props.firebase
}

export default withAuthorization;
