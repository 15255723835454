import React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { withFirebase } from '../Firebase';
import { withAuthorization, AuthUserContext } from '../Session';
import { withTranslate } from '../Language';
import Fade from 'react-reveal/Fade';
// import withFade from '../UI/withFade';
import { toDollar, recordNumber } from '../../constants/math';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

import '../Form/CustomForm.css';

const moment = extendMoment(Moment);

const INITIAL_STATE = {
  loading: false,
  trip: null,
}

class RecordsView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE
    };

  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({ loading: true });
    const userContext = this.context;
    let uid = userContext.uid;
    if(userContext.claims.role === ROLES.ADMIN || userContext.claims.role === ROLES.SUPERVISOR) uid = this.props.match.params.uid;
    
    this.props.firebase.trip(uid, this.props.match.params.id)
      .on('value', snapshot => {
        const tripObj = snapshot.val();
        if (!tripObj) return this.props.history.push(ROUTES.RECORDS);

        const trip = {
          date: tripObj.date,
          driverId: tripObj.driverId,
          vehicleId: tripObj.vehicleId,
          jobId: tripObj.jobId,
          startLocationId: tripObj.startLocationId,
          endLocationId: tripObj.endLocationId,
          companyId: tripObj.companyId,
          ticket: tripObj.ticket,
          remarks: tripObj.remarks,
          startAt: tripObj.startAt,
          endAt: tripObj.endAt,
          // additional info snapped at point of save:
          amount: tripObj.amount, // in cents, in db value for presentation
          distance: tripObj.distance,
          tid: snapshot.key,
          driver: tripObj.driver,
          vehicle: tripObj.vehicle,
          job: tripObj.job,
          startLocation: tripObj.startLocation,
          endLocation: tripObj.endLocation,
          company: tripObj.company,
          recno: tripObj.recno
        };

        if (this._isMounted) {
          this.setState({
            loading: false,
            trip
          });
        }

      });

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { loading, trip } = this.state;
    var duration = 0;
    const t = this.props.t;

    if (trip && trip.startAt && trip.endAt) {
      // difference, if neg set to zero
      duration = parseInt((trip.endAt - trip.startAt) / (1000 * 60));
    }

    return (
      <React.Fragment>
        {loading
          ? <p>Loading ...</p>
          : trip && (
            <AuthUserContext.Consumer>
              {authUser => <React.Fragment>
                <div className="top">
                  <Fade>
                    <h1>
                      {t('record')}{' '}
                      {trip.recno ? recordNumber(trip.recno) : trip.tid}
                    </h1>
                    <div className="month-section-record">
                      <div className="month-block">
                        <div>
                          {moment(trip.date).format('ddd').toUpperCase()} <br/>
                          <span>{moment(trip.date).format('D')}</span>
                        </div>
                      </div>
                      <div className="record-list">
                        <div>
                          <span>{trip.startLocation} <i className="fas fa-arrow-right fa-sm"></i> {trip.endLocation}</span>
                          <br/>{moment(trip.startAt, 'x').format('h:mma')} - {moment(trip.endAt, 'x').format('h:mma')}
                        </div>
                      </div>
                    </div>
                    <div className="record-detail">
                      <p><span>{t('form.driver')}:</span> {trip.driver}</p>
                      <p><span>{t('form.vehicle')}:</span> {trip.vehicle}</p>
                      <p><span>{t('form.job')}:</span> {trip.job}</p>
                      <p><span>{t('form.sitelocation')}:</span> {trip.startLocation} <i className="fas fa-arrow-right fa-sm"></i> {trip.endLocation}</p>
                      <p className="note">
                        <i className="fas fa-route fa-sm"></i> {t('form.distance')}: {trip.distance}km
                      </p>
                      <p><span>{t('form.company')}:</span> {trip.company}</p>
                      <p><span>{t('form.ticket')}:</span> {trip.ticket}</p>
                      <p><span>{t('form.remarks')}:</span> {trip.remarks}</p>
                      <p><span>{t('form.startat')}:</span> {moment(trip.startAt, 'x').format('h:mma')}</p>
                      <p><span>{t('form.endat')}:</span> {moment(trip.endAt, 'x').format('h:mma')}</p>
                      <p className="note"><i className="far fa-clock fa-sm"></i> {t('form.totalhours')}: {(duration >= 60) && (parseInt(duration / 60) + ' hours')} {(duration % 60 > 0) && ((duration % 60) + ' minutes')}</p>
                      <div className="totalpay">{t('form.amount')}: ${toDollar(trip.amount)}</div>
                      {/* <p className="note">Entered By Tan Kok Meng (Supervisor)</p> */}
                    </div>
                  </Fade>
                </div>
                <div className="fixed-bottom">
                  <div className="quick-link quick-link-2">
                    {(authUser.claims.role === ROLES.DRIVER)
                      ? <div>
                        <Link to={{
                          pathname: ROUTES.RECORDS,
                          state: { fromDate: trip.startAt }
                        }}>
                          <i className="fas fa-long-arrow-alt-left fa-lg"></i><br />
                          <span className="btn-2">{t('form.back')}</span>
                        </Link>
                      </div>
                      : <div>
                        <Link to={{
                          pathname: ROUTES.RECORDS_LIST.replace(':id', trip.driverId),
                          state: { fromDate: trip.startAt }
                        }}>
                          <i className="fas fa-long-arrow-alt-left fa-lg"></i><br />
                          <span className="btn-2">{t('form.back')}</span>
                        </Link>
                      </div>
                    }
                    {(authUser.claims.role === ROLES.ADMIN)
                      ? <div>
                        <Link to={ROUTES.RECORDS_EDIT.replace(':uid', trip.driverId).replace(':id', trip.tid)}>
                          <i className="fas fa-edit fa-lg"></i><br />
                          <span className="btn-2">{t('form.edit')}</span>
                        </Link>
                      </div>
                      : null
                    }
                  </div>
                </div>
              </React.Fragment>}
            </AuthUserContext.Consumer>
          )
        }
      </React.Fragment>
    );
  }
}

RecordsView.contextType = AuthUserContext;

const condition = authUser => !!authUser;
export default withTranslate(withAuthorization(condition)(withFirebase((RecordsView))));