import React from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

// this doesnt do route protection, it just provides the authUser object to consumers
// this version is not just functional, but will store the state, instead of polluting our main App
/* note this concise version will not work, since we are also going to add in state info, so a stateful component is needed instead of a func:
export const withAuthentication = Component => props => (
  <AuthUserContext.Consumer>
    {authUser => <Component {...props} authUser={authUser} />}
  </AuthUserContext.Consumer>
);
*/

const withAuthentication = Component => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        authUser: JSON.parse(localStorage.getItem('authUser'))
      }
    }

    componentDidMount() {
      this.listener = this.props.firebase.onAuthUserListener(
        authUser => { 
          localStorage.setItem('authUser', JSON.stringify(authUser));
          this.setState({ authUser });
        },
        () => {
          localStorage.removeItem('authUser');
          this.setState({ authUser: null })
        }
      );

    }

    componentWillUnmount() {
      this.listener(); // onAuthStateChanged returns an unsubscribe function, calling which removes the listener
    }

    render() {
      return (
        <AuthUserContext.Provider value={this.state.authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      );
    }
  }

  return withFirebase(WithAuthentication); // so that we can use props.firebase
}

export default withAuthentication;
