import React from 'react';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import moment from 'moment';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';

import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import Actionbar from '../UI/Actionbar';
import { withTranslate } from '../Language';
import { withAuthorization } from '../Session';
import { withFirebase } from '../Firebase';
import DriverSelect from '../Form/DriverSelect';
import { toDollar } from '../../constants/math';

class Charts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      form: {
        driverId: '',
      },
      data: {}
    }

    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onChange = e => {
    if (!e.target.value) return false; // dun allow switch to empty
    const form = Object.assign({}, this.state.form);
    form[e.target.name] = e.target.value;
    this.setState({ form });
  }

  onSubmit = e => {
    e.preventDefault();
    const { form } = this.state;

    if (form.driverId) {
      this.setState({ loading: true, data: {} }, () => {
        for (let i = 0; i < 4; i++) {
          let current = moment().subtract(i, "months");
          this.props.firebase.trips(form.driverId).orderByChild('monthDriver').equalTo(current.format('YYYY-MM') + form.driverId).once('value', snap => {
            let trips = snap.val();
            if (this._isMounted) {
              this.setState(prevState => {
                let data = Object.assign({}, prevState.data);
                data[current.format('x')] = trips;
                return {
                  loading: false,
                  data
                };
              });
            }
          });
        }
      });
    }
  }

  render() {
    const { loading, data, form } = this.state;
    const t = this.props.t;
    const chartData = [];
    
    Object.keys(data).forEach(key => {
      let count = 0, 
        amount = 0,
        name = moment(key, 'x').format('MMM');

      if(data[key]) {
        Object.keys(data[key]).forEach(childKey => {
          amount += data[key][childKey].amount;
          count++;
        });
      }

      chartData.push({ name, count, amount: toDollar(amount), key });
    });
    chartData.sort((a, b) => (parseInt(a.key) - parseInt(b.key)));
    
    return (
      <React.Fragment>
        <div className="top">
          <h1>{t('dashboard.title')}</h1>
          <React.Fragment>
            <Link to={ROUTES.DASHBOARD} className="topnav">Notifications</Link>{' | '}
            <Link to={ROUTES.CHARTS} className="topnav">Performances</Link>
          </React.Fragment>
          <Fade>
            <h3>{t('dashboard.charts')}</h3>
            <form className="customform form" onSubmit={this.onSubmit}>
              <DriverSelect
                name="driverId"
                className="noappearance inline"
                labelText={t('form.driver')}
                value={form.driverId}
                onChange={this.onChange}
              />
              <button type="submit" disabled={loading}><i className="fas chart-bar fa-md"></i> {loading ? t('form.loading') : t('fetch')}</button>
            </form>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                data={chartData}
                margin={{
                  top: 20, right: 30, left: 20, bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis yAxisId="left" orientation="left" stroke="#8884d8" />
                <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" />
                <Tooltip />
                <Legend />
                <Bar name="# Trips" yAxisId="left" dataKey="count" fill="#8884d8" />
                <Bar name="Total Amt" yAxisId="right" dataKey="amount" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </Fade>
        </div>
        <Actionbar />
      </React.Fragment>
    );
  }
}

const condition = authUser => !!authUser && authUser.claims.role === ROLES.ADMIN;
export default withTranslate(withAuthorization(condition)(withFirebase(Charts)));