import React from 'react';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import { withTranslate } from '../Language';
import withFade from '../UI/withFade';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
// import Input from '../Form/Input';
import { toDollar } from '../../constants/math';

import '../Form/CustomForm.css';

const moment = extendMoment(Moment);

const INITIAL_STATE = {
  deleting: false,
  wait: null,
}

class RecordsDelete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
      ...props.location.state,
    };

    this._isMounted = false;
  }

  componentDidMount() {
    if (!this.state.wait) {
      return this.props.history.push(ROUTES.WAITING_RECORDS_EDIT.replace(':uid', this.props.match.params.uid).replace(':id', this.props.match.params.id));
    }

    this._isMounted = true;
    // re-update this trip info with more details from actual db:
    this.props.firebase.wait(this.props.match.params.uid, this.props.match.params.id).once('value', snap => {
      if(!snap.exists()) { 
        return this.props.history.push(ROUTES.RECORDS);
      }
      const record = snap.val();
      if(this._isMounted) {
        this.setState({
          wait: {
            ...record,
            rid: snap.key
          }
        });
      }
    })
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onSubmit = e => {
    e.preventDefault();
    const { wait } = this.state;

    this.setState({ deleting: true }, () => {
      this.props.firebase.removeWaitRecordAdmin(wait.rid, wait.driverId)
        .then(() => {
          NotificationManager.success('Waiting Record removed');
          this.props.history.push(ROUTES.RECORDS_LIST.replace(':id', wait.driverId));
        })
        .catch(err => {
          NotificationManager.error(err.message);
        })
    })
  }

  render() {
    const { wait, deleting } = this.state;
    const id = this.props.match.params.id;
    var duration = 0;
    const t = this.props.t;

    if (wait && wait.startAt && wait.endAt) {
      // difference, if neg set to zero
      duration = (wait.endAt - wait.startAt) / (1000 * 60);
    }

    return (
      <React.Fragment>
        <h1>{t('waits.delete')}</h1>
        <Link to={ROUTES.WAITING_RECORDS_EDIT.replace(':uid', wait.driverId).replace(':id', id)} className="topnav"><i className="fas fa-arrow-left fa-sm"></i> {t('form.backtoeditrecord')}</Link>
        <form className="customform waitform" onSubmit={this.onSubmit}>
          <div className="month-section-record">
            <div className="month-block">
              <div>
                {moment(wait.date).format('ddd').toUpperCase()} <br />
                <span>{moment(wait.date).format('D')}</span>
              </div>
            </div>
            <div className="record-list">
              <div>
                <span>{wait.siteLocation} <i className="fas fa-hourglass-half fa-sm grey"></i></span>
                <br />{moment(wait.startAt, 'x').format('h:mma')} - {moment(wait.endAt, 'x').format('h:mma')}
              </div>
            </div>
          </div>
          <div className="record-detail">
            <p><span>{t('form.driver')}:</span> {wait.driver}</p>
            <p><span>{t('form.vehicle')}:</span> {wait.vehicle}</p>
            <p><span>{t('form.job')}:</span> {wait.job}</p>
            <p><span>{t('form.sitelocation')}:</span> {wait.siteLocation}</p>
            <p><span>{t('form.company')}:</span> {wait.company}</p>
            <p><span>{t('form.remarks')}:</span> {wait.remarks}</p>
            <p><span>{t('form.startat')}:</span> {moment(wait.startAt, 'x').format('h:mma')}</p>
            <p><span>{t('form.endat')}:</span> {moment(wait.endAt, 'x').format('h:mma')}</p>
            <p className="note"><i className="far fa-clock fa-sm"></i> {t('form.totalhours')}: {(duration >= 60) && (parseInt(duration / 60) + ' hours')} {(duration % 60 > 0) && ((duration % 60) + ' minutes')}</p>
            <div className="totalpay">{t('form.amount')}: ${toDollar(wait.amount)}</div>
            {/* <p className="note">Entered By Tan Kok Meng (Supervisor)</p> */}
          </div>
          <center>
            <p>Confirm delete this waiting record?</p>
            <button type="submit" disabled={deleting} className="warn" style={{ marginBottom: '20px' }}><i className="fas fa-trash-alt fa-md"></i> {deleting ? t('form.deleting') : 'Delete Waiting Record'}</button>{' '}
          </center>
        </form>
      </React.Fragment>
    );
  }
}

const condition = authUser => !!authUser && authUser.claims.role === ROLES.ADMIN;
export default withTranslate(withAuthorization(condition)(withFirebase(withFade(RecordsDelete))));