import React from 'react';

import { withFirebase } from '../Firebase';
import Select from './Select';

/*
wrapper for company selection
*/
class CompanySelect extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      companiesList: []
    };
  }

  componentDidMount() {
    this.props.firebase.companies().orderByChild('name').on('value', snap => {
      const companiesList = []; // convert into array of label values for use in option
      snap.forEach(item => {
        const val = item.val();
        companiesList.push({ label: val.name, value: item.key });
      });
      this.setState({ companiesList });
    });
  }

  componentWillUnmount() {
    this.props.firebase.companies().off();
  }

  render() {
    let props = this.props;
    let { companiesList } = this.state;

    return (
      <Select
        {...props}
        options={companiesList}
      />
    );
  }

}

export default withFirebase(CompanySelect);
