import React from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import moment from 'moment';
import 'react-table/react-table.css';

import withFade from '../UI/withFade';
import { withTranslate } from '../Language';
import { withFirebase } from '../Firebase';
import Input from '../Form/Input';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { withAuthorization } from '../Session';

/*
delete data; add - validation - must not be same value in from field
one trick is to push the required value (a string) as a top level node:
  /usedkeys/
    "myuniqueval1": itemid
    "myuniqueval2": itemid

  then in item/itemid/uniquekey we add this rule:
    rules: {
      items: {
        $itemid: {
          uniquefield: {
            .validate: "newData.isString() && newData.val().length > 0 && !root.child('usedkeys').child(newData.val()).exists()"
          }
        }
      }
    }

  BUT the catch here, the value must be a valid candidate for a key, so a decimal number, whether numeric or string, is not valid!
  2 ways to solve this -
    1. replace invalid chars (in this case the dot .) with replacements, e.g. comma ','
    2. create hash and use the hash
  not sure if we can do the str replace in the rules itself! because we do not want to store the changed version as the value

    .validate: "newData.child('from').val() >= 0 && !root.child('useddistances').child((newData.child('from').val() + '').replace('.',',')).exists()"
*/

const DriversTable = ({ drivers, history, presences, totalJobs, totalTrips, totalWaits, totalWayTrips, totalWorkshops, currentMonth }) => {
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      minWidth: 200
    }, {
      id: 'trips',
      Header: 'Trips',
      accessor: driver => {
        if (isNaN(totalTrips[driver.uid])) return '-';
        return totalTrips[driver.uid];
      }
    }, {
      id: 'waits',
      Header: 'Waits',
      accessor: driver => {
        if (isNaN(totalWaits[driver.uid])) return '-';
        return totalWaits[driver.uid];
      }
    }, {
      id: 'dayjobs',
      Header: 'Day Jobs',
      accessor: driver => {
        if (isNaN(totalJobs[driver.uid])) return '-';
        return totalJobs[driver.uid];
      }
    }, {
      id: 'waytrips',
      Header: 'Way Trips',
      accessor: driver => {
        if (isNaN(totalWayTrips[driver.uid])) return '-';
        return totalWayTrips[driver.uid];
      }
    }, {
      id: 'workshops',
      Header: 'Workshops',
      accessor: driver => {
        if (isNaN(totalWorkshops[driver.uid])) return '-';
        return totalWorkshops[driver.uid];
      }
    }, {
      id: 'lastSeen',
      Header: 'Last Seen',
      accessor: driver => {
        if (presences[driver.uid] === true) return 'Online';
        if (isNaN(presences[driver.uid])) return 'Never';
        return moment(presences[driver.uid]).calendar();
      }
    }, {
      id: 'action',
      Header: 'Action',
      accessor: driver => <Link to={{
        pathname: `${ROUTES.RECORDS}/list/${driver.uid}`,
        state: { driver }
      }}><i className="fas fa-route fa-lg"></i></Link>,
      maxWidth: 80,
    }
  ];

  return (
    <ReactTable className="-highlight" data={drivers} columns={columns} 
      defaultPageSize={10}
      defaultSorted={[{ id: 'name', desc: false }]}
      getTdProps={(state, rowInfo, column, instance) => {
        return {
          onClick: (e, handleOriginal) => {
            history.push({
              pathname: `${ROUTES.RECORDS}/list/${rowInfo.original.uid}`,
              state: { fromDate: moment(currentMonth).valueOf() }
            });

            if (handleOriginal) {
              handleOriginal();
            }
          }
        };
      }}
    />
  );
}

const INITIAL_STATE = {
  loading: false,
  currentMonth: null,
  drivers: [],
  presences: [],
  totalTrips: [],
  totalWaits: [],
  totalJobs: [],
  totalWorkshops: [],
  totalWayTrips: []
}

class RecordsListAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE,
      currentMonth: moment().format('YYYY-MM')
    };
    this.presenceRef = null;
    this.usersRef = null;
    this.tripsRefs = [];
    this.waitsRefs = [];
    this.jobsRefs = [];
    this.workshopsRefs = [];
    this.waytripsRefs = [];

    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    
    this.setState({ loading: true }, () => {
      this.presenceRef = this.props.firebase.presences();
      this.presenceRef.on('value', snap => {
        let presences = snap.val();
        this.setState({ presences });
      });

      this.usersRef = this.props.firebase.users().orderByChild('displayRole').equalTo(ROLES.DRIVER);
      this.usersRef.on('value', snap => {
        if(!snap.exists()) {
          return this.setState({ loading: false });
        }
        let drivers = []; // order manually on name field, move key into object property in order to create an array

        snap.forEach(item => {
          let val = item.val();
          drivers.push({
            ...val,
            uid: item.key
          });
          
        }); // snap.foreach
        this.setState({ loading: false, drivers }, () => {
          this.updateCounts();
        });

      }); // usersref.on

    }); // setstate
  }

  componentWillUnmount() {
    this._isMounted = false;
    // this.props.firebase.users().off();
    if(this.presenceRef)
      this.presenceRef.off();
    if(this.usersRef)
      this.usersRef.off();
    
    this.clearCountRefs();
  }

  clearCountRefs = () => {
    if (this.tripsRefs.length) {
      this.tripsRefs.forEach(ref => {
        ref.off();
      });
    }
    if (this.waitsRefs.length) {
      this.waitsRefs.forEach(ref => {
        ref.off();
      });
    }
    if (this.jobsRefs.length) {
      this.jobsRefs.forEach(ref => {
        ref.off();
      });
    }
    if (this.workshopsRefs.length) {
      this.workshopsRefs.forEach(ref => {
        ref.off();
      });
    }
    if (this.waytripsRefs.length) {
      this.waytripsRefs.forEach(ref => {
        ref.off();
      });
    }

    this.tripsRefs = [];
    this.waitsRefs = [];
    this.jobsRefs = [];
    this.workshopsRefs = [];
    this.waytripsRefs = [];
  }

  onChangeMonth = e => {
    const currentMonth = e.target.value;
    if (currentMonth) { // do not allow empty selection
      this.setState({ currentMonth }, this.updateCounts);
    }
  }

  // based on currentMonth
  updateCounts = () => {
    const { drivers, currentMonth } = this.state;
    // clear all the count refs first since they are pointing to a different month!
    this.clearCountRefs();
    // clear counts from prev month so we can display a '-' as loading/fetching state indicator:
    this.setState({
      totalTrips: [],
      totalWaits: [],
      totalJobs: [],
      totalWorkshops: [],
      totalWayTrips: []
    }, () => {
        drivers.forEach(driver => {
          let tripRef = this.props.firebase.trips(driver.uid).orderByChild('monthDriver').equalTo(currentMonth + driver.uid);
          let waitRef = this.props.firebase.waits(driver.uid).orderByChild('monthDriver').equalTo(currentMonth + driver.uid);
          let jobRef = this.props.firebase.dayjobs(driver.uid).orderByChild('monthDriver').equalTo(currentMonth + driver.uid);
          let workshopRef = this.props.firebase.workshops(driver.uid).orderByChild('monthDriver').equalTo(currentMonth + driver.uid);
          let waytripRef = this.props.firebase.waytrips(driver.uid).orderByChild('monthDriver').equalTo(currentMonth + driver.uid);

          this.tripsRefs.push(tripRef);
          this.waitsRefs.push(waitRef);
          this.jobsRefs.push(jobRef);
          this.workshopsRefs.push(workshopRef);
          this.waytripsRefs.push(waytripRef);

          tripRef.on('value', snap => {
            this.setState(prevState => ({
              totalTrips: {
                ...prevState.totalTrips,
                [driver.uid]: snap.numChildren()
              }
            }));
          });

          waitRef.on('value', snap => {
            this.setState(prevState => ({
              totalWaits: {
                ...prevState.totalWaits,
                [driver.uid]: snap.numChildren()
              }
            }));
          });

          jobRef.on('value', snap => {
            this.setState(prevState => ({
              totalJobs: {
                ...prevState.totalJobs,
                [driver.uid]: snap.numChildren()
              }
            }));
          });

          workshopRef.on('value', snap => {
            this.setState(prevState => ({
              totalWorkshops: {
                ...prevState.totalWorkshops,
                [driver.uid]: snap.numChildren()
              }
            }));
          });

          waytripRef.on('value', snap => {
            this.setState(prevState => ({
              totalWayTrips: {
                ...prevState.totalWayTrips,
                [driver.uid]: snap.numChildren()
              }
            }));
          });

        });
    });
    
  }

  render() {
    const { loading, drivers, presences, currentMonth, totalJobs, totalTrips, totalWaits, totalWayTrips, totalWorkshops } = this.state;
    const t = this.props.t;

    return (
      <React.Fragment>
        <h1>{t('records.driver')}</h1>
        <form className="customform">
          <Input
            name="currentMonth"
            className=""
            labelText={t('date.month')}
            type="month"
            value={currentMonth}
            onChange={this.onChangeMonth}
          />
        </form>
        {loading
          ? <p>Loading ...</p>
          : <DriversTable drivers={drivers} history={this.props.history} presences={presences} currentMonth={currentMonth}
            totalJobs={totalJobs} totalTrips={totalTrips} totalWaits={totalWaits} totalWayTrips={totalWayTrips} totalWorkshops={totalWorkshops}
          />
        }
      </React.Fragment>
    );
  }
}

const condition = authUser => !!authUser && (authUser.claims.role === ROLES.ADMIN || authUser.claims.role === ROLES.SUPERVISOR);
export default withTranslate(withAuthorization(condition)(withFirebase(withFade(RecordsListAdmin))));
