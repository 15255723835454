import React from 'react';
import moment from 'moment';

import { AuthUserContext, withAuthorization } from '../Session';

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      creator: null,
      updater: null,
    };
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    if(this.context.createdBy) {
      this.props.firebase.user(this.context.createdBy).once('value', snap => {
        if(snap.exists() && this._isMounted) {
          let user = snap.val();
          this.setState({ creator: user.name });
        }
      });
    }
    if(this.context.updatedBy) {
      this.props.firebase.user(this.context.updatedBy).once('value', snap => {
        if(snap.exists() && this._isMounted) {
          let user = snap.val();
          this.setState({ updater: user.name });
        }
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { creator, updater } = this.state;

    return (
      <div className="main">
        <div className="main-wrapper">
          <div className="top">
            <h1>Account</h1>
            <AuthUserContext.Consumer>
              {authUser => (
                <div className="record-detail">
                  <p><span>Name:</span> {authUser.name}</p>
                  <p><span>Role:</span> {authUser.claims.role}</p>
                  <p><span>Session started:</span> {moment(authUser.claims.auth_time, 'X').calendar()}</p>
                  {creator && 
                    <p><span>Created by:</span> {creator} ({moment(authUser.createdAt, 'x').calendar()})</p>
                  }
                  {updater && 
                    <p><span>Updated by:</span> {updater} ({moment(authUser.updatedAt, 'x').calendar()})</p>
                  }
                </div>
              )}
            </AuthUserContext.Consumer>
          </div>
        </div>
      </div>
    );
  }
}

Account.contextType = AuthUserContext;

const condition = authUser => !!authUser;
export default withAuthorization(condition)(Account);