import React from 'react';

import * as ROLES from '../../constants/roles';
import { AuthUserContext } from '../Session';
import RecordsListAdmin from './RecordsListAdmin';
import RecordsListDriver from './RecordsListDriver';
import Actionbar from '../UI/Actionbar';

const RecordsList = () => 
  <React.Fragment>
    <div className="top">
      <AuthUserContext.Consumer>
        {authUser =>
          authUser && ((authUser.claims.role === ROLES.DRIVER &&
            <RecordsListDriver />
          ) || ((authUser.claims.role === ROLES.ADMIN || authUser.claims.role === ROLES.SUPERVISOR) && 
            <RecordsListAdmin />
        ))}
      </AuthUserContext.Consumer>
    </div>
    <Actionbar />
  </React.Fragment>

export default RecordsList;
