import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/functions';
import { GOOGLE_API, SENDER_ID, DOMAIN, DB_URL, PROJECT_ID, BUCKET_ID,
  DEV_GOOGLE_API, DEV_SENDER_ID, DEV_DOMAIN, DEV_DB_URL, DEV_PROJECT_ID, DEV_BUCKET_ID,
 } from '../../constants/keys';

const prodConfig = {
  apiKey: GOOGLE_API,
  authDomain: DOMAIN,
  databaseURL: DB_URL,
  projectId: PROJECT_ID,
  storageBucket: BUCKET_ID,
  messagingSenderId: SENDER_ID
};

const devConfig = {
  apiKey: DEV_GOOGLE_API,
  authDomain: DEV_DOMAIN,
  databaseURL: DEV_DB_URL,
  projectId: DEV_PROJECT_ID,
  storageBucket: DEV_BUCKET_ID,
  messagingSenderId: DEV_SENDER_ID
};

const config = (process.env.NODE_ENV === 'production') && !Boolean(process.env.REACT_APP_FORCE_DEV)
  ? prodConfig
  : devConfig;

class Firebase {
  constructor() {
    if(!app.apps.length) {
      app.initializeApp(config);
    }

    this.auth = app.auth();
    this.db = app.database();
    this.functions = app.functions();
    this.ServerValue = app.database.ServerValue;
  }

  // merge auth and db user: (avoid repeated codes in withAuth and withAutho)
  onAuthUserListener = (next, fallback) => {
    return this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.online().on('value', snap => {
          if (snap.val()) {
            // note disconnections are different from logouts
            this.presence(authUser.uid).onDisconnect().set(this.ServerValue.TIMESTAMP)
              .then(() => {
                this.presence(authUser.uid).set(true);
              });
          }
        })

        this.user(authUser.uid)
          .once('value')
          .then(snapshot => {
            const dbUser = snapshot.val();

            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              ...dbUser
            }

            return authUser;
          }).then(authUser => {
            return this.auth.currentUser.getIdTokenResult().then(idTokenResult => [idTokenResult, authUser]); // pass-thru to append another param
          }).then(([idTokenResult, authUser]) => {
            authUser.claims = idTokenResult.claims;

            next(authUser);
          });
      } else {
        fallback();
      }
    });
  }

  // auth api:
  doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);
  doSignOut = () => {
    // logouts timestamps
    if (this.auth.currentUser && this.auth.currentUser.uid) {
      this.presence(this.auth.currentUser.uid).set(this.ServerValue.TIMESTAMP);
    }
    return this.auth.signOut();
  }
  doPasswordUpdate = password => this.auth.doPasswordUpdate(password);

  // user retrieval; 
  user = uid => this.db.ref(`users/${uid}`);
  users = () => this.db.ref('users');

  // presence:
  online = () => this.db.ref('.info/connected');
  presences = () => this.db.ref('presence');
  presence = uid => this.db.ref(`presence/${uid}`);
  preflang = uid => this.db.ref(`preflang/${uid}`);

  // user crud functions we are using server-side instead of the built-in firebase functions
  updateUser = user => this.functions.httpsCallable('updateUser')(user);
  createUser = user => this.functions.httpsCallable('createUser')(user);
  getUser = uid => this.functions.httpsCallable('getUser')({ uid });
  updatePassword = (uid, user) => this.functions.httpsCallable('updatePassword')({ ...user, uid });

  // trip records
  createTripRecordAdmin = trip => this.functions.httpsCallable('createTripRecordAdmin')(trip);
  updateTripRecordAdmin = (rid, prevDriverId, trip) => this.functions.httpsCallable('updateTripRecordAdmin')({ ...trip, rid, prevDriverId });
  removeTripRecordAdmin = (rid, driverId) => this.functions.httpsCallable('removeTripRecordAdmin')({ rid, driverId }); // cannot direct remove as write perm denied
  createTripRecord = trip => this.functions.httpsCallable('createTripRecord')(trip);
  trips = driverId => this.db.ref(`/triprecords/${driverId}`);
  trip = (driverId, id) => this.db.ref(`/triprecords/${driverId}/${id}`);

  // wait records
  waits = driverId => this.db.ref(`/waitingrecords/${driverId}`);
  wait = (driverId, id) => this.db.ref(`/waitingrecords/${driverId}/${id}`);
  createWaitRecordAdmin = trip => this.functions.httpsCallable('createWaitRecordAdmin')(trip);
  updateWaitRecordAdmin = (rid, prevDriverId, trip) => this.functions.httpsCallable('updateWaitRecordAdmin')({ ...trip, rid, prevDriverId });
  removeWaitRecordAdmin = (rid, driverId) => this.functions.httpsCallable('removeWaitRecordAdmin')({ rid, driverId });

  // workshop records
  workshops = driverId => this.db.ref(`/workshoprecords/${driverId}`);
  workshop = (driverId, id) => this.db.ref(`/workshoprecords/${driverId}/${id}`);
  createWorkshopRecordAdmin = trip => this.functions.httpsCallable('groupWorkshop-createWorkshopRecordAdmin')(trip);
  updateWorkshopRecordAdmin = (rid, prevDriverId, trip) => this.functions.httpsCallable('groupWorkshop-updateWorkshopRecordAdmin')({ ...trip, rid, prevDriverId });
  removeWorkshopRecordAdmin = (rid, driverId) => this.functions.httpsCallable('groupWorkshop-removeWorkshopRecordAdmin')({ rid, driverId });

  // waytrip records
  createWayTripRecordAdmin = trip => this.functions.httpsCallable('groupWay-createWayTripRecordAdmin')(trip);
  updateWayTripRecordAdmin = (rid, prevDriverId, trip) => this.functions.httpsCallable('groupWay-updateWayTripRecordAdmin')({ ...trip, rid, prevDriverId });
  removeWayTripRecordAdmin = (rid, driverId) => this.functions.httpsCallable('groupWay-removeWayTripRecordAdmin')({ rid, driverId }); // cannot direct remove as write perm denied
  createWayTripRecord = trip => this.functions.httpsCallable('groupWay-createWayTripRecord')(trip);
  waytrips = driverId => this.db.ref(`/wayrecords/${driverId}`);
  waytrip = (driverId, id) => this.db.ref(`/wayrecords/${driverId}/${id}`);

  // day jobs
  dayjobs = driverId => this.db.ref(`/dayjobrecords/${driverId}`);
  dayjob = (driverId, id) => this.db.ref(`/dayjobrecords/${driverId}/${id}`);
  createDayJobRecordAdmin = trip => this.functions.httpsCallable('createDayJobRecordAdmin')(trip);
  updateDayJobRecordAdmin = (rid, prevDriverId, trip) => this.functions.httpsCallable('updateDayJobRecordAdmin')({ ...trip, rid, prevDriverId });
  removeDayJobRecordAdmin = (rid, driverId) => this.functions.httpsCallable('removeDayJobRecordAdmin')({ rid, driverId });

  // trip references:
  driverTrips = id => this.db.ref(`/driver_trips/${id}`);
  endLocationTrips = id => this.db.ref(`/endLocation_trips/${id}`);
  jobTrips = id => this.db.ref(`/job_trips/${id}`);
  startLocationTrips = id => this.db.ref(`/startLocation_trips/${id}`);
  vehicleTrips = id => this.db.ref(`/vehicle_trips/${id}`);

  // waytrip references:
  driverWayTrips = id => this.db.ref(`/driver_ways/${id}`);
  endLocationWayTrips = id => this.db.ref(`/endLocation_ways/${id}`);
  jobWayTrips = id => this.db.ref(`/job_ways/${id}`);
  startLocationWayTrips = id => this.db.ref(`/startLocation_ways/${id}`);
  vehicleWayTrips = id => this.db.ref(`/vehicle_ways/${id}`);

  // wait refs/dayjob refs
  driverWaits = id => this.db.ref(`/driver_waits/${id}`);
  driverDayJobs = id => this.db.ref(`/driver_dayjobs/${id}`);
  driverWorkshops = id => this.db.ref(`/driver_workshops/${id}`);

  // locations crud:
  locations = () => this.db.ref('locations');
  location = id => this.db.ref(`locations/${id}`);
  distances = () => this.db.ref('distances');
  distance = id => this.db.ref(`distances/${id}`);
  
  // distance rates crud:
  rates = () => this.db.ref('distancerates');
  rate = id => this.db.ref(`distancerates/${id}`);
  useddistances = () => this.db.ref('useddistances');
  useddistance = id => this.db.ref(`useddistances/${id}`);

  // way trip rates:
  wayrates = () => this.db.ref('wayrates');
  wayrate = id => this.db.ref(`wayrates/${id}`);
  usedways = () => this.db.ref('usedways');
  usedway = id => this.db.ref(`usedways/${id}`);

  // waiting/dayjob rates:
  waitingrate = () => this.db.ref('/waitingrates');
  dayrate = () => this.db.ref('/dayjobrates');
  workshoprate = () => this.db.ref('/workshoprates');

  // vehicles crud:
  vehicles = () => this.db.ref('/vehicles');
  vehicle = id => this.db.ref(`/vehicles/${id}`);
  usedvehicles = () => this.db.ref('/usedvehicles');
  usedvehicle = id => this.db.ref(`/usedvehicles/${id}`);

  // jobs crud:
  jobs = () => this.db.ref('/jobs');
  job = id => this.db.ref(`/jobs/${id}`);
  usedjobs = () => this.db.ref('/usedjobs');
  usedjob = id => this.db.ref(`/usedjobs/${id}`);

  // companies crud:
  companies = () => this.db.ref('/companies');
  company = id => this.db.ref(`/companies/${id}`);
  usedcompanies = () => this.db.ref('/usedcompanies');
  usedcompany = id => this.db.ref(`/usedcompanies/${id}`);

  // notices
  notices = id => this.db.ref(`/notices/${id}`);

}

export default Firebase;