import React from 'react';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import { withTranslate } from '../Language';
import withFade from '../UI/withFade';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
// import Input from '../Form/Input';
import { toDollar } from '../../constants/math';

import '../Form/CustomForm.css';

const moment = extendMoment(Moment);

const INITIAL_STATE = {
  deleting: false,
  trip: null,
}

class RecordsDelete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
      ...props.location.state,
    };

    this._isMounted = false;
  }

  componentDidMount() {
    if (!this.state.trip) {
      return this.props.history.push(ROUTES.WAY_RECORDS_EDIT.replace(':uid', this.props.match.params.uid).replace(':id', this.props.match.params.id));
    }

    this._isMounted = true;
    // re-update this trip info with more details from actual db:
    this.props.firebase.waytrip(this.props.match.params.uid, this.props.match.params.id).once('value', snap => {
      if (!snap.exists()) {
        return this.props.history.push(ROUTES.RECORDS);
      }
      const trip = snap.val();
      if (this._isMounted) {
        this.setState({
          trip: {
            ...trip,
            tid: snap.key
          }
        });
      }
    })
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onSubmit = e => {
    e.preventDefault();
    const { trip } = this.state;

    this.setState({ deleting: true }, () => {
      this.props.firebase.removeWayTripRecordAdmin(trip.tid, trip.driverId)
        .then(() => {
          NotificationManager.success('Way Trip Record removed');
          this.props.history.push(ROUTES.RECORDS_LIST.replace(':id', trip.driverId));
        })
        .catch(err => {
          NotificationManager.error(err.message);
        })
    })
  }

  render() {
    const { trip, deleting } = this.state;
    const id = this.props.match.params.id;
    var duration = 0;
    const t = this.props.t;

    if (trip && trip.startAt && trip.endAt) {
      // difference, if neg set to zero
      duration = (trip.endAt - trip.startAt) / (1000 * 60);
    }

    return (
      <React.Fragment>
        <h1>{t('waytrips.delete')}</h1>
        <Link to={ROUTES.WAY_RECORDS_EDIT.replace(':uid', trip.driverId).replace(':id', id)} className="topnav"><i className="fas fa-arrow-left fa-sm"></i> {t('form.backtoeditrecord')}</Link>
        <form className="customform waytripform" onSubmit={this.onSubmit}>
          <div className="month-section-record">
            <div className="month-block">
              <div>
                {moment(trip.date).format('ddd').toUpperCase()} <br />
                <span>{moment(trip.date).format('D')}</span>
              </div>
            </div>
            <div className="record-list">
              <div>
                <span>{trip.startLocation} <i className="fas fa-arrow-right fa-sm"></i> {trip.endLocation}</span>
                <br />{moment(trip.startAt, 'x').format('h:mma')} - {moment(trip.endAt, 'x').format('h:mma')}
              </div>
            </div>
          </div>
          <div className="record-detail">
            <p><span>{t('form.driver')}:</span> {trip.driver}</p>
            <p><span>{t('form.vehicle')}:</span> {trip.vehicle}</p>
            <p><span>{t('form.job')}:</span> {trip.job}</p>
            <p><span>{t('form.sitelocation')}:</span> {trip.startLocation} <i className="fas fa-arrow-right fa-sm"></i> {trip.endLocation}</p>
            <p><span>{t('form.company')}:</span> {trip.company}</p>
            <p><span>{t('form.ticket')}:</span> {trip.ticket}</p>
            <p><span>{t('form.remarks')}:</span> {trip.remarks}</p>
            <p><span>{t('form.startat')}:</span> {moment(trip.startAt, 'x').format('h:mma')}</p>
            <p><span>{t('form.endat')}:</span> {moment(trip.endAt, 'x').format('h:mma')}</p>
            <p className="note"><i className="far fa-clock fa-sm"></i> {t('form.totalhours')}: {(duration >= 60) && (parseInt(duration / 60) + ' hours')} {(duration % 60 > 0) && ((duration % 60) + ' minutes')}</p>
            <div className="totalpay">{t('form.amount')}: ${toDollar(trip.amount)}</div>
            {/* <p className="note">Entered By Tan Kok Meng (Supervisor)</p> */}
          </div>
          <center>
            <p>{t('form.deletewaytripconfirm')}</p>
            <button type="submit" disabled={deleting} className="warn" style={{ marginBottom: '20px' }}><i className="fas fa-trash-alt fa-md"></i> {deleting ? t('form.deleting') : t('form.deletewaytrip')}</button>{' '}
          </center>
        </form>
      </React.Fragment>
    );
  }
}

const condition = authUser => !!authUser && authUser.claims.role === ROLES.ADMIN;
export default withTranslate(withAuthorization(condition)(withFirebase(withFade(RecordsDelete))));