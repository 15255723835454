import React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { CSVLink } from "react-csv";

import Fade from 'react-reveal/Fade';
// import withFade from '../UI/withFade';
import { withTranslate } from '../Language';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { toDollar, recordNumber } from '../../constants/math';
import withAuthorization from '../Session/withAuthorization';
import Input from '../Form/Input';
import Actionbar from '../UI/Actionbar';

const moment = extendMoment(Moment);

const RecordsCalendarDay = ({ date, records, t }) => {
  var dayAmt = 0;
  let recordsList = [];
  date = moment(date);

  records.sort((a, b) => {
    if (a.startAt < b.startAt) return -1;
    if (a.startAt > b.startAt) return 1;
    return 0;
  });

  // using for instead of forEach, as we want to access the prev record directly in order to compare locations
  // records.forEach(record => {
  // });
  for(let i = 0; i < records.length; i++) {
    let record = records[i];
    dayAmt += record.amount;
    if (record.type === 'trip') {
      let isContinuing = false;
      // if only checking prevRecord:
      // let prevRecord = records[i - 1];
      // if(prevRecord && prevRecord.type === 'trip' && prevRecord.endLocation === record.startLocation) {
      //   isContinuing = true;
      // }
      if (record.amount < record.baseAmount) {
        isContinuing = true;
      }

      recordsList.push(<Link key={record.key} className={isContinuing ? 'trip continuing' : 'trip'} to={`${ROUTES.RECORDS}/${record.driverId}/${record.key}`}>
        <span>{record.startLocation} <i className="fas fa-arrow-right fa-sm"></i> {record.endLocation}</span>
        <br />{moment(record.startAt).format('h:mma')} - {moment(record.endAt).format('h:mma')}</Link>
      );
    } else if (record.type === 'wait') {
      recordsList.push(<Link key={record.key} className="wait" to={`${ROUTES.WAITING_RECORDS}/${record.driverId}/${record.key}`}>
        <span>{record.siteLocation} <i className="fas fa-hourglass-half fa-sm grey"></i></span>
        <br />{moment(record.startAt).format('h:mma')} - {moment(record.endAt).format('h:mma')}</Link>
      );
    } else if (record.type === 'dayjob') {
      recordsList.push(<Link key={record.key} className="dayjob" to={`${ROUTES.OTHER_RECORDS}/${record.driverId}/${record.key}`}>
        <span>{record.siteLocation} <i className="fas fa-calendar-day fa-sm grey"></i></span>
        <br />{moment(record.startAt).format('h:mma')} - {moment(record.endAt).format('h:mma')}</Link>
      );
    } else if (record.type === 'workshop') {
      recordsList.push(<Link key={record.key} className="workshop" to={`${ROUTES.WORKSHOP_RECORDS}/${record.driverId}/${record.key}`}>
        <span>{record.siteLocation} <i className="fas fa-tools fa-sm grey"></i></span>
        <br />{moment(record.startAt).format('h:mma')} - {moment(record.endAt).format('h:mma')}</Link>
      );
    } else if (record.type === 'waytrip') {
      recordsList.push(<Link key={record.key} className="waytrip" to={`${ROUTES.WAY_RECORDS}/${record.driverId}/${record.key}`}>
        <span>{record.startLocation} <i className="fas fa-directions fa-sm"></i> {record.endLocation}</span>
        <br />{moment(record.startAt).format('h:mma')} - {moment(record.endAt).format('h:mma')}</Link>
      );
    }
  }

  return (
    <div className="month-section-record">
      <div className="month-block">
        <div>
          {date.format('ddd').toUpperCase()} <br />
          <span>{date.format('D')}</span>
        </div>
      </div>
      <div className="record-list">
        {recordsList}
        <p>{t('totaldaily')}: ${toDollar(dayAmt)}</p>
      </div>
    </div>
  );
}

const RecordsCalendar = ({ records, t }) => {
  if (!records || !Object.keys(records).length)
    return <p>No records</p>;

  let groupedRecords = [];
  let monthAmt = 0;

  Object.keys(records).forEach(key => {
    let record = records[key];
    monthAmt += record.amount;

    if (!!groupedRecords[record.date]) {
      groupedRecords[record.date].push({
        ...record,
        key
      });
    } else {
      groupedRecords[record.date] = [{
        ...record,
        key
      }];
    }
  });

  // note due to named keys, this is an assoc array, which is an object in js, iterating by keys is not in correct order
  // sorting this object here thus, does not work as expected, instead we need to sort its keys in rendering
  // groupedRecords.sort();
  
  return (
    <React.Fragment>
      {Object.keys(groupedRecords).sort().map(key => (
        <RecordsCalendarDay key={key} date={key} records={groupedRecords[key]} t={t} />
      ))}
      <div className="totalpay">{t('totalmonthly')}: ${toDollar(monthAmt)}</div>
    </React.Fragment>
  );
}

const INITIAL_STATE = {
  loading: false,
  currentMonth: null,
  trips: null,
  waits: null,
  dayjobs: null,
  workshops: null,
  waytrips: null,
  driver: null
}

class RecordsListRecords extends React.Component {
  constructor(props) {
    super(props);

    const locState = props.location.state;
    const today = (locState && locState.fromDate) ? (new Date(locState.fromDate)) : (new Date());
    this.state = {
      ...INITIAL_STATE,
      currentMonth: moment(today).format('YYYY-MM')
    };

    this.tripRef = null;
    this.waitRef = null;
    this.jobRef = null;
    this.workshopRef = null;
    this.waytripRef = null;
  }

  componentDidMount() {
    this.props.firebase.user(this.props.match.params.id).on('value', snap => {
      this.setState({ driver: snap.val() });
    });
    this.updateList();
  }

  componentWillUnmount() {
    this.props.firebase.user(this.props.match.params.id).off();
    if (this.tripRef) this.tripRef.off();
    if (this.waitRef) this.waitRef.off();
    if (this.jobRef) this.jobRef.off();
    if (this.workshopRef) this.workshopRef.off();
    if (this.waytripRef) this.waytripRef.off();
  }

  onChangeMonth = e => {
    const currentMonth = e.target.value;
    if(currentMonth) { // do not allow empty selection
      this.setState({ currentMonth }, this.updateList);
    }
  }

  updateList = () => {
    this.setState({ loading: true }, () => {
      
      if (this.tripRef) this.tripRef.off();
      this.tripRef = this.props.firebase.trips(this.props.match.params.id).orderByChild('monthDriver').equalTo(this.state.currentMonth + this.props.match.params.id);
      this.tripRef.on('value', snap => {
        this.setState({ loading: false, trips: snap.val() });
      });

      if (this.waitRef) this.waitRef.off();
      this.waitRef = this.props.firebase.waits(this.props.match.params.id).orderByChild('monthDriver').equalTo(this.state.currentMonth + this.props.match.params.id);
      this.waitRef.on('value', snap => {
        this.setState({ loading: false, waits: snap.val() });
      });

      if (this.jobRef) this.jobRef.off();
      this.jobRef = this.props.firebase.dayjobs(this.props.match.params.id).orderByChild('monthDriver').equalTo(this.state.currentMonth + this.props.match.params.id);
      this.jobRef.on('value', snap => {
        this.setState({ loading: false, dayjobs: snap.val() });
      });

      if (this.workshopRef) this.workshopRef.off();
      this.workshopRef = this.props.firebase.workshops(this.props.match.params.id).orderByChild('monthDriver').equalTo(this.state.currentMonth + this.props.match.params.id);
      this.workshopRef.on('value', snap => {
        this.setState({ loading: false, workshops: snap.val() });
      });

      if (this.waytripRef) this.waytripRef.off();
      this.waytripRef = this.props.firebase.waytrips(this.props.match.params.id).orderByChild('monthDriver').equalTo(this.state.currentMonth + this.props.match.params.id);
      this.waytripRef.on('value', snap => {
        this.setState({ loading: false, waytrips: snap.val() });
      });

    });
  }

  render() {
    const { loading, currentMonth, driver, trips, waits, dayjobs, workshops, waytrips } = this.state;
    const csvData = [];

    if(trips) {
      Object.keys(trips).forEach(key => {
        trips[key] = {
          ...trips[key],
          type: 'trip',
          typeDisplay: 'Trip',
          startSiteLocation: trips[key].startLocation,
          startAtDisplay: moment(trips[key].startAt, 'x').format('HH:mm'),
          endAtDisplay: moment(trips[key].endAt, 'x').format('HH:mm'),
          recnoDisplay: trips[key].recno ? recordNumber(trips[key].recno) : key,
          createdAtDisplay: trips[key].createdAt ? moment(trips[key].createdAt, 'x').format('YYYY-MM-DD HH:mm') : '',
          amountDisplay: toDollar(trips[key].amount),
        }
        csvData.push(trips[key]);
      });
    }
    if(waits) {
      Object.keys(waits).forEach(key => {
        waits[key] = {
          ...waits[key],
          type: 'wait',
          typeDisplay: 'Waiting',
          startSiteLocation: waits[key].siteLocation,
          startAtDisplay: moment(waits[key].startAt, 'x').format('HH:mm'),
          endAtDisplay: moment(waits[key].endAt, 'x').format('HH:mm'),
          recnoDisplay: waits[key].recno ? recordNumber(waits[key].recno, 'wait') : key,
          createdAtDisplay: waits[key].createdAt ? moment(waits[key].createdAt, 'x').format('YYYY-MM-DD HH:mm') : '',
          amountDisplay: toDollar(waits[key].amount),
        }
        csvData.push(waits[key]);
      });
    }
    if (dayjobs) {
      Object.keys(dayjobs).forEach(key => {
        dayjobs[key] = {
          ...dayjobs[key],
          type: 'dayjob',
          typeDisplay: 'Day Job',
          startSiteLocation: dayjobs[key].siteLocation,
          startAtDisplay: moment(dayjobs[key].startAt, 'x').format('HH:mm'),
          endAtDisplay: moment(dayjobs[key].endAt, 'x').format('HH:mm'),
          recnoDisplay: dayjobs[key].recno ? recordNumber(dayjobs[key].recno, 'dayjob') : key,
          createdAtDisplay: dayjobs[key].createdAt ? moment(dayjobs[key].createdAt, 'x').format('YYYY-MM-DD HH:mm') : '',
          amountDisplay: toDollar(dayjobs[key].amount),
        }
        csvData.push(dayjobs[key]);
      });
    }
    if (workshops) {
      Object.keys(workshops).forEach(key => {
        workshops[key] = {
          ...workshops[key],
          type: 'workshop',
          typeDisplay: 'Workshop',
          startSiteLocation: workshops[key].siteLocation,
          startAtDisplay: moment(workshops[key].startAt, 'x').format('HH:mm'),
          endAtDisplay: moment(workshops[key].endAt, 'x').format('HH:mm'),
          recnoDisplay: workshops[key].recno ? recordNumber(workshops[key].recno, 'workshop') : key,
          createdAtDisplay: workshops[key].createdAt ? moment(workshops[key].createdAt, 'x').format('YYYY-MM-DD HH:mm') : '',
          amountDisplay: toDollar(workshops[key].amount),
        }
        csvData.push(workshops[key]);
      });
    }
    if (waytrips) {
      Object.keys(waytrips).forEach(key => {
        waytrips[key] = {
          ...waytrips[key],
          type: 'waytrip',
          typeDisplay: 'Way Trip',
          startSiteLocation: waytrips[key].startLocation,
          startAtDisplay: moment(waytrips[key].startAt, 'x').format('HH:mm'),
          endAtDisplay: moment(waytrips[key].endAt, 'x').format('HH:mm'),
          recnoDisplay: waytrips[key].recno ? recordNumber(waytrips[key].recno, 'waytrip') : key,
          createdAtDisplay: waytrips[key].createdAt ? moment(waytrips[key].createdAt, 'x').format('YYYY-MM-DD HH:mm') : '',
          amountDisplay: toDollar(waytrips[key].amount),
        }
        csvData.push(waytrips[key]);
      });
    }

    const records = { ...trips, ...waits, ...dayjobs, ...workshops, ...waytrips };
    const t = this.props.t;

    const csvHeaders = [
      { label: 'Record #', key: 'recnoDisplay' },
      { label: 'Date', key: 'date' },
      { label: 'Start Time', key: 'startAtDisplay' },
      { label: 'End Time', key: 'endAtDisplay' },
      { label: 'Record Type', key: 'typeDisplay' },
      { label: 'Driver', key: 'driver' },
      { label: 'Vehicle No', key: 'vehicle' },
      { label: 'Job Type', key: 'job' },
      { label: 'Company', key: 'company' },
      { label: 'Start/Site Location', key: 'startSiteLocation' },
      { label: 'End Location', key: 'endLocation' },
      { label: 'Distance (km)', key: 'distance' },
      { label: 'Amount ($)', key: 'amountDisplay' },
      { label: 'Ticket', key: 'ticket' },
      { label: 'Remarks', key: 'remarks' },
      { label: 'Created At', key: 'createdAtDisplay' },
      // { label: 'Created By', key: 'createdBy' }, // requires db ref to get the name; to reduce lookups we can get all and use a lookup locally
    ];

    csvData.sort((a, b) => {
      if(a.date === b.date) {
        return a.startAt - b.startAt;
      } else {
        if(a.date < b.date) return -1;
        return 1;
      }
    });

    return (
      <React.Fragment>
        <Fade>
          <div className="top">
            {driver
              ? <h1>{t('records.named', { name: driver.name })}</h1>
              : <h1>{t('records.driver')}</h1>
            }
            <form className="customform">
              <Input
                name="currentMonth"
                className=""
                labelText={t('date.month')}
                type="month"
                value={currentMonth}
                onChange={this.onChangeMonth}
              />
            </form>
            {loading
              ? <p>Loading ...</p>
              : <React.Fragment>
                <RecordsCalendar records={records} t={t} />
                {csvData.length > 0 && 
                  <center>
                    <p>
                      <CSVLink 
                        data={csvData} 
                        headers={csvHeaders} 
                        filename={`${driver.name}-${currentMonth}.csv`}
                        className="btn-3"
                      >
                        <i className="fas fa-file-csv fa-sm"></i> Export Records
                      </CSVLink>
                    </p>
                  </center>
                }
              </React.Fragment>
            }
          </div>
        </Fade>
        <Actionbar />
      </React.Fragment>
    );
  }
}

const condition = authUser => !!authUser && (authUser.claims.role === ROLES.ADMIN || authUser.claims.role === ROLES.SUPERVISOR);
export default withTranslate(withAuthorization(condition)(withFirebase((RecordsListRecords))));