import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { withAuthorization } from '../Session';
import * as ROUTES from '../../constants/routes';
import JobsList from './JobsList';
import JobsAdd from './JobsAdd';
import JobsEdit from './JobsEdit';
import JobsDelete from './JobsDelete';

const Jobs = () => (
  <div className="main">
    <div className="main-wrapper">
      <div className="top">
        <Switch>
          <Route exact path={ROUTES.JOBS_ADD} component={JobsAdd} />
          <Route exact path={ROUTES.JOBS_DELETE} component={JobsDelete} />
          <Route exact path={ROUTES.JOBS_EDIT} component={JobsEdit} />
          <Route exact path={ROUTES.JOBS} component={JobsList} />
        </Switch>
      </div>
    </div>
  </div>

);

const condition = authUser => !!authUser; 
export default withAuthorization(condition)(Jobs);