import React from 'react';
import { Switch, Route } from 'react-router-dom';

import * as ROUTES from '../../constants/routes';
import Notices from './Notices';
import Charts from './Charts';

const Dashboard = () => (
  <div className="main">
    <div className="main-wrapper">
      <Switch>
        <Route exact path={ROUTES.CHARTS} component={Charts} />
        <Route path="*" component={Notices} />
      </Switch>
    </div>
  </div>
);

export default Dashboard;