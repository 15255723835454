import React from 'react';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import { withTranslate } from '../Language';
import withFade from '../UI/withFade';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import Input from '../Form/Input';
import '../Form/CustomForm.css';

const INITIAL_STATE = {
  deleting: false,
  company: null,
}

class CompaniesDelete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
      ...props.location.state,
    };

  }

  componentDidMount() {
    if (!this.state.company) {
      this.props.history.push(`${ROUTES.COMPANIES}/${this.props.match.params.id}`);
    }
  }

  onSubmit = e => {
    e.preventDefault();

    this.setState({ deleting: true }, () => {
      this.props.firebase.company(this.state.company.cid).remove()
        .then(() => {
          NotificationManager.success('Company removed');
          this.props.history.push(ROUTES.COMPANIES);
        })
        .catch(err => {
          NotificationManager.error(err.message);
        })
    })
  }

  render() {
    const { company, deleting } = this.state;
    const id = this.props.match.params.id;
    const t = this.props.t;

    return (
      <React.Fragment>
        <h1>{t('companies.delete')}</h1>
        <Link to={`${ROUTES.COMPANIES}/${id}`} className="topnav"><i className="fas fa-arrow-left fa-sm"></i> {t('form.backtocompany')}</Link>
        <form className="customform companyform" onSubmit={this.onSubmit}>
          <Input
            name="name"
            className=""
            labelText={t('companies.name')}
            type="text"
            value={company.name}
            disabled={true}
          />
          <center>
            <p>{t('form.deletecompanyconfirm')}</p>
            <button type="submit" disabled={deleting} className="warn" style={{ marginBottom: '20px' }}><i className="fas fa-trash-alt fa-md"></i> {deleting ? t('form.deleting') : 'Delete Company'}</button>{' '}
          </center>
        </form>
      </React.Fragment>
    );
  }
}

const condition = authUser => !!authUser && authUser.claims.role === ROLES.ADMIN;
export default withTranslate(withAuthorization(condition)(withFirebase(withFade(CompaniesDelete))));