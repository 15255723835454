import React from 'react';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

import { withFirebase } from '../Firebase';
import { withAuthorization, AuthUserContext } from '../Session';
import { withTranslate } from '../Language';
import withFade from '../UI/withFade';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import Input from '../Form/Input';
import FormValidator from '../Form/FormValidator';
// import Geocode from '../Geocode';
import '../Form/CustomForm.css';

const INITIAL_STATE = {
  saving: false,
  form: {
    name: '',
    address: ''
  },
}

class LocationsAdd extends React.Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: 'name',
        method: 'isEmpty',
        validWhen: false,
        message: 'Name is required.'
      }
    ]);

    this.state = { 
      ...INITIAL_STATE, 
      validation: this.validator.valid()
    };

    this.submitted = false;
  }

  onSubmit = e => {
    e.preventDefault();
    const validForm = this.checkValidForm();
    const { form } = this.state;
    this.submitted = true;

    if(validForm) {
      this.setState({ saving: true }, () => {
        this.props.firebase.db.ref('/locations').push().set({
          name: form.name,
          address: form.address,
          createdAt: this.props.firebase.ServerValue.TIMESTAMP,
          createdBy: this.context.uid
        })
          .then(() => {
            NotificationManager.success('Location created');
            this.props.history.push(ROUTES.LOCATIONS);
          })
          .catch(err => {
            NotificationManager.error(err.message);
          });
      });

      // Geocode.fromAddress(form.address)
      //   .then(
      //     response => {
      //       const { lat, lng } = response.results[0].geometry.location;
      //       return { lat, lng };
      //     },
      //     error => {
      //       // console.error(error);
      //       return { lat: null, lng: null };
      //     }
      //   )
      //   .then(({ lat, lng }) => {
      //     // push here if using geocode
      //   });
    } else {
      console.log('Invalid submission');
    }
  }

  onChange = e => {
    const form = Object.assign({}, this.state.form);
    form[e.target.name] = e.target.value;
    this.setState({ form });
  }

  onResetForm = e => {
    e.preventDefault();
    this.setState({
      ...INITIAL_STATE,
      validation: this.validator.valid()
    });
    this.submitted = false;
  }

  checkValidForm = () => {
    const validation = this.validator.validate(this.state.form);
    this.setState({ validation: validation });
    return validation.isValid;
  }

  render() {
    const { form, saving } = this.state;
    const validation = this.submitted ? this.validator.validate(form) : this.state.validation;
    const t = this.props.t;

    return (
      <React.Fragment>
        <h1>{t('locations.add')}</h1>
        <Link to={ROUTES.LOCATIONS} className="topnav"><i className="fas fa-arrow-left fa-sm"></i> Back to Locations</Link>
        <form className="customform locationform" onSubmit={this.onSubmit}>
          <Input
            name="name"
            className=""
            labelText={t('name')}
            type="text"
            value={form.name}
            invalid={validation.name.isInvalid}
            message={validation.name.message}
            onChange={this.onChange}
          />
          <Input
            name="address"
            className=""
            labelText={t('address')}
            type="text"
            value={form.address}
            onChange={this.onChange}
          />
          <button type="submit" className="positive" disabled={saving}><i className="fas fa-save fa-md"></i> {saving ? t('form.saving') : 'Create Location'}</button>{' '}
          <button onClick={this.onResetForm} disabled={saving}><i className="fas fa-times fa-md"></i> {t('form.reset')}</button>
        </form>
      </React.Fragment>
    );
  }
}

LocationsAdd.contextType = AuthUserContext;

const condition = authUser => !!authUser && authUser.claims.role === ROLES.ADMIN;
export default withTranslate(withAuthorization(condition)(withFirebase(withFade(LocationsAdd))));