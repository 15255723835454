import React from 'react';
import english from './english.json';
import tamil from './tamil.json';
import { AuthUserContext } from '../Session/index.js';
import { withFirebase } from '../Firebase/index.js';

const LanguageContext = React.createContext();
const LanguageConsumer = LanguageContext.Consumer;

const TranslatableText = props => ( // anytime a piece of text needs to have translation, wrap the text in this component and the right one will render
  <LanguageConsumer>
    {({ language }) => props.dictionary[language]}
  </LanguageConsumer>
);

const withTranslate = Component => props => ( // only interested in passing t function atm
  <LanguageConsumer>
    {({ t }) => <Component {...props} t={t} />}
  </LanguageConsumer>
);

class LanguageProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      language: 'english',
      mydictionary: { english, tamil }
    }
  }

  componentDidMount() {
    /*
    use authlisteners here since the user may not have logged in yet at component load (firebase is async) so will not have this.context.uid available yet
    */
    this.listener = this.props.firebase.onAuthUserListener(
      authUser => {
        this.props.firebase.preflang(authUser.uid).once('value', snap => {
          if (snap.exists()) {
            let language = snap.val();
            this.setState({ language });
          }
        });
      },
      () => {
        this.setState({ language: 'english' });
      }
    );
  }

  componentWillUnmount() {
    this.listener(); // onAuthStateChanged returns an unsubscribe function, calling which removes the listener
  }

  updateLanguage = lang => e => { // currying for the extra param passing
    e.preventDefault();
    return this.setState({ language: lang }, () => {
      this.props.firebase.preflang(this.context.uid).set(lang);
    });
  }

  t = (path, args) => {
    const translationKeys = path.split('.');
    let translation = '';
    let transObj = this.state.mydictionary[this.state.language];

    translationKeys.forEach(key => {
      const temp = transObj[key];
      if (typeof temp === 'object') { // some nested stuff
        transObj = transObj[key]; // move inside the object
      }
      if (typeof temp === 'string') { // final item, no nesting
        translation = temp;
      }
    });

    if (!translation) return path; // return original if not found

    if (args) { // object containing params
      Object.keys(args).forEach(key => {
        translation = translation.replace(`{${key}}`, args[key]);
      });
    }

    return translation;

  }

  render() {
    return (
      <LanguageContext.Provider
        value={{
          language: this.state.language,
          updateLanguage: this.updateLanguage,
          t: this.t
        }}
      >
        {this.props.children}
      </LanguageContext.Provider>
    );
  }
}

LanguageProvider.contextType = AuthUserContext;
LanguageProvider = withFirebase(LanguageProvider);
export {
  LanguageProvider,
  LanguageConsumer,
  TranslatableText,
  withTranslate
};
