import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { withAuthorization } from '../Session';
import * as ROUTES from '../../constants/routes';
import VehiclesList from './VehiclesList';
import VehiclesAdd from './VehiclesAdd';
import VehiclesEdit from './VehiclesEdit';
import VehiclesDelete from './VehiclesDelete';

const Vehicles = () => (
  <div className="main">
    <div className="main-wrapper">
      <div className="top">
        <Switch>
          <Route exact path={ROUTES.VEHICLES_ADD} component={VehiclesAdd} />
          <Route exact path={ROUTES.VEHICLES_DELETE} component={VehiclesDelete} />
          <Route exact path={ROUTES.VEHICLES_EDIT} component={VehiclesEdit} />
          <Route exact path={ROUTES.VEHICLES} component={VehiclesList} />
        </Switch>
      </div>
    </div>
  </div>

);

const condition = authUser => !!authUser; 
export default withAuthorization(condition)(Vehicles);
