import React from 'react';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import withFade from '../UI/withFade';
import { withTranslate } from '../Language';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { toDollar } from '../../constants/math';
import { withAuthorization, AuthUserContext } from '../Session';
import Input from '../Form/Input';

const moment = extendMoment(Moment);

const RecordsCalendarDay = ({ date, records, t }) => {
  var dayAmt = 0;
  let recordsList = [];
  date = moment(date);

  records.sort((a, b) => {
    if (a.startAt < b.startAt) return -1;
    if (a.startAt > b.startAt) return 1;
    return 0;
  });

  // using for instead of forEach, as we want to access the prev record directly in order to compare locations
  // records.forEach(record => {});
  for (let i = 0; i < records.length; i++) {
    let record = records[i];
    dayAmt += record.amount;
    if (record.type === 'trip') {
      let isContinuing = false;
      // if only checking prevRecord:
      // let prevRecord = records[i - 1];
      // if (prevRecord && prevRecord.type === 'trip' && prevRecord.endLocation === record.startLocation) {
      //   isContinuing = true;
      // }
      if (record.amount < record.baseAmount) {
        isContinuing = true;
      }

      recordsList.push(<Link key={record.key} className={isContinuing ? 'trip continuing' : 'trip'} to={`${ROUTES.RECORDS}/${record.driverId}/${record.key}`}>
        <span>{record.startLocation} <i className="fas fa-arrow-right fa-sm"></i> {record.endLocation}</span>
        <br />{moment(record.startAt).format('h:mma')} - {moment(record.endAt).format('h:mma')}</Link>
      );
    } else if (record.type === 'wait') {
      recordsList.push(<Link key={record.key} className="wait" to={`${ROUTES.WAITING_RECORDS}/${record.driverId}/${record.key}`}>
        <span>{record.siteLocation} <i className="fas fa-hourglass-half fa-sm grey"></i></span>
        <br />{moment(record.startAt).format('h:mma')} - {moment(record.endAt).format('h:mma')}</Link>
      );
    } else if (record.type === 'dayjob') {
      recordsList.push(<Link key={record.key} className="dayjob" to={`${ROUTES.OTHER_RECORDS}/${record.driverId}/${record.key}`}>
        <span>{record.siteLocation} <i className="fas fa-calendar-day fa-sm grey"></i></span>
        <br />{moment(record.startAt).format('h:mma')} - {moment(record.endAt).format('h:mma')}</Link>
      );
    } else if (record.type === 'workshop') {
      recordsList.push(<Link key={record.key} className="workshop" to={`${ROUTES.WORKSHOP_RECORDS}/${record.driverId}/${record.key}`}>
        <span>{record.siteLocation} <i className="fas fa-tools fa-sm grey"></i></span>
        <br />{moment(record.startAt).format('h:mma')} - {moment(record.endAt).format('h:mma')}</Link>
      );
    } else if (record.type === 'waytrip') {
      recordsList.push(<Link key={record.key} className="waytrip" to={`${ROUTES.WAY_RECORDS}/${record.driverId}/${record.key}`}>
        <span>{record.startLocation} <i className="fas fa-directions fa-sm"></i> {record.endLocation}</span>
        <br />{moment(record.startAt).format('h:mma')} - {moment(record.endAt).format('h:mma')}</Link>
      );
    }
  }
  
  return (
    <div className="month-section-record">
      <div className="month-block">
        <div>
          {date.format('ddd').toUpperCase()} <br />
          <span>{date.format('D')}</span>
        </div>
      </div>
      <div className="record-list">
        {recordsList}
        <p>{t('totaldaily')}: ${toDollar(dayAmt)}</p>
      </div>
    </div>
  );
}

const RecordsCalendar = ({ records, t }) => {
  if (!records || !Object.keys(records).length)
    return <p>No records</p>;

  let groupedRecords = [];
  let monthAmt = 0;

  Object.keys(records).forEach(key => {
    let record = records[key];
    monthAmt += record.amount;

    if (!!groupedRecords[record.date]) {
      groupedRecords[record.date].push({
        ...record,
        key
      });
    } else {
      groupedRecords[record.date] = [{
        ...record,
        key
      }];
    }
  });

  // note due to named keys, this is an assoc array, which is an object in js, iterating by keys is not in correct order
  // sorting this object here thus, does not work as expected, instead we need to sort its keys in rendering
  // groupedRecords.sort();

  return (
    <React.Fragment>
      {Object.keys(groupedRecords).sort().map(key => (
        <RecordsCalendarDay key={key} date={key} records={groupedRecords[key]} t={t} />
      ))}
      <div className="totalpay">{t('totalmonthly')}: ${toDollar(monthAmt)}</div>
    </React.Fragment>
  );
}

const INITIAL_STATE = {
  loading: false,
  currentMonth: null,
  trips: null,
  waits: null,
  dayjobs: null,
  workshops: null,
  waytrips: null,
}

class RecordsListDriver extends React.Component {
  constructor(props) {
    super(props);
    
    const today = new Date();
    this.state = {
      ...INITIAL_STATE,
      currentMonth: moment(today).format('YYYY-MM')
    };

    this.tripRef = null;
    this.waitRef = null;
    this.jobRef = null;
    this.workshopRef = null;
    this.waytripRef = null;
  }

  componentDidMount() {
    this.updateList();
  }

  componentWillUnmount() {
    if (this.tripRef) this.tripRef.off();
    if (this.waitRef) this.waitRef.off();
    if (this.jobRef) this.jobRef.off();
    if (this.workshopRef) this.workshopRef.off();
    if (this.waytripRef) this.waytripRef.off();
  }

  onChangeMonth = e => {
    const currentMonth = e.target.value;
    if (currentMonth) { // do not allow empty selection
      this.setState({ currentMonth }, this.updateList);
    }
  }

  updateList = () => {
    const userContext = this.context;
    this.setState({ loading: true }, () => {
      if(this.tripRef) this.tripRef.off(); // turn off as listener address is changed now
      this.tripRef = this.props.firebase.trips(userContext.uid).orderByChild('monthDriver').equalTo(this.state.currentMonth + userContext.uid);
      this.tripRef.on('value', snap => {
        this.setState({ loading: false, trips: snap.val() });
      });

      if (this.waitRef) this.waitRef.off();
      this.waitRef = this.props.firebase.waits(userContext.uid).orderByChild('monthDriver').equalTo(this.state.currentMonth + userContext.uid);
      this.waitRef.on('value', snap => {
        this.setState({ loading: false, waits: snap.val() });
      });

      if (this.jobRef) this.jobRef.off();
      this.jobRef = this.props.firebase.dayjobs(userContext.uid).orderByChild('monthDriver').equalTo(this.state.currentMonth + userContext.uid);
      this.jobRef.on('value', snap => {
        this.setState({ loading: false, dayjobs: snap.val() });
      });

      if (this.workshopRef) this.workshopRef.off();
      this.workshopRef = this.props.firebase.workshops(userContext.uid).orderByChild('monthDriver').equalTo(this.state.currentMonth + userContext.uid);
      this.workshopRef.on('value', snap => {
        this.setState({ loading: false, workshops: snap.val() });
      });

      if (this.waytripRef) this.waytripRef.off();
      this.waytripRef = this.props.firebase.waytrips(userContext.uid).orderByChild('monthDriver').equalTo(this.state.currentMonth + userContext.uid);
      this.waytripRef.on('value', snap => {
        this.setState({ loading: false, waytrips: snap.val() });
      });
    });
  }

  render() {
    const { loading, currentMonth, trips, waits, dayjobs, workshops, waytrips } = this.state;
    const t = this.props.t;

    if (trips) {
      Object.keys(trips).forEach(key => {
        trips[key] = {
          ...trips[key],
          type: 'trip'
        }
      });
    }
    if (waits) {
      Object.keys(waits).forEach(key => {
        waits[key] = {
          ...waits[key],
          type: 'wait'
        }
      });
    }
    if (dayjobs) {
      Object.keys(dayjobs).forEach(key => {
        dayjobs[key] = {
          ...dayjobs[key],
          type: 'dayjob'
        }
      });
    }
    if (workshops) {
      Object.keys(workshops).forEach(key => {
        workshops[key] = {
          ...workshops[key],
          type: 'workshop'
        }
      });
    }
    if (waytrips) {
      Object.keys(waytrips).forEach(key => {
        waytrips[key] = {
          ...waytrips[key],
          type: 'waytrip'
        }
      });
    }

    const records = { ...trips, ...waits, ...dayjobs, ...workshops, ...waytrips };

    return (
      <React.Fragment>
        <h1>{t('records.my')}</h1>
        <form className="customform">
          <Input
            name="currentMonth"
            className=""
            labelText={t('date.month')}
            type="month"
            value={currentMonth}
            onChange={this.onChangeMonth}
          />
        </form>
        {loading
          ? <p>Loading ...</p>
          : <RecordsCalendar records={records} t={t} />
        }
      </React.Fragment>
    );
  }
}

RecordsListDriver.contextType = AuthUserContext;

const condition = authUser => !!authUser && (authUser.claims.role === ROLES.DRIVER);
export default withTranslate(withAuthorization(condition)(withFirebase(withFade(RecordsListDriver))));
