export const GOOGLE_API = "AIzaSyCgm3Q-AGQ9elJCzsl0yifcNx6FENZod6w";
export const SENDER_ID = "1053996606271";
export const DOMAIN = "sing-heng-hoe.firebaseapp.com";
export const DB_URL = "https://sing-heng-hoe.firebaseio.com";
export const PROJECT_ID = "sing-heng-hoe";
export const BUCKET_ID = "sing-heng-hoe.appspot.com";

export const DEV_GOOGLE_API = "AIzaSyCN8PUx67YA4xvBL7XnJPfWGgogK9phyxo";
export const DEV_SENDER_ID = "837450572005";
export const DEV_DOMAIN = "shh-dps-demo.firebaseapp.com";
export const DEV_DB_URL = "https://shh-dps-demo.firebaseio.com";
export const DEV_PROJECT_ID = "shh-dps-demo";
export const DEV_BUCKET_ID = "shh-dps-demo.appspot.com";