import React from 'react';
import { Link } from 'react-router-dom';

// import { TranslatableText as TT } from '../Language';
import { AuthUserContext } from '../Session';

import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { withTranslate } from '../Language';

class Actionbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMouseDown: false,
      startX: 0,
      scrollLeft: 0
    };
    this.sliderRef = React.createRef();
  }

  onMouseDown = e => {
    this.setState({ 
      isMouseDown: true,
      startX: e.pageX - this.sliderRef.current.offsetLeft,
      scrollLeft: this.sliderRef.current.scrollLeft
    });
  }

  onMouseUp = e => {
    this.setState({ isMouseDown: false });
  }

  onMouseMove = e => {
    if(!this.state.isMouseDown) return;
    e.preventDefault();
    const x = e.pageX - this.sliderRef.current.offsetLeft;
    const walk = (x - this.state.startX) * 1; // increase speed by increasing this multiplier
    this.sliderRef.current.scrollLeft = this.state.scrollLeft - walk;
  }

  render() {
    const { t } = this.props;

    return (
      <AuthUserContext.Consumer>
        {authUser =>
          <div className="fixed-bottom">
            <div className={(authUser.claims.role === ROLES.ADMIN || authUser.claims.role === ROLES.SUPERVISOR) ? 'quick-link admin-quick-link' : 'quick-link'}
              onMouseDown={this.onMouseDown} onMouseUp={this.onMouseUp} onMouseLeave={this.onMouseUp} onMouseMove={this.onMouseMove}
              ref={this.sliderRef}
            >
              <div>
                <Link to={ROUTES.RECORDS_ADD}>
                  <i className="fas fa-route fa-2x"></i><br />
                  {t('trip')}
                </Link>
              </div>
              {(authUser.claims.role === ROLES.ADMIN || authUser.claims.role === ROLES.SUPERVISOR)
                ? <React.Fragment>
                  <div>
                    <Link to={ROUTES.WAITING_RECORDS_ADD}>
                      <i className="fas fa-hourglass-half fa-2x yellow-dark"></i><br />
                      {t('waiting')}
                    </Link>
                  </div>
                  <div>
                    <Link to={ROUTES.OTHER_RECORDS_ADD}>
                      <i className="fas fa-calendar-day fa-2x red-dark"></i><br />
                      {t('dayjob')}
                    </Link>
                  </div>
                </React.Fragment>
                : null
              }
              <div>
                <Link to={ROUTES.WAY_RECORDS_ADD}>
                  <i className="fas fa-directions fa-2x blue-dark"></i><br />
                  {t('waytrip')}
                </Link>
              </div>
              {(authUser.claims.role === ROLES.ADMIN || authUser.claims.role === ROLES.SUPERVISOR)
                ? <div>
                  <Link to={ROUTES.WORKSHOP_RECORDS_ADD}>
                    <i className="fas fa-tools fa-2x green-dark"></i><br />
                    {t('workshop')}
                  </Link>
                </div>
                : null
              }
            </div>
          </div>
        }
      </AuthUserContext.Consumer>
    );
  }
}

// const Actionbarx = ({ t }) => ();

export default withTranslate(Actionbar);
