export const toDollar = cents => {
  return (Number.parseFloat(cents) / 100).toFixed(2);
};

export const toFBKey = val => {
  // .$[]#/
  // note in a character class (square bracket for range []), only ^-]\ needs to be escaped; so a '.' means a dot, not 'any character' as outside []
  // return val.replace(/[\.$[\]#/\s]/g, '');
  return val.replace(/[.$[\]#/\s]/g, '').toLowerCase();
};

export const recordNumber = (i, type) => {
  let prefix = 'T';
  if(type === 'wait') { 
    prefix = 'W'; 
  } else if (type === 'dayjob') {
    prefix = 'J';
  } else if (type === 'workshop') {
    prefix = 'WS';
  } else if (type === 'waytrip') {
    prefix = 'TW';
  }

  return prefix + String(i).padStart(6, '0');
}