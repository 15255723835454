import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { withAuthorization } from '../Session';
import * as ROUTES from '../../constants/routes';
import RecordAdd from './RecordsAdd';
import RecordsEdit from './RecordsEdit';
import RecordsView from './RecordsView';
import RecordsDelete from './RecordsDelete';

const Records = () => (
  <div className="main">
    <div className="main-wrapper">
      <Switch>
        <Route exact path={ROUTES.OTHER_RECORDS_ADD} component={RecordAdd} />
        <Route exact path={ROUTES.OTHER_RECORDS_EDIT} component={RecordsEdit} />
        <Route exact path={ROUTES.OTHER_RECORDS_DELETE} component={RecordsDelete} />
        <Route exact path={ROUTES.OTHER_RECORDS_VIEW} component={RecordsView} />
      </Switch>
    </div>
  </div>
);

const condition = authUser => !!authUser; 
export default withAuthorization(condition)(Records);
