import React from 'react';
import { Link } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import mapsapi from 'google-maps-api';

import withFade from '../UI/withFade';
import { withTranslate } from '../Language';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import { GOOGLE_API } from '../../constants/keys';
import { AuthUserContext } from '../Session';
import BaseTable, { Column, AutoResizer } from 'react-base-table';
import Input from '../Form/Input';
import FormValidator from '../Form/FormValidator';
import '../Form/CustomForm.css';
import 'react-base-table/styles.css';
import './matrix.css';
import './modal.css';

/*
20210209 - performance fix
  instead of matrix, just a table/list dropdown src+destination
  maybe autocomplete
  on choice, show distance table
  this should reduce listeners from 250x250

*/

const LocDistMatrix = ({ locdistances, handleOpen, highlighted, role }) => {
  if (!locdistances.length)
    return <p>No data</p>;

  var data = [];
  var columns = [{
    key: 'col-0',
    dataKey: 'col-0',
    title: '',
    width: 250,
    frozen: Column.FrozenDirection.LEFT
  }];

  locdistances.forEach((col, cindex) => {
    columns.push({
      key: `col-${cindex + 1}`,
      dataKey: `col-${cindex + 1}`,
      title: col.name,
      width: 65
    });

    var row = {
      id: `row-${cindex}`,
      parentId: null,
      'col-0': { name: col.name, y: col.key }
    };
    col.distances.forEach((item, index) => {
      if (item.key === col.key) {
        row[`col-${index + 1}`] = { name: '', x: item.key, y: item.key };
      } else {
        row[`col-${index + 1}`] = { name: item.distance, x: item.key, y: col.key, tooltip: `${col.name} » ${item.name}` };
      }
    });
    data.push(row);
  });

  const CustomHeaderCell = ({ className, column }) => (
    <div className={className + ' text-smaller'}><span title={column.title}>{column.title}</span></div>
  )

  const CustomCell = ({ className, cellData, rowIndex, columnIndex }) => {
    if (columnIndex === 0) {
      return <div
        className={className + ' text-smaller'}
      >
        <Link to={`${ROUTES.LOCATIONS}/points/${cellData.y}`} title={cellData.name}>{cellData.name}</Link>
      </div>
    }

    if (rowIndex === columnIndex - 1) {
      className += ' no-data';
    } else {
      className += ' with-data';
    }

    // 20210209 - temp disable for performance
    // if (highlighted && ((highlighted.x === cellData.x && highlighted.y === cellData.y) || (highlighted.y === cellData.x && highlighted.x === cellData.y))) {
    //   className += ' highlighted';
    // }

    if (role === ROLES.ADMIN) {
      // xxxyyy somehow null is passed here from TableCell prop; new change? check how many TableCell calls are done
      // xxxyyy could it be the location is deleted, but the distance grid remains? -Lwm3lVmDUmTBd-0zZkU
      // xxxyyy 2 issues - node should delete that distance on removal of location; grid should only render on existance of location
      // xxxyyy quickfix - figure out the offending data and delete them
      // xxxyyy node only deleted that entry from distances/abc/abc, but not distances/xyz/abc, nor distances/abc/*
      /* xxxyyy todo:
        1. ensure only data from valid locations is rendered
        2. verify onlocationremoved listener works in node
      */
      if (!cellData) {
        return <div
          className={className}
        >
          (NA)
        </div>
      }
      
      return <div
        className={className}
        onClick={handleOpen(cellData.x, cellData.y)}
        title={cellData.tooltip}
      >
        {cellData.name}
      </div>
    }

    return <div
      className={className}
      title={cellData.tooltip}
    >
      {cellData.name}
    </div>
  }

  return (
    <div className="MatrixTbl" style={{ width: '100%', height: '600px' }}>
      <AutoResizer>
        {({ width, height }) => (
          <BaseTable
            fixed
            width={width}
            height={height}
            data={data}
            columns={columns}
            components={{
              TableCell: CustomCell,
              TableHeaderCell: CustomHeaderCell
            }}
            headerHeight={150}
          />
        )}
      </AutoResizer>
    </div>
  );
}

const Modal = ({ show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";

  return (
    <div className={showHideClassName}>
      <div className="modal-bg"></div>
      <div className="modal-main">
        {children}
      </div>
    </div>
  );
}

const INITIAL_STATE = {
  loading: false,
  locdistances: [],
  show: false,
  modalLoading: false,
  form: null,
  saving: false,
  highlighted: null,
  matrixService: null,
  estimatedDistance: '-'
}

class LocationsList extends React.Component {
  constructor(props) {
    super(props);

    this.validator = new FormValidator([
      {
        field: 'distance',
        method: 'isEmpty',
        validWhen: false,
        message: 'Distance is required.'
      },
      {
        field: 'distance',
        method: 'isNumeric',
        validWhen: true,
        message: 'Distance is invalid.'
      }
    ]);

    this.state = {
      ...INITIAL_STATE,
      validation: this.validator.valid()
    };

    this._isMounted = false;
    this.submitted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({ loading: true });

    var nameSort = (a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    };

    // need .on here instead of .once, when adding location, the server listener for distance insert may not update immediately
    // SO maybe if we swap distance with location, we can attach .on to distance instead?
    var tmpLocKeys = [];
    this.props.firebase.locations().once('value').then(locSnap => {
      locSnap.forEach(item => {
        tmpLocKeys.push(item.key);
      });
    }).then(() => {
      this.props.firebase.distances().on('value', dstSnap => {
        let dstValues = [];
        dstSnap.forEach(item => {
          if (tmpLocKeys.indexOf(item.key) === -1) {
            return; // only show if in actual locations, phantom distances records
          }
          let dstValue = item.val();

          dstValue = Object.keys(dstValue).filter(k => (tmpLocKeys.indexOf(k) > -1)).map(k => ({ ...dstValue[k], key: k })); // convert to array and embed key as prop
          // dstValue = Object.keys(dstValue).map(k => ({ ...dstValue[k], key: k })); // convert to array and embed key as prop
          let me = dstValue.find(obj => (obj.key === item.key)); // find that child that is the self node, contains name for use
          let name = (me !== undefined) ? me.name : item.key;

          dstValues.push({
            name: name,
            key: item.key,
            distances: dstValue.sort(nameSort)
          });
        });
  
        this.setState({
          loading: false,
          locdistances: dstValues.sort(nameSort)
        });
      });
    });

    /*
    this.props.firebase.locations().once('value').then(locSnap => {
      let locValues = [];
      // using promises.all to ensure all data is returned before continuing
      let promises = [];
      locSnap.forEach(item => {
        var locValue = item.val();
        // console.log('loc', locValue.name, item.key);
        var p = this.props.firebase.distance(item.key).once('value').then(disSnap => {
          var disValues = disSnap.val();
          // console.log('dist', item.key)
          if(disValues) {
            disValues = Object.keys(disValues).map(k => ({ ...disValues[k], key: k }));
            // convert to array for sortable
            locValues.push({
              ...locValue,
              key: item.key,
              distances: disValues.sort(nameSort)
            });
          }
        });
        promises.push(p);
      });

      this.allPromises = Promise.all(promises).then(() => {
        // console.log('prom')
        if(this._isMounted) {
          // console.log('setstate', locValues.length);
          this.setState({ 
            loading: false, 
            locdistances: locValues.sort(nameSort)
          });
        }
      });

    });
    */

    return mapsapi(GOOGLE_API)()
      .then(maps => {
        const matrixService = new maps.DistanceMatrixService();
        if (this._isMounted) {
          this.setState({ matrixService })
        }
      });

  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.firebase.distances().off();
  }

  showModal = (x, y) => e => {
    e.preventDefault();
    this.setState({ show: true, modalLoading: true, estimatedDistance: '-' }, () => {

      let { locdistances } = this.state;
      let yItem = locdistances.find(item => (item.key === y));
      if (!yItem) return;

      let distanceItem = yItem.distances.find(item => (item.key === x));

      if (this._isMounted) {
        this.setState({
          modalLoading: false,
          form: {
            y: yItem.key,
            yName: yItem.name,
            x: distanceItem.key,
            xName: distanceItem.name,
            distance: distanceItem.distance
          }
        });
      }
      // dun need to fetch from db, we have a copy in our locdistance grid!
      // this.props.firebase.distance(x).child(y).once('value')
      //   .then(snap => {
      //     const distance = snap.val();
      //     console.log('loaded', x, y, distance);
      //     if(this._isMounted) {
      //       this.setState({
      //         modalLoading: false,
      //         form: {
      //           ...distance,
      //           x,
      //           y,
      //           xName: distance.name,
      //           yName: '',
      //         }
      //       });
      //     }
      //   })
    });
  }

  hideModal = e => {
    e.preventDefault();
    this.setState({ show: false, form: null, validation: this.validator.valid(), highlighted: null });
    this.submitted = false;
  }

  submitModal = e => {
    e.preventDefault();
    const validForm = this.checkValidForm();
    const { form } = this.state;
    this.submitted = true;

    if (validForm) {
      this.setState({ saving: true }, () => {
        let update = {};
        form.distance = parseFloat(form.distance);

        update[`${form.x}/${form.y}/distance`] = form.distance;
        update[`${form.y}/${form.x}/distance`] = form.distance;

        this.props.firebase.distances().update(update)
          .then(() => {
            this.submitted = false;
            this.setState({ show: false, form: null, saving: false, validation: this.validator.valid(), highlighted: { x: form.x, y: form.y } });
            NotificationManager.success('Distance updated');
          })
          .catch(err => {
            this.setState(prevstate => ({
              saving: false
            }));
            NotificationManager.error(err.message);
          });
      });
    } else {
      console.log('Invalid submission');
    }
  }

  estimateDistance = e => {
    e.preventDefault();
    const { form, matrixService } = this.state;
    let start = null,
      end = null;

    this.setState({ estimatedDistance: -1 }, () => {
      return this.props.firebase.location(form.y).once('value')
        .then(snap => {
          if (snap.exists()) start = snap.val();
          return this.props.firebase.location(form.x).once('value');
        })
        .then(snap => {
          if (snap.exists()) end = snap.val();
          if (start && end && start.address && end.address) {
            matrixService.getDistanceMatrix({
              origins: [start.address],
              destinations: [end.address],
              travelMode: 'DRIVING',
            },
              (res, status) => {
                if (status === 'OK' && res.rows[0].elements[0].status === 'OK' && this._isMounted) {
                  this.setState({ estimatedDistance: res.rows[0].elements[0].distance.text });
                } else {
                  this.setState({ estimatedDistance: 'N.A.' });
                }
              }
            );
          } else if (this._isMounted) {
            this.setState({ estimatedDistance: 'N.A.' });
          }
        });
    });

  }

  onChange = e => {
    const form = Object.assign({}, this.state.form);
    form[e.target.name] = e.target.value;
    this.setState({ form });
  }

  checkValidForm = () => {
    const validation = this.validator.validate(this.state.form);
    this.setState({ validation: validation });
    return validation.isValid;
  }

  render() {
    const { loading, locdistances, modalLoading, form, show, saving, highlighted, estimatedDistance } = this.state;
    let modalValidation = this.submitted ? this.validator.validate(form) : this.state.validation;
    const t = this.props.t;

    return (
      <React.Fragment>
        <h1>{t('locations.title')}</h1>
        <AuthUserContext.Consumer>
          {authUser =>
            <React.Fragment>
              {authUser && authUser.claims.role === ROLES.ADMIN &&
                <Link to={ROUTES.LOCATIONS_ADD} className="topnav"><i className="fas fa-plus fa-sm"></i> Add New Location</Link>
              }
              <div style={{ position: 'relative' }}>
                {loading
                  ? <p>Loading ...</p>
                  : <LocDistMatrix role={authUser.claims.role} locdistances={locdistances} handleOpen={this.showModal} highlighted={highlighted} />
                }
                <Modal show={show}>
                  {modalLoading
                    ? <p>Loading ...</p>
                    : form && <form className="customform distanceform" onSubmit={this.submitModal}>
                      <h2>
                        {form.yName} <i className="fas fa-arrows-alt-h fa-md"></i> {form.xName}
                      </h2>
                      <Input
                        name="distance"
                        className=""
                        labelText="Distance (km)"
                        type="text"
                        value={String(form.distance)}
                        invalid={modalValidation.distance.isInvalid}
                        message={modalValidation.distance.message}
                        onChange={this.onChange}
                      />
                      <button type="submit" className="positive" disabled={saving}><i className="fas fa-save fa-md"></i> {saving ? t('form.saving') : 'Save Changes'}</button>{' '}
                      <button onClick={this.estimateDistance} disabled={saving} className="info">
                        <i className="fas fa-map-marker-alt fa-md"></i>{' '}
                        {(estimatedDistance === -1) ? 'Fetching...' : `Map Estimate: ${estimatedDistance}`}
                      </button>{' '}
                      <button onClick={this.hideModal} disabled={saving}><i className="fas fa-times fa-md"></i> Close</button>
                    </form>
                  }
                </Modal>
              </div>
            </React.Fragment>
          }
        </AuthUserContext.Consumer>
      </React.Fragment>
    );
  }
}

export default withTranslate(withFirebase(withFade(LocationsList)));
export { LocationsList as PureLocationsList, LocDistMatrix };